import styled from 'styled-components'

import { errorRed } from '@ui/theme/colors'
import { Body } from '@ui/typography/Body'

export const ErrorMessage = styled(Body)`
  color: ${errorRed};
  min-height: 1.5rem;

  a {
    color: ${errorRed};
  }
`
