export const shadows = {
  button: '0 3px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.2)',
  buttonHover: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.2)',
  paper: '0 2px 4px 0 rgba(0, 0, 0, 0.20), 0 -1px 2px 0 rgba(0, 0, 0, 0.15)',
  plusLock: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  plusFeatureCard: '0px 1px 3px 0px rgba(0, 0, 0, 0.30)',
  listingCard: '0 3px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.2)',
  listingCardHover: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.2)',
  listingModal: {
    listingCarousel: '0px -0.5px 4px 0px rgba(0, 0, 0, 0.10)',
    listingCarouselCta: '0px 1px 4px 0px rgba(75, 78, 83, 0.25)',
    contextMenu: '0 2px 4px 0 rgba(0,0,0,0.20),0 -1px 2px 0 rgba(0,0,0,0.15)',
  },
  hiringStageModal: {
    buttonContainer: '0 1px 3px 0 rgba(0,0,0,0.2)',
  },
}
