import { useEffect, useMemo, useState } from 'react'
import smartlookClient from 'smartlook-client'

type SmartLookUser = {
  pk?: number
  email?: string | null
  displayName?: string | null
  profileType?: string | null
  listingType?: string | null
}

export default function useSmartLook(user: SmartLookUser) {
  const SAMPLE_PERCENTAGE = 3
  const PERCENTAGE = Math.ceil(Math.random() * 100)
  const SMARTLOOK_PROJECT_KEY = process.env.NEXT_PUBLIC_SMARTLOOK_PROJECT_KEY

  const isSmartLookEnabled = useMemo(
    () =>
      SMARTLOOK_PROJECT_KEY && SMARTLOOK_PROJECT_KEY !== 'false' && PERCENTAGE < SAMPLE_PERCENTAGE,
    [PERCENTAGE, SMARTLOOK_PROJECT_KEY]
  )

  const [isSmartlookLoaded, setSmartlookLoaded] = useState(false)

  useEffect(() => {
    if (isSmartlookLoaded || !isSmartLookEnabled || !SMARTLOOK_PROJECT_KEY) return

    window.addEventListener('load', () => {
      smartlookClient.init(SMARTLOOK_PROJECT_KEY)
      setSmartlookLoaded(true)
    })
  }, [isSmartlookLoaded, isSmartLookEnabled, SMARTLOOK_PROJECT_KEY])

  useEffect(() => {
    if (!isSmartlookLoaded) return

    if (user.pk && user.email && user.displayName && user.profileType && user.listingType) {
      smartlookClient.identify(user.pk, {
        email: user.email,
        name: user.displayName,
        profileType: user.profileType,
        listingType: user.listingType,
        release: process.env.NEXT_PUBLIC_REVISION || 'unknown',
      })
    }
  }, [isSmartlookLoaded, user])
}
