import Link from '@ui/common/Link'
import { onboardingPath } from 'routes/static'

export const clientOnlyJwtParser = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const json = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(json)
}

export const parseErrorMsg = (error: string) => {
  if (error === 'You don’t have an account with us yet, please sign up below.') {
    return (
      <>
        It looks like you don’t have an account with us yet. Please{' '}
        <Link href={onboardingPath}>create an account.</Link>
      </>
    )
  }

  return error
}
