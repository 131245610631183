import { breakpoints } from './breakpoints'
import { colors } from './colors'
import { shadows } from './shadows'
import { sizes } from './sizes'
import { space } from './spacing'
import { fonts, fontSizes, fontWeights } from './typography'
import { zIndex } from './zindex'

export * from './breakpoints'

const theme = {
  space,
  colors,
  breakpoints,
  zIndex,
  fonts,
  fontSizes,
  fontWeights,
  shadows,
  sizes,
}

export type Theme = typeof theme

export default theme
