import { CostDirectory, ProfileType } from '@graph/types/global.generated'
import Link from '@ui/common/Link'
import Stack from '@ui/layout/stack'
import { Heading } from '@ui/typography/Heading'

import { PrimaryFooterPublicCostDirectoryFragment } from './fragments.generated'
import { PrimaryPublicFooterKeyword } from './utils'

type LinksFindProps = {
  keywords: PrimaryPublicFooterKeyword[]
  costDirectories: PrimaryFooterPublicCostDirectoryFragment[]
}

const LinksFind = ({ keywords, costDirectories }: LinksFindProps) => {
  return (
    <Stack as='nav' gap='4'>
      <Heading as='h3' size='xs' color='white.4'>
        Find
      </Heading>

      <Stack gap='3'>
        {keywords.map((keyword) => (
          <Link variant='secondary' key={keyword.id} href={keyword.webClientPath}>
            {keyword.name}
          </Link>
        ))}

        {costDirectories.map((keyword) => (
          <Link
            variant='secondary'
            key={keyword.id}
            href={(keyword.costDirectory as CostDirectory).webClientPath}
          >
            {keyword.audienceProfileType === ProfileType.Family && `${keyword.name} Cost`}
            {keyword.audienceProfileType === ProfileType.Nanny && `${keyword.audienceName} Salary`}
          </Link>
        ))}
      </Stack>
    </Stack>
  )
}

export default LinksFind
