// Because TypeScript is weird .. https://stackoverflow.com/questions/60952710/styled-componentss-css-prop-with-typescript
import type {} from 'styled-components/cssprop'
import propTypes from '@styled-system/prop-types'
import styled from 'styled-components'
import {
  compose,
  space,
  layout,
  color,
  flexbox,
  position,
  shadow,
  typography,
  border,
  grid,
  SpaceProps,
  LayoutProps,
  ColorProps,
  PositionProps,
  FlexboxProps,
  ShadowProps,
  BorderProps,
  TypographyProps,
  GridProps,
} from 'styled-system'

export type BoxProps = SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps &
  FlexboxProps &
  BorderProps &
  ShadowProps &
  TypographyProps &
  GridProps

const propsToOmit = [
  ...Object.keys(propTypes.space),
  ...Object.keys(propTypes.layout),
  ...Object.keys(propTypes.color),
  ...Object.keys(propTypes.position),
  ...Object.keys(propTypes.flexbox),
  ...Object.keys(propTypes.shadow),
  ...Object.keys(propTypes.border),
  ...Object.keys(propTypes.typography),
  ...Object.keys(propTypes.grid),
  'css',
  'variant',
]

export const Box = styled.div.withConfig({
  shouldForwardProp: (prop) => !propsToOmit.includes(prop as string),
})<BoxProps>`
  ${compose(space, layout, color, position, flexbox, shadow, border, typography, grid)}
`

Box.defaultProps = {
  borderRadius: '0',
  borderWidth: 0,
  borderStyle: 'solid',
}
