import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'

const useModal = (modalId: string, flowId?: string) => {
  const router = useRouter()
  const { modal, group } = router.query

  const isVisible = useMemo(() => {
    const modalPresent = modal === modalId
    const groupPresent = group === flowId

    if (flowId) return modalPresent && groupPresent

    return modalPresent
  }, [flowId, group, modal, modalId])

  const openModal = useCallback(
    (urlParams?: { [key: string]: string }) => {
      router.push(
        {
          pathname: router.asPath.split('?')[0],
          query: {
            modal: modalId,
            ...(flowId ? { group: flowId } : {}),
            ...urlParams,
          },
        },
        undefined,
        { shallow: true }
      )
    },
    [modalId, flowId, router]
  )

  const closeModal = useCallback(() => {
    router.push(
      {
        pathname: router.asPath.split('?')[0],
        query: null,
      },
      undefined,
      { shallow: true }
    )
  }, [router])

  return { isVisible, openModal, closeModal }
}

export default useModal
