// @ts-nocheck
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"-QYlWpLMYFUVZRkalKXsw"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_APP_ENV,
  release: process.env.NEXT_PUBLIC_REVISION,
  debug: false,

  integrations: [
    Sentry.browserTracingIntegration({
      tracingOrigins: [
        process.env.NEXT_PUBLIC_API_URI.replace('/graphql', '').replace('https://', ''),
      ],
    }),
  ],
  ignoreErrors: [
    "undefined is not an object (evaluating 'a.L')",
    "Can't find variable: _AutofillCallbackHandler",
  ],
})
