import { ProfileType } from '@graph/types/global.generated'

export const PRICE_EXPERIMENT_KEY = 'family-price-experiment-2023-12-i1'

export const PLUS_FAMILY_PRICE_IN_CENTS = 6000
export const PLUS_NANNY_PRICE_IN_CENTS = 1000
export const ADDITIONAL_PAYROLL_EMPLOYEE_IN_CENTS = 2500

export type PriceConfig = { priceId: string; price: number }

export const PRICE_EXPERIMENTS: {
  [key in string]: { [key in string]: PriceConfig }
} = {
  [ProfileType.Nanny]: {
    control: {
      priceId: 'nanny-lane-plus-nanny-monthly-2020-10',
      price: PLUS_NANNY_PRICE_IN_CENTS,
    },
  },
  [ProfileType.Family]: {
    control: {
      priceId: 'nanny-lane-plus-family-monthly-2023-09-variant-b',
      price: PLUS_FAMILY_PRICE_IN_CENTS,
    },
  },
}

export const GENERIC_ERROR_MESSAGE = 'Something went wrong, please try again'
export const US_FEDERAL_MINIMUM_WAGE = 725
export const PROMO_KEY = 'promo'
export const VALID_PROMO_CHARACTERS = /^[A-Za-z0-9]{1,100}$/

export const CONFIRM_ACCOUNT_MODAL_ID = 'confirm-account'

export const SECONDS_IN_A_DAY = 86400

export const PAGES_VISITED_COOKIE_NAME = 'nl-pages-visited'
