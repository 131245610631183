import Link from '@ui/common/Link'
import Stack from '@ui/layout/stack'
import { Heading } from '@ui/typography/Heading'
import {
  nannyLandingPath,
  homePath,
  nannyShareLandingPath,
  nannyPayrollLandingPath,
  nannySharePayrollLandingPath,
  backgroundCheckLandingPath,
  nannyContractLandingPath,
  plusLandingPath,
  guideForFamilyPath,
  guideForNannyPath,
  compareProductsPath,
} from 'routes/static'

const LinksHowItWorks = () => {
  return (
    <Stack as='nav' gap='4'>
      <Heading as='h3' size='xs' color='white.4'>
        How It Works
      </Heading>

      <Stack gap='3'>
        <Link variant='secondary' href={nannyLandingPath}>
          Nannies
        </Link>
        <Link variant='secondary' href={homePath}>
          Parents
        </Link>
        <Link variant='secondary' href={nannyShareLandingPath}>
          Nanny Share
        </Link>
        <Link variant='secondary' href={nannyPayrollLandingPath}>
          Nanny Payroll Service
        </Link>
        <Link variant='secondary' href={nannySharePayrollLandingPath}>
          Nanny Share Payroll
        </Link>
        <Link variant='secondary' href={backgroundCheckLandingPath}>
          Background Checks
        </Link>
        <Link variant='secondary' href={nannyContractLandingPath}>
          Nanny Contracts
        </Link>
        <Link variant='secondary' href={plusLandingPath}>
          PLUS
        </Link>
        <Link variant='secondary' href={guideForFamilyPath}>
          Guide for Families
        </Link>
        <Link variant='secondary' href={guideForNannyPath}>
          Guide for Nannies
        </Link>
        <Link variant='secondary' href={compareProductsPath}>
          Comparing Nanny Lane
        </Link>
      </Stack>
    </Stack>
  )
}

export default LinksHowItWorks
