import css from '@styled-system/css'
import Image from 'next/image'
import styled from 'styled-components'

import { useViewerGlobalContext } from 'viewer/ViewerGlobalContext'

import logoDesktopPlus from './images/logo-plus-desktop.svg'
import logoMobilePlus from './images/logo-plus-mobile.svg'
import logoDesktop from './images/nl-logo-desktop.svg'
import logoMobile from './images/nl-logo-mobile.svg'

const Logo = () => {
  const viewer = useViewerGlobalContext()

  return viewer.isPlus ? (
    <>
      <DesktopLogo>
        <Image src={logoDesktopPlus} priority alt='Nanny Lane Logo' width='115' height='34' />
      </DesktopLogo>

      <MobileLogo>
        <Image src={logoMobilePlus} priority alt='Nanny Lane Logo' width='32' height='32' />
      </MobileLogo>
    </>
  ) : (
    <>
      <DesktopLogo>
        <Image src={logoDesktop} priority alt='Nanny Lane Logo' width='115' height='34' />
      </DesktopLogo>

      <MobileLogo>
        <Image src={logoMobile} priority alt='Nanny Lane Logo' width='20' height='32' />
      </MobileLogo>
    </>
  )
}

const DesktopLogo = styled.div`
  flex: 0 0 auto;
  @media print {
    display: block;
  }
  ${css({ display: ['none', 'flex'] })}
`

const MobileLogo = styled.div`
  flex: 0 0 auto;
  @media print {
    display: none;
  }
  ${css({ display: ['flex', 'none'] })}
`

export default Logo
