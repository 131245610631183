import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { ReactNode, useCallback } from 'react'
import styled from 'styled-components'

import { Analytics } from 'analytics/Analytics'

import { CombinedButtonProps, commonButtonStyles } from './Button'

export type ButtonLinkProps = CombinedButtonProps &
  Omit<NextLinkProps, 'as'> & { children: ReactNode; target?: string }

const propsToOmit = ['width', 'minWidth', 'maxWidth', 'variant', 'gradient']

const config = {
  shouldForwardProp: (prop: string) => !propsToOmit.includes(prop),
}

const ButtonLink = ({
  onClick,
  eventClickTarget,
  variant = 'normal',
  selected = false,
  disabled = false,
  ...rest
}: ButtonLinkProps) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (eventClickTarget) Analytics.trackButtonClick(eventClickTarget)
      if (onClick) onClick(e)
    },
    [eventClickTarget, onClick]
  )

  return (
    <AnchorButton
      onClick={(e) => handleClick(e)}
      variant={variant}
      selected={selected}
      disabled={disabled}
      prefetch={false}
      data-event-click-target={eventClickTarget}
      {...rest}
    />
  )
}

const AnchorButton = styled(NextLink).withConfig<ButtonLinkProps>(config)`
  ${commonButtonStyles}

  display: flex;
  justify-content: center;
  align-items: center;
`

export default ButtonLink
