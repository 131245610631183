import {
  PeekabooExperiment,
  PeekabooProvider,
  PeekabooProviderOptions,
} from '@careguide/peekaboo-client'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'

import { Analytics } from 'analytics/Analytics'
import { useViewerGlobalContext } from 'viewer/ViewerGlobalContext'

import { isDevEnvironment, isRunningSpecs, isStagingEnvironment } from './utils'

const ExperimentsProvider = ({ children }: { children: React.ReactNode }) => {
  const viewer = useViewerGlobalContext()
  const { query, asPath } = useRouter()
  const forcedExperimentKey = typeof query.experiment === 'string' ? query.experiment : undefined
  const forcedExperimentVariant = typeof query.variant === 'string' ? query.variant : undefined
  const hasForcedExperiment = !!forcedExperimentKey && !!forcedExperimentVariant

  /**
   * When monitoring viewer changes in dev mode you will experience a double
   * viewer update on page load. This is a known issue when running on the dev
   * server with hot reloading.
   */
  const peekabooUser = useMemo(() => {
    return !!viewer?.user ? { id: viewer.user.id } : null
  }, [viewer])

  const onExperimentsLoaded = useCallback(
    (experiments: PeekabooExperiment[]) => Analytics.trackExperiments(experiments),
    []
  )

  const peekabooOptions: PeekabooProviderOptions = {
    domain: typeof window !== 'undefined' ? window.location.host : '',
    serviceUrl: process.env.NEXT_PUBLIC_PEEKABOO_API_URI as string,
    enableForceExperiment: isDevEnvironment || isStagingEnvironment,
    /**
     * We disable the experiment if the viewer is loading because we don't
     * want to bucket a user until we know have a valid viewer. This prevents
     * multiple calls going to Peekaboo for the same page request
     */
    disableExperiments: isRunningSpecs() || viewer.isLoading,
  }

  if (hasForcedExperiment) {
    peekabooOptions.forcedExperiment = {
      key: forcedExperimentKey,
      variation: { key: forcedExperimentVariant },
    }
  }

  return (
    <PeekabooProvider
      user={peekabooUser}
      currentUrl={asPath}
      options={peekabooOptions}
      onExperimentsLoaded={onExperimentsLoaded}
    >
      {children}
    </PeekabooProvider>
  )
}

export default ExperimentsProvider
