import Image from 'next/image'
import styled from 'styled-components'

import Link from '@ui/common/Link'
import Stack from '@ui/layout/stack'
import { Body } from '@ui/typography/Body'
import { cancellationPolicyPath, privacyPolicyPath, termsOfServicePath } from 'routes/static'

import NLlogo from './images/nl-logo-wordmark-reversed.svg'

const DELIMITER = `${'\u00A0'.repeat(2)}•${'\u00A0'.repeat(2)}`
const CURRENT_YEAR = new Date().getFullYear()

const LegalFooter = () => {
  return (
    <Stack gap='4'>
      <Body color='white.4'>
        California consumers have the right to access information by submitting a Verifiable
        Consumer Request at{' '}
        <Link variant='secondary' href='mailto:help@nannylane.com'>
          help@nannylane.com
        </Link>
      </Body>

      <Stack gap='2'>
        <div>
          <Image src={NLlogo} width='90' height='28' alt='Nanny Lane Logo' />
        </div>

        <LegalLinks>
          © {CURRENT_YEAR} CareGuide Inc
          {DELIMITER}
          <Link variant='secondary' href={privacyPolicyPath} target='_blank' rel='noreferrer'>
            Privacy Policy
          </Link>
          {DELIMITER}
          <Link variant='secondary' href={termsOfServicePath} target='_blank' rel='noreferrer'>
            Terms of Service
          </Link>
          {DELIMITER}
          <Link variant='secondary' href={cancellationPolicyPath} target='_blank' rel='noreferrer'>
            Cancellation Policy
          </Link>
          {DELIMITER}
          <address>20 Bay Street, 11th Floor, Toronto, ON M5J 2N8</address>
        </LegalLinks>
      </Stack>
    </Stack>
  )
}

const LegalLinks = styled(Body).attrs({ color: 'white.4' })`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
`

export default LegalFooter
