import css from '@styled-system/css'
import styled from 'styled-components'

const Hr = styled.hr`
  border: 0;
  width: 100%;

  ${css({
    borderTopColor: 'grey.1',
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
  })}
`

export default Hr
