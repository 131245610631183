import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Family, Nanny, ProfileType } from '@graph/types/global.generated'
import { CountryCode, DEFAULT_LOCALE, Locale, VIEWER_LOCALE_COOKIE_NAME } from 'locale/constants'
import { getLocaleFromCountry, isLocaleSupported } from 'locale/utils'
import { useViewerGlobalContext } from 'viewer/ViewerGlobalContext'

const COOKIE_MAX_AGE_IN_DAYS = 365

export const LocaleContext = createContext<{
  locale: Locale
  localeCookie: Locale | undefined
  setLocale: (value: Locale, persistToCookie?: boolean) => void
}>({ locale: DEFAULT_LOCALE, localeCookie: undefined, setLocale: () => {} })

export const LocaleContextProvider = ({
  children,
  localeFromServerCookie,
}: {
  children: React.ReactNode
  localeFromServerCookie: Locale | null
}) => {
  const [locale, setLocale] = useState(DEFAULT_LOCALE)
  const prevLocale = useRef<Locale>()
  const viewer = useViewerGlobalContext()
  const { locale: localeFromUrl } = useRouter()
  const localeFromCookie =
    localeFromServerCookie || (Cookies.get(VIEWER_LOCALE_COOKIE_NAME) as Locale | undefined)

  const profile = viewer.user?.profile
  const hasAddress = profile?.type == ProfileType.Nanny || profile?.type == ProfileType.Family
  const countrySlug = hasAddress ? (profile as Family | Nanny).address?.country.slug : null
  const localeFromCountry = getLocaleFromCountry(countrySlug as CountryCode)

  const updateLocale = useCallback(
    (curLocale: Locale, persistToCookie = true) => {
      if (isLocaleSupported(curLocale)) {
        setLocale(curLocale)
        prevLocale.current = curLocale
        if (persistToCookie) {
          Cookies.set(VIEWER_LOCALE_COOKIE_NAME, curLocale, {
            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
            expires: COOKIE_MAX_AGE_IN_DAYS,
            secure: true,
          })
        }
      }
    },
    [setLocale]
  )

  if (prevLocale.current !== locale) {
    // get locale
    if (localeFromCountry) {
      updateLocale(localeFromCountry)
    } else if (localeFromCookie) {
      updateLocale(localeFromCookie)
    } else if (isLocaleSupported(localeFromUrl as Locale)) {
      updateLocale(localeFromUrl as Locale, false)
    }
  }

  const localeObject = useMemo(() => {
    return {
      locale,
      localeCookie: localeFromCookie,
      setLocale: updateLocale,
    }
  }, [locale, localeFromCookie, updateLocale])

  useEffect(() => {
    if (viewer.isLoading || prevLocale.current === localeFromCountry) return
    updateLocale(localeFromCountry as Locale)
  }, [viewer, localeFromCountry, updateLocale])

  return <LocaleContext.Provider value={localeObject}>{children}</LocaleContext.Provider>
}

export const useLocaleContext = () => {
  return useContext(LocaleContext)
}
