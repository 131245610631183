import css from '@styled-system/css'
import styled from 'styled-components'

export const FormLabel = styled.label`
  ${css({
    color: 'grey.3',
    fontSize: 1,
    fontWeight: 'medium',
  })}
`
