import { Maybe } from 'graphql/jsutils/Maybe'

import { ViewerGlobalFragment } from '@graph/fragments/ViewerGlobal.generated'
import {
  FeatureLookupKey,
  ProductVariant,
  ProfileType,
  PaidTierCohort,
} from '@graph/types/global.generated'
import { backgroundCheckStatusPath } from 'routes/backgroundChecks'
import { contractPreviewPage } from 'routes/contract'
import { payrollStatusPath } from 'routes/static'

type Viewer = Maybe<ViewerGlobalFragment>

export const isViewerLoggedIn = (viewer: Viewer) => !!viewer?.user?.id

export const isViewerNanny = (viewer: Viewer) => viewer?.user?.profile?.type === ProfileType.Nanny

export const isViewerFamily = (viewer: Viewer) => viewer?.user?.profile?.type === ProfileType.Family

export const isViewerSubscribed = (viewer: Viewer) => !!viewer?.user?.activeSubscription?.id

export const isViewerFullSession = (viewer: Viewer) => !!viewer?.user?.isFullSession

export const viewerHasListing = (viewer: Viewer) => !!viewer?.user?.profile?.listing

export const isViewerPlus = (viewer: Viewer) =>
  viewer?.user?.activeSubscription?.product?.variant === ProductVariant.Plus

export const isViewerEssential = (viewer: Viewer) =>
  viewer?.user?.activeSubscription?.product?.variant === ProductVariant.Essential

export const isViewerHidden = (viewer: Viewer) => viewer?.user?.isDisabled ?? false

export const isViewerAdmin = (viewer: Viewer) => viewer?.user?.isAdmin ?? false

export const getSingleFlowPath = (viewer: Viewer) => {
  if (viewer?.user?.isComplete) return null

  if (!!viewer?.user?.profile?.payroll?.id) return payrollStatusPath

  const latestBackgroundCheck = viewer?.user?.requestedBackgroundChecks[0]
  if (!!latestBackgroundCheck) return backgroundCheckStatusPath(latestBackgroundCheck.id)

  const latestNannyContract = viewer?.user?.latestNannyContract
  if (!!viewer?.user?.profile && !viewer?.user?.profile?.listing && !!latestNannyContract)
    return contractPreviewPage(latestNannyContract.id)

  return null
}

export const hasAccessToFeature = (viewer: Viewer, feature: FeatureLookupKey) => {
  return viewer?.user?.features.some(({ lookupKey }) => lookupKey === feature) ?? false
}
// Temp helpers for paid required experiment
export const isViewerControlCohort = (viewer: Viewer) => {
  // THis ensures if corhort isn't set then viewer is considered control
  if (!viewer?.user?.paidTierCohort) return true

  return viewer.user.paidTierCohort === PaidTierCohort.Control
}

export const isViewerIntroduceEssentialCohort = (viewer: Viewer) =>
  viewer?.user?.paidTierCohort === PaidTierCohort.IntroduceEssential

export const isViewerPaidRequiredCohort = (viewer: Viewer) =>
  viewer?.user?.paidTierCohort === PaidTierCohort.PaidRequired
