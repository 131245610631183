import css from '@styled-system/css'
import { Montserrat } from 'next/font/google'
import { css as styledCss } from 'styled-components'

const montserrat = Montserrat({
  weight: ['400', '500', '600'],
  style: ['normal'],
  subsets: ['latin'],
})

export const fonts = {
  montserrat: montserrat.style.fontFamily,
}

export const fontSizes = [
  14, // 0
  16, // 1
  18, // 2
  20, // 3
  24, // 4
  28, // 5
  32, // 6
  36, // 7
  40, // 8
  48, // 9
] as const

export const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 600,
}

export const body = css({
  fontFamily: 'montserrat',
  color: 'grey.3',
  fontSize: 1,
  fontWeight: 'regular',
})

const defaultLink = styledCss`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;

  ${css({
    color: 'grey.3',
    lineHeight: 1.5,
    fontSize: 1,
    textDecoration: 'none',
  })}

  &:active,
  &:hover,
  &:visited,
  &:focus {
    ${css({ color: 'grey.3' })}
`

const footerLink = styledCss`
  ${defaultLink}

  ${css({
    color: 'white.4',
    textDecoration: 'underline',
  })}

  &:active,
  &:hover,
  &:visited,
  &:focus {
    ${css({ color: 'white.4' })}
  }
`

const bodyLinks = styledCss`
  ${defaultLink}

  ${css({
    fontWeight: 'medium',
    textDecoration: 'underline',
  })}
`

export const link = {
  primary: defaultLink,
  body: bodyLinks,
  footer: footerLink,
}
