import css from '@styled-system/css'
import NextLink from 'next/link'
import { useCallback } from 'react'
import styled, { css as styledCss } from 'styled-components'
import { variant } from 'styled-system'

import { Analytics } from 'analytics/Analytics'

type UnderlineVariant = 'primary' | 'secondary' | 'highlight'

export const defaultLinkStyle = styledCss`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;

  ${css({
    color: 'grey.3',
    lineHeight: 1.5,
    fontSize: 1,
    fontWeight: 'medium',
    textDecoration: 'none',
  })}
`

const linkVariants = variant<Record<string, unknown>, UnderlineVariant>({
  prop: 'variant',
  variants: {
    primary: {
      textDecoration: 'underline',
      '::active': { color: 'grey.3' },
      '::hover': { color: 'grey.3' },
      '::visited': { color: 'grey.3' },
      '::focus': { color: 'grey.3' },
    },
    secondary: {
      textDecoration: 'underline',
      color: 'white.4',
      '::active': { color: 'white.4' },
      '::hover': { color: 'white.4' },
      '::visited': { color: 'white.4' },
      '::focus': { color: 'white.4' },
    },
    highlight: {
      textDecoration: 'underline',
      color: 'linkPurple',
      '::active': { color: 'linkPurple' },
      '::hover': { color: 'linkPurple' },
      '::visited': { color: 'linkPurple' },
      '::focus': { color: 'linkPurple' },
    },
  },
})

type LinkProps = Omit<React.ComponentProps<typeof NextLink>, 'as'> & { eventClickTarget?: string }

const Link = ({ onClick, eventClickTarget, ...rest }: LinkProps) => {
  const handleClick = useCallback(
    (event: any) => {
      if (eventClickTarget) Analytics.trackButtonClick(eventClickTarget)
      if (onClick) onClick(event)
    },
    [eventClickTarget, onClick]
  )

  return (
    <StyledLink
      prefetch={false}
      {...rest}
      onClick={handleClick}
      data-event-click-target={eventClickTarget}
    />
  )
}

const StyledLink = styled(NextLink)`
  ${defaultLinkStyle}
  ${linkVariants}
`

Link.defaultProps = {
  variant: 'primary',
}

export default Link
