import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { ViewerGlobalFragment } from '@graph/fragments/ViewerGlobal.generated'
import { ViewerGlobalDocument } from '@graph/queries/ViewerGlobal.generated'
import useApolloClient from '@graph/useApolloClient'
import useAnalyticsWatcher from 'analytics/useAnalyticsWatcher'
import useSmartLook from 'lib/smartlook/useSessionRecorder'
import useTimeZoneWatcher from 'viewer/useTimeZoneWatcher'

/**
 * A hook that consolidates other common hooks that need to be initialized at the app level.
 * @param viewer
 */
const useAppInitialize = (initialApolloState: any, viewerFromServer?: ViewerGlobalFragment) => {
  const apolloClient = useApolloClient(initialApolloState)
  const { data } = useQuery(ViewerGlobalDocument, {
    client: apolloClient,
    skip: !!viewerFromServer,
  })

  const viewer = viewerFromServer ?? data?.viewer

  const smartlookUser = {
    pk: viewer?.user?.pk,
    email: viewer?.user?.email,
    displayName: viewer?.user?.profile?.displayName,
    profileType: viewer?.user?.profile?.__typename,
  }

  const timeZone = viewer?.user?.timeZone ?? null

  const user = useMemo(
    () => ({ pk: smartlookUser.pk, email: smartlookUser.email }),
    [smartlookUser.pk, smartlookUser.email]
  )

  useAnalyticsWatcher(user)

  useTimeZoneWatcher(timeZone, apolloClient)

  useSmartLook(smartlookUser)

  return { apolloClient }
}

export default useAppInitialize
