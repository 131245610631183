import { useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import Cookies from 'js-cookie'
import { createContext, useContext, useMemo } from 'react'

import { ViewerGlobalFragment } from '@graph/fragments/ViewerGlobal.generated'
import { ViewerGlobalDocument } from '@graph/queries/ViewerGlobal.generated'
import { FeatureLookupKey } from '@graph/types/global.generated'
import { GradientType, toGradientType } from '@ui/theme/gradients'

import {
  hasAccessToFeature,
  isViewerLoggedIn,
  isViewerPlus,
  isViewerSubscribed,
  isViewerFamily,
  isViewerHidden,
  isViewerNanny,
  isViewerAdmin,
  viewerHasListing,
  isViewerEssential,
} from './utils'

export type ViewerGlobalContextType = ViewerGlobalFragment & {
  isLoading: boolean
  isLoggedIn: boolean
  isSubscribed: boolean
  isPlus: boolean
  isEssential: boolean
  isFamily: boolean
  isNanny: boolean
  hasListing: boolean
  isHidden: boolean
  isAdmin: boolean
  hasAccessToFeature: (feature: FeatureLookupKey) => boolean
  gradientType: GradientType
}

type ViewerGlobalProviderProps = {
  viewerFromServer?: ViewerGlobalFragment
  fetchPolicy?: WatchQueryFetchPolicy
  children: React.ReactNode
}

/**
 * ViewerGlobalContext represents the viewer data that is present in ViewerGlobalFragment.
 * This viewer data is global to the entire application and every page will either include
 * it in their page query or allow this context to fetch the viewer data client side. The
 * ViewerGlobal data that is included in the page query will be passed to the ViewerGlobalProvider
 * in the viewerFromServer prop.
 *
 * Page specific viewer data should be added to that page's query.
 */
const ViewerGlobalContext = createContext<ViewerGlobalContextType>({
  user: null,
  isLoading: false,
  isLoggedIn: false,
  isSubscribed: false,
  isPlus: false,
  isEssential: false,
  isFamily: false,
  isNanny: false,
  hasListing: false,
  isHidden: false,
  isAdmin: false,
  hasAccessToFeature: (_feature: FeatureLookupKey) => false,
  gradientType: 'neutral',
})

export const ViewerGlobalProvider = ({
  viewerFromServer,
  fetchPolicy = 'cache-first',
  children,
}: ViewerGlobalProviderProps) => {
  /**
   * To ensure the children of the provider re-render when viewer data changes ie. (mutations)
   * the provider's viewer data is pulled from a useQuery hook.
   *
   * 1. A page using getServerSideProps
   * If the page query included ViewerGlobal fragment then the viewer data will already
   * be cached when this runs client side avoiding fetching from server again.
   *
   * 2. A page using getStaticProps
   * Since getStaticProps runs during build time we don't have access to viewer data.
   * Since no viewer data is cached from the server the client will fetch viewr data.
   */
  const { data, loading } = useQuery(ViewerGlobalDocument, {
    fetchPolicy,
    nextFetchPolicy: 'cache-first',
    skip: !!viewerFromServer,
  })

  const loadingViewer = useMemo(() => {
    return {
      user: null,
      isLoading: true,
      isLoggedIn: false,
      isSubscribed: false,
      isPlus: false,
      isEssential: false,
      isFamily: false,
      isNanny: false,
      hasListing: false,
      isHidden: false,
      isAdmin: false,
      hasAccessToFeature: (_feature: FeatureLookupKey) => false,
      gradientType: 'neutral' as GradientType,
    }
  }, [])

  const fetchedViewer = useMemo(() => {
    const viewerFromClient = data?.viewer
    const viewerData = (viewerFromServer || viewerFromClient) ?? { user: null }

    return {
      ...viewerData,
      isLoading: false,
      isLoggedIn: isViewerLoggedIn(viewerData),
      isSubscribed: isViewerSubscribed(viewerData),
      isPlus: isViewerPlus(viewerData),
      isEssential: isViewerEssential(viewerData),
      isFamily: isViewerFamily(viewerData),
      isNanny: isViewerNanny(viewerData),
      hasListing: viewerHasListing(viewerData),
      isHidden: isViewerHidden(viewerData),
      isAdmin: isViewerAdmin(viewerData),
      hasAccessToFeature: (feature: FeatureLookupKey) => hasAccessToFeature(viewerData, feature),
      gradientType: toGradientType(viewerData.user?.profile?.type),
    }
  }, [data, viewerFromServer])

  const viewer = loading ? loadingViewer : fetchedViewer

  if (viewer.user) {
    const user = viewer.user
    Sentry.getCurrentScope().setUser({
      id: user.pk.toString(),
      email: user.email as string,
    })

    if (!Cookies.get('current_user_id')) {
      Cookies.set('current_user_id', user.pk.toString(), {
        expires: 1,
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        secure: true,
      })
    }
  }

  return <ViewerGlobalContext.Provider value={viewer}>{children}</ViewerGlobalContext.Provider>
}

export const useViewerGlobalContext = (): ViewerGlobalContextType => {
  const context = useContext(ViewerGlobalContext)
  return context
}
