export const darkOrange = '#FFC095'
export const mediumOrange = '#FCD7BE'
export const lightOrange = '#FFEAD5'

export const darkYellow = '#FBF09E'
export const mediumYellow = '#FFF8C5'
export const lightYellow = '#FFFAD7'

export const mediumGreen = '#BBECC3'

export const darkPurple = '#B7B6FD'
export const mediumPurple = '#CDCCFB'
export const lightPurple = '#E6E5FF'
export const linkPurple = '#6B6AC9'

export const powderPink = '#FDB3E4'
export const mediumPink = '#FBC5E9'
export const lightPink = '#FCD9F1'

export const mediumGrey = '#90949B'
export const grey = '#e0e0e0'
export const lightGrey = '#BFBFBF'
export const extraLightGrey = '#F7F7F7'

export const darkGrey = 'rgba(75, 78, 83, 1)'
export const darkGrey75 = 'rgba(75, 78, 83, 0.75)'
export const darkGrey50 = 'rgba(75, 78, 83, 0.5)'
export const darkGrey35 = 'rgba(75, 78, 83, 0.35)'
export const darkGrey10 = 'rgba(75, 78, 83, 0.1)'

export const lightRed = '#F8F8F8'

export const mediumGrey25 = 'rgba(144, 148, 155, 0.25)'

export const white = 'rgba(255, 255, 255, 1)'
export const white75 = 'rgba(255, 255, 255, 0.75)'
export const white50 = 'rgba(255, 255, 255, 0.5)'
export const white25 = 'rgba(255, 255, 255, 0.25)'
export const white10 = 'rgba(255, 255, 255, 0.1)'

export const errorRed = '#DB3838'
export const highlightGreen = '#0DBB29'
export const facebookBlue = '#3B5998'
export const messengerBlue = '#0084FF'
export const twitterBlue = '#1DA1F2'

export const teal = '#37ABBA'

export const planEssential = '#148190'
export const planPlus = '#6B6AC9'

// NOTE: Colour scale compatible with the System UI specification
export const colors = {
  orange: [lightOrange, mediumOrange, darkOrange],
  yellow: [lightYellow, mediumYellow, darkYellow],
  green: ['rgba(159, 218, 169, 0.3)', 'rgba(159, 218, 169, 0.5)', 'rgba(159, 218, 169, 1)'],
  blue: ['rgba(152, 231, 242, 0.3)', 'rgba(152, 231, 242, 0.5)', 'rgba(152, 231, 242, 1)'],
  purple: ['rgba(183, 182, 253, 0.3)', 'rgba(183, 182, 253, 0.5)', 'rgba(183, 182, 253, 1)'],
  pink: [lightPink, mediumPink, powderPink],
  grey: [extraLightGrey, lightGrey, mediumGrey, darkGrey],
  red: [lightRed],
  white: [white10, white25, white50, white75, white],

  darkGrey,
  darkGrey75,
  darkGrey50,
  darkGrey35,
  darkGrey10,

  mediumGrey25,

  linkPurple,

  teal,

  errorRed,
  highlightGreen,

  facebookBlue,
  messengerBlue,
  twitterBlue,

  planEssential,
  planPlus,
}
