import css from '@styled-system/css'
import styled, { css as styledCss } from 'styled-components'
import { color, variant, typography, TextColorProps, TextAlignProps } from 'styled-system'

import { Responsive } from '@ui/theme'

export type HeadingSize = 'xl' | 'lg' | 'md' | 'sm' | 'xs'

const propsToOmit = ['size', 'color', 'textAlign']

const config = {
  shouldForwardProp: (prop: string) => !propsToOmit.includes(prop),
}

export const headingXlStyles = {
  fontSize: [6, 9],
  lineHeight: ['40px', '60px'],
  fontWeight: ['medium', 'medium', 'regular'],
}
export const headingLgStyles = {
  fontSize: [5, 8],
  lineHeight: ['42px', '60px'],
  fontWeight: 'medium',
}
export const headingMdStyles = {
  fontSize: [4, 5],
  lineHeight: ['36px', '40px'],
  fontWeight: 'medium',
}
export const headingSmStyles = {
  fontSize: [3, 4],
  lineHeight: ['30px', '36px'],
  fontWeight: 'medium',
}
export const headingXsStyles = {
  fontSize: [1, 2],
  lineHeight: ['24px', '27px'],
  fontWeight: 'medium',
}

const size = variant<Record<string, unknown>, HeadingSize>({
  prop: 'size',
  variants: {
    xl: headingXlStyles,
    lg: headingLgStyles,
    md: headingMdStyles,
    sm: headingSmStyles,
    xs: headingXsStyles,
  },
})

export type HeadingProps = TextAlignProps & {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  size?: Responsive<HeadingSize>
} & TextColorProps

export const headingStyles = styledCss`
  word-break: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;

  ${css({
    margin: 0,
    color: 'grey.3',
  })}

  ${(typography as any).textAlign}
  ${size}
  ${color}
`

export const Heading = styled.h1.withConfig<HeadingProps>(config)`
  ${headingStyles}

  // To ensure links inside of <Heading> retain heading styles
  // add data-heading-link to the nested <a> tag.

  > a[data-heading-link] {
    ${headingStyles}
    text-decoration: none;
  }
`

Heading.defaultProps = {
  as: 'h2',
  size: 'xl',
}
