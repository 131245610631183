import { InputHTMLAttributes, ReactElement, forwardRef, useCallback } from 'react'
import styled from 'styled-components'
import { TextAlignProps, textAlign } from 'styled-system'

import { ContentBlock } from '@ui/layout/ContentBlock'
import Stack from '@ui/layout/stack'
import { darkGrey, errorRed, mediumGrey } from '@ui/theme/colors'

export enum InputTypes {
  TEXT = 'text',
  NUMBER = 'number',
  PASSWORD = 'password',
  EMAIL = 'email',
  DATE = 'date',
}

type TextInputProps = {
  type?: InputTypes
  hasError?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  ref?: React.Ref<HTMLInputElement>
  underline?: boolean
  icon?: ReactElement
} & TextAlignProps &
  InputHTMLAttributes<HTMLInputElement>

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      type = InputTypes.TEXT,
      disabled = false,
      hasError = false,
      onChange,
      onBlur,
      underline = true,
      icon,
      ...rest
    },
    ref
  ) => {
    const getInputMode = useCallback(() => {
      switch (type) {
        case InputTypes.NUMBER:
          return 'numeric'
        case InputTypes.EMAIL:
          return 'email'
      }
    }, [type])
    return (
      <UnderlineContainer hasError={hasError} underline={underline}>
        <Stack direction='horizontal' gap='1' valign='center'>
          {icon}
          <StyledInput
            hasError={hasError}
            type={type}
            inputMode={getInputMode()}
            autoComplete='off'
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            {...rest}
          />
        </Stack>
      </UnderlineContainer>
    )
  }
)

const UnderlineContainer = styled(ContentBlock)<{ hasError: boolean; underline: boolean }>`
  ${(props) =>
    props.underline
      ? `border-bottom: 1px solid ${props.hasError ? errorRed : darkGrey}`
      : 'border-bottom: none'}
`

const StyledInput = styled.input<{
  hasError: boolean
  disabled: boolean
}>`
  border-radius: 0;
  -webkit-appearance: none;
  width: 100%;
  border: none;
  color: ${(props) => (props.disabled ? mediumGrey : props.hasError ? errorRed : darkGrey)};
  background-color: transparent;
  outline: none;
  ${textAlign}
`

TextInput.displayName = 'TextInput'
export default TextInput
