// This file is generated by yarn codegen, PLEASE DO NOT EDIT!
/* eslint-disable */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Decimal: { input: any; output: any; }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any; }
  _Any: { input: any; output: any; }
};

/** Autogenerated input type of AcceptBackgroundCheck */
export type AcceptBackgroundCheckInput = {
  backgroundCheckId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AcceptBackgroundCheck */
export type AcceptBackgroundCheckPayload = MutationWithErrorsResult & {
  __typename: 'AcceptBackgroundCheckPayload';
  backgroundCheck: Maybe<BackgroundCheck>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

/** Autogenerated input type of AcceptInterview */
export type AcceptInterviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  interviewDatetime: Scalars['ID']['input'];
  nannyContactMethod?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AcceptInterview */
export type AcceptInterviewPayload = MutationWithErrorsResult & {
  __typename: 'AcceptInterviewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  interview: Maybe<Interview>;
};

/** Autogenerated input type of AcceptOffer */
export type AcceptOfferInput = {
  acceptMessage?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offerId: Scalars['ID']['input'];
};

/** Autogenerated return type of AcceptOffer */
export type AcceptOfferPayload = MutationWithErrorsResult & {
  __typename: 'AcceptOfferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  offer: Maybe<Offer>;
};

/** Autogenerated input type of AcceptReferenceContactRequest */
export type AcceptReferenceContactRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  referenceContactRequestId: Scalars['ID']['input'];
};

/** Autogenerated return type of AcceptReferenceContactRequest */
export type AcceptReferenceContactRequestPayload = MutationWithErrorsResult & {
  __typename: 'AcceptReferenceContactRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  referenceContactRequest: Maybe<ReferenceContactRequest>;
};

export type Address = Node & {
  __typename: 'Address';
  approximateCoordinates: GeoCoordinates;
  city: City;
  coordinates: Maybe<GeoCoordinates>;
  country: Country;
  distanceTo: Maybe<Scalars['Int']['output']>;
  fullAddress: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use 'coordinates' */
  lat: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use 'coordinates' */
  lng: Maybe<Scalars['Float']['output']>;
  neighborhood: Maybe<Neighborhood>;
  postalCode: Maybe<Scalars['String']['output']>;
  state: State;
  street1: Maybe<Scalars['String']['output']>;
  street2: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  city: Scalars['String']['input'];
  countrySlug: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
  neighborhood?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  stateName: Scalars['String']['input'];
  stateSlug: Scalars['String']['input'];
  street1?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
};

/** Age Group */
export enum AgeGroup {
  /** Adult */
  Adult = 'ADULT',
  /** Child */
  Child = 'CHILD',
  /** Infant */
  Infant = 'INFANT',
  /** Preteen */
  Preteen = 'PRETEEN',
  /** Teen */
  Teen = 'TEEN',
  /** Toddler */
  Toddler = 'TODDLER'
}

export type Article = Node & {
  __typename: 'Article';
  content: Scalars['String']['output'];
  createdAt: Maybe<Scalars['ISO8601Date']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageAlt: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  journeyStep: JourneyStep;
  nextArticles: Array<Article>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['ISO8601Date']['output']>;
  webClientPath: Scalars['String']['output'];
};


export type ArticleImageUrlArgs = {
  transformations: InputMaybe<ImageTransformation>;
};


export type ArticleNextArticlesArgs = {
  limit: Scalars['Int']['input'];
};

/** The connection type for Article. */
export type ArticleConnection = {
  __typename: 'ArticleConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ArticleEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Article>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ArticleEdge = {
  __typename: 'ArticleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Article>;
};

/** Autogenerated input type of AssignCandidateHiringStageMutation */
export type AssignCandidateHiringStageMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  hiringStage: HiringStage;
  userListingInterestId: Scalars['ID']['input'];
};

/** Autogenerated return type of AssignCandidateHiringStageMutation */
export type AssignCandidateHiringStageMutationPayload = MutationWithErrorsResult & {
  __typename: 'AssignCandidateHiringStageMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  userListingInterest: Maybe<UserListingInterest>;
  viewer: Maybe<Viewer>;
};

/** Autogenerated input type of AttachDefaultPaymentMethod */
export type AttachDefaultPaymentMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  stripePaymentMethodId: Scalars['String']['input'];
};

/** Autogenerated return type of AttachDefaultPaymentMethod */
export type AttachDefaultPaymentMethodPayload = MutationWithErrorsResult & {
  __typename: 'AttachDefaultPaymentMethodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  user: Maybe<User>;
};

export type Avatar = Node & {
  __typename: 'Avatar';
  extraLargeUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  largeUrl: Maybe<Scalars['String']['output']>;
  mediumUrl: Maybe<Scalars['String']['output']>;
  profile: Profile;
  regularUrl: Maybe<Scalars['String']['output']>;
  smallUrl: Maybe<Scalars['String']['output']>;
  tinyUrl: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type AvatarInput = {
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  url: Scalars['String']['input'];
};

/** Autogenerated input type of AvatarUpdatePrimary */
export type AvatarUpdatePrimaryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  profileId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

/** Autogenerated return type of AvatarUpdatePrimary */
export type AvatarUpdatePrimaryPayload = MutationWithErrorsResult & {
  __typename: 'AvatarUpdatePrimaryPayload';
  avatar: Maybe<Avatar>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  profile: Maybe<Profile>;
};

export type BackgroundCheck = Node & {
  __typename: 'BackgroundCheck';
  backgroundCheckType: Scalars['String']['output'];
  canAccept: Scalars['Boolean']['output'];
  candidate: Maybe<User>;
  checkrFirstName: Maybe<Scalars['String']['output']>;
  checkrInvitationUrl: Maybe<Scalars['String']['output']>;
  checkrLastName: Maybe<Scalars['String']['output']>;
  completedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  externalCandidateEmail: Maybe<Scalars['String']['output']>;
  externalCandidateFirstName: Maybe<Scalars['String']['output']>;
  externalCandidateLastName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isCharged: Scalars['Boolean']['output'];
  isExternal: Scalars['Boolean']['output'];
  requestedBy: User;
  requiresCandidateInfo: Scalars['Boolean']['output'];
  status: Maybe<BackgroundCheckStatus>;
  statusDescription: Maybe<Scalars['String']['output']>;
};

export type BackgroundCheckExternalCandidateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export enum BackgroundCheckStatus {
  Accepted = 'accepted',
  Cleared = 'cleared',
  Consider = 'consider',
  Declined = 'declined',
  Disputed = 'disputed',
  Expired = 'expired',
  Failed = 'failed',
  Pending = 'pending',
  Requested = 'requested',
  Suspended = 'suspended'
}

/** Supported background check types */
export enum BackgroundCheckType {
  /** Basic */
  Basic = 'Basic',
  /** Enhanced */
  Enhanced = 'Enhanced'
}

/** Boolean facets on listings */
export enum BooleanFacet {
  /** cpr facet */
  Cpr = 'cpr',
  /** drivers_license facet */
  DriversLicense = 'driversLicense',
  /** first_aid facet */
  FirstAid = 'firstAid',
  /** fluent_english facet */
  FluentEnglish = 'fluentEnglish',
  /** fluent_spanish facet */
  FluentSpanish = 'fluentSpanish',
  /** full_time facet */
  FullTime = 'fullTime',
  /** gradeschooler_care facet */
  GradeschoolerCare = 'gradeschoolerCare',
  /** housekeeping facet */
  Housekeeping = 'housekeeping',
  /** infant_care facet */
  InfantCare = 'infantCare',
  /** interested_in_payroll facet */
  InterestedInPayroll = 'interestedInPayroll',
  /** interested_in_share facet */
  InterestedInShare = 'interestedInShare',
  /** live_in facet */
  LiveIn = 'liveIn',
  /** meal_preparation facet */
  MealPreparation = 'mealPreparation',
  /** occasional_work facet */
  OccasionalWork = 'occasionalWork',
  /** part_time facet */
  PartTime = 'partTime',
  /** preschooler_care facet */
  PreschoolerCare = 'preschoolerCare',
  /** special_needs facet */
  SpecialNeeds = 'specialNeeds',
  /** toddler_care facet */
  ToddlerCare = 'toddlerCare',
  /** transportation facet */
  Transportation = 'transportation'
}

/** Autogenerated input type of BuildNannyContract */
export type BuildNannyContractInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nannyContract: NannyContractInput;
};

/** Autogenerated return type of BuildNannyContract */
export type BuildNannyContractPayload = MutationWithErrorsResult & {
  __typename: 'BuildNannyContractPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  nannyContract: Maybe<NannyContract>;
};

/** Autogenerated input type of CancelInterview */
export type CancelInterviewInput = {
  cancelNotes?: InputMaybe<Scalars['String']['input']>;
  cancelReason: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  interviewId: Scalars['ID']['input'];
};

/** Autogenerated return type of CancelInterview */
export type CancelInterviewPayload = MutationWithErrorsResult & {
  __typename: 'CancelInterviewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  interview: Maybe<Interview>;
};

/** Autogenerated input type of CancelSubscription */
export type CancelSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CancelSubscription */
export type CancelSubscriptionPayload = MutationWithErrorsResult & {
  __typename: 'CancelSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  user: Maybe<User>;
};

export type Candidate = Node & {
  __typename: 'Candidate';
  candidateInvitation: Maybe<CandidateInvitation>;
  createdAt: Scalars['Int']['output'];
  currentInvite: CandidateInvitation;
  id: Scalars['ID']['output'];
  invitationAgeInSeconds: Scalars['Int']['output'];
  isInvitationAccepted: Scalars['Boolean']['output'];
  nannyListing: NannyListing;
  shareableToken: Scalars['String']['output'];
  status: CandidateStatus;
  subscription: UserSubscription;
};

/** Autogenerated input type of CandidateAcceptInvitationMutation */
export type CandidateAcceptInvitationMutationInput = {
  candidateInvitationId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  notesForFamily?: InputMaybe<Scalars['String']['input']>;
  requirements?: InputMaybe<JobRequirements>;
};

/** Autogenerated return type of CandidateAcceptInvitationMutation */
export type CandidateAcceptInvitationMutationPayload = MutationWithErrorsResult & {
  __typename: 'CandidateAcceptInvitationMutationPayload';
  candidate: Maybe<Candidate>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

/** Autogenerated input type of CandidateDeliverMutation */
export type CandidateDeliverMutationInput = {
  candidateId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CandidateDeliverMutation */
export type CandidateDeliverMutationPayload = MutationWithErrorsResult & {
  __typename: 'CandidateDeliverMutationPayload';
  candidate: Maybe<Candidate>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

/** An edge in a connection. */
export type CandidateEdge = {
  __typename: 'CandidateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Candidate>;
};

export type CandidateInvitation = Node & {
  __typename: 'CandidateInvitation';
  candidate: Candidate;
  createdAt: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  notesForFamily: Maybe<Scalars['String']['output']>;
  shareableToken: Scalars['String']['output'];
  status: CandidateStatus;
};

/** Autogenerated input type of CandidateInviteMutation */
export type CandidateInviteMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  listingId: Scalars['ID']['input'];
  subscriptionId: Scalars['ID']['input'];
};

/** Autogenerated return type of CandidateInviteMutation */
export type CandidateInviteMutationPayload = MutationWithErrorsResult & {
  __typename: 'CandidateInviteMutationPayload';
  candidate: Maybe<Candidate>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

/** Autogenerated input type of CandidateMarkNotInterestedMutation */
export type CandidateMarkNotInterestedMutationInput = {
  candidateInvitationId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CandidateMarkNotInterestedMutation */
export type CandidateMarkNotInterestedMutationPayload = MutationWithErrorsResult & {
  __typename: 'CandidateMarkNotInterestedMutationPayload';
  candidate: Maybe<Candidate>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

/** Autogenerated input type of CandidateRejectInvitationMutation */
export type CandidateRejectInvitationMutationInput = {
  candidateInvitationId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CandidateRejectInvitationMutation */
export type CandidateRejectInvitationMutationPayload = MutationWithErrorsResult & {
  __typename: 'CandidateRejectInvitationMutationPayload';
  candidate: Maybe<Candidate>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

export type CandidateSearchFilters = {
  archivedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  hiringStages?: InputMaybe<Array<HiringStage>>;
  requestsOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Status of a PLUS subscription candidate */
export enum CandidateStatus {
  Approved = 'APPROVED',
  Delivered = 'DELIVERED',
  Invited = 'INVITED',
  NotInterested = 'NOT_INTERESTED',
  PendingReview = 'PENDING_REVIEW',
  Rejected = 'REJECTED',
  Unverified = 'UNVERIFIED'
}

/** Autogenerated input type of ChangePassword */
export type ChangePasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ChangePassword */
export type ChangePasswordPayload = MutationWithErrorsResult & {
  __typename: 'ChangePasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  user: User;
};

export type Child = Node & {
  __typename: 'Child';
  ageDescription: Scalars['String']['output'];
  ageGroup: AgeGroup;
  gender: Maybe<Gender>;
  id: Scalars['ID']['output'];
  monthsOldEnd: Maybe<Scalars['Int']['output']>;
  monthsOldStart: Scalars['Int']['output'];
};

/** The connection type for Child. */
export type ChildConnection = {
  __typename: 'ChildConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ChildEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Child>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ChildEdge = {
  __typename: 'ChildEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Child>;
};

export type ChildInput = {
  gender?: InputMaybe<Gender>;
  monthsOldEnd?: InputMaybe<Scalars['Int']['input']>;
  monthsOldStart?: InputMaybe<Scalars['Int']['input']>;
};

export type City = Node & {
  __typename: 'City';
  averageCost: Maybe<Scalars['Int']['output']>;
  averageSalary: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  neighborhood: Maybe<Neighborhood>;
  slug: Scalars['String']['output'];
  state: State;
};


export type CityNeighborhoodArgs = {
  slug: InputMaybe<Scalars['String']['input']>;
};

export type CityAverageHourlyRate = {
  __typename: 'CityAverageHourlyRate';
  average: Scalars['Int']['output'];
  city: Maybe<City>;
};

/** The connection type for City. */
export type CityConnection = {
  __typename: 'CityConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CityEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<City>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CityEdge = {
  __typename: 'CityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<City>;
};

/** Autogenerated input type of CompletePayrollClientRegistration */
export type CompletePayrollClientRegistrationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentUserToken: Scalars['String']['input'];
};

/** Autogenerated return type of CompletePayrollClientRegistration */
export type CompletePayrollClientRegistrationPayload = MutationWithErrorsResult & {
  __typename: 'CompletePayrollClientRegistrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  payrollClientRegistration: Maybe<PayrollClientRegistration>;
};

/** Autogenerated input type of CompletePayrollEmployeeRegistration */
export type CompletePayrollEmployeeRegistrationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentUserToken: Scalars['String']['input'];
};

/** Autogenerated return type of CompletePayrollEmployeeRegistration */
export type CompletePayrollEmployeeRegistrationPayload = MutationWithErrorsResult & {
  __typename: 'CompletePayrollEmployeeRegistrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  payrollEmployeeRegistration: Maybe<PayrollEmployeeRegistration>;
};

/** Autogenerated input type of ConfirmSubscriptionPurchase */
export type ConfirmSubscriptionPurchaseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  stripeSubscriptionId: Scalars['String']['input'];
};

/** Autogenerated return type of ConfirmSubscriptionPurchase */
export type ConfirmSubscriptionPurchasePayload = MutationWithErrorsResult & {
  __typename: 'ConfirmSubscriptionPurchasePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  user: Maybe<User>;
};

export type ContractAddress = {
  __typename: 'ContractAddress';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street1: Scalars['String']['output'];
  street2: Maybe<Scalars['String']['output']>;
};

export type ContractAddressInput = {
  city: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street1: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
};

export type ContractAllowance = {
  __typename: 'ContractAllowance';
  accomodationAmount: Scalars['Int']['output'];
  mealAmount: Scalars['Int']['output'];
};

export type ContractAllowanceInput = {
  accomodationAmount?: InputMaybe<Scalars['Int']['input']>;
  mealAmount?: InputMaybe<Scalars['Int']['input']>;
};

export type ContractChild = Node & {
  __typename: 'ContractChild';
  additionalInformation: Maybe<Scalars['String']['output']>;
  ageDescription: Scalars['String']['output'];
  ageRangeInMonths: ContractChildAgeRange;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ContractChildAgeRange = {
  __typename: 'ContractChildAgeRange';
  end: Maybe<Scalars['Int']['output']>;
  start: Scalars['Int']['output'];
};

export type ContractChildAgeRangeInput = {
  end?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['Int']['input'];
};

export type ContractChildInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  ageRangeInMonths: ContractChildAgeRangeInput;
  firstName: Scalars['String']['input'];
};

export type ContractCustomClause = Node & {
  __typename: 'ContractCustomClause';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type ContractCustomClauseInput = {
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type ContractEmergencyContact = Node & {
  __typename: 'ContractEmergencyContact';
  email: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  middleName: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  relationship: Scalars['String']['output'];
};

export type ContractEmergencyContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  relationship: Scalars['String']['input'];
};

export type ContractHolidays = {
  __typename: 'ContractHolidays';
  additional: Maybe<Scalars['String']['output']>;
  paid: Array<Holiday>;
  unpaid: Array<Holiday>;
};

export type ContractHolidaysInput = {
  additional?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Array<Holiday>>;
  unpaid?: InputMaybe<Array<Holiday>>;
};

export type ContractNanny = ContractParty & Node & {
  __typename: 'ContractNanny';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  middleName: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
};

export type ContractParent = ContractParty & Node & {
  __typename: 'ContractParent';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  middleName: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
};

export type ContractParty = {
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  middleName: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
};

export type ContractPartyInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
};

export type ContractPayment = {
  __typename: 'ContractPayment';
  dayOfWeek: Maybe<DayOfWeek>;
  frequency: ContractPaymentFrequency;
  medium: ContractPaymentMethod;
  rate: Scalars['Int']['output'];
  secondaryRate: Maybe<Scalars['Int']['output']>;
  type: ContractPaymentType;
};

export enum ContractPaymentFrequency {
  BiWeekly = 'BI_WEEKLY',
  SemiMonthly = 'SEMI_MONTHLY',
  Weekly = 'WEEKLY'
}

export type ContractPaymentInput = {
  dayOfWeek?: InputMaybe<DayOfWeek>;
  frequency: ContractPaymentFrequency;
  medium: ContractPaymentMethod;
  rate: Scalars['Int']['input'];
  secondaryRate?: InputMaybe<Scalars['Int']['input']>;
  type: ContractPaymentType;
};

export enum ContractPaymentMethod {
  Bank = 'BANK',
  Cash = 'CASH',
  Check = 'CHECK',
  Other = 'OTHER',
  Payroll = 'PAYROLL',
  Transfer = 'TRANSFER'
}

export enum ContractPaymentType {
  Hourly = 'HOURLY',
  Salary = 'SALARY'
}

export type ContractSchedule = Node & Schedule & {
  __typename: 'ContractSchedule';
  days: Array<DayOfWeek>;
  endTime: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  startTime: Scalars['Int']['output'];
};

export type ContractSickLeave = {
  __typename: 'ContractSickLeave';
  daysOff: Scalars['Int']['output'];
  isPaid: Scalars['Boolean']['output'];
};

export type ContractSickLeaveInput = {
  daysOff: Scalars['Int']['input'];
  isPaid: Scalars['Boolean']['input'];
};

export type ContractTermination = {
  __typename: 'ContractTermination';
  noticeInWeeks: Scalars['Int']['output'];
};

export type ContractTerminationInput = {
  noticeInWeeks: Scalars['Int']['input'];
};

export type ContractVacation = {
  __typename: 'ContractVacation';
  daysOff: Scalars['Int']['output'];
  isCarryOver: Scalars['Boolean']['output'];
  isPaid: Scalars['Boolean']['output'];
  isPaidOutOnTermination: Maybe<Scalars['Boolean']['output']>;
};

export type ContractVacationInput = {
  daysOff: Scalars['Int']['input'];
  isCarryOver: Scalars['Boolean']['input'];
  isPaid: Scalars['Boolean']['input'];
  isPaidOutOnTermination?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The connection type for UserListingInterest. */
export type ConversationConnection = {
  __typename: 'ConversationConnection';
  count: Scalars['Int']['output'];
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UserListingInterestEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CostDirectory = Node & {
  __typename: 'CostDirectory';
  directoryKeyword: DirectoryKeyword;
  id: Scalars['ID']['output'];
  leastExpensiveCities: Array<CityAverageHourlyRate>;
  listings: ListingConnectionWithCount;
  metaDescription: Scalars['String']['output'];
  mostExpensiveCities: Array<CityAverageHourlyRate>;
  relatedJourneyStep: Maybe<JourneyStep>;
  statistics: Maybe<CostStatistics>;
  statisticsByCity: Maybe<Array<CostStatistics>>;
  statisticsByFacet: Maybe<Array<CostStatistics>>;
  statisticsCapableCitiesInState: Array<City>;
  statisticsCapableStates: Array<State>;
  title: Scalars['String']['output'];
  webClientPath: Scalars['String']['output'];
};


export type CostDirectoryLeastExpensiveCitiesArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  profileType: ProfileType;
  stateSlug: InputMaybe<Scalars['String']['input']>;
};


export type CostDirectoryListingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  citySlug: Scalars['String']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  stateSlug: Scalars['String']['input'];
};


export type CostDirectoryMetaDescriptionArgs = {
  citySlug: InputMaybe<Scalars['String']['input']>;
  stateSlug: InputMaybe<Scalars['String']['input']>;
};


export type CostDirectoryMostExpensiveCitiesArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  profileType: ProfileType;
  stateSlug: InputMaybe<Scalars['String']['input']>;
};


export type CostDirectoryStatisticsArgs = {
  citySlug: InputMaybe<Scalars['String']['input']>;
  stateSlug: InputMaybe<Scalars['String']['input']>;
};


export type CostDirectoryStatisticsByCityArgs = {
  numberOfCities: Scalars['Int']['input'];
  sort: SortDirection;
  stateSlug: InputMaybe<Scalars['String']['input']>;
};


export type CostDirectoryStatisticsByFacetArgs = {
  citySlug: InputMaybe<Scalars['String']['input']>;
  includeFacets?: InputMaybe<Array<BooleanFacet>>;
  sort: SortDirection;
  stateSlug: InputMaybe<Scalars['String']['input']>;
};


export type CostDirectoryStatisticsCapableCitiesInStateArgs = {
  stateSlug: Scalars['String']['input'];
};


export type CostDirectoryTitleArgs = {
  citySlug: InputMaybe<Scalars['String']['input']>;
  stateSlug: InputMaybe<Scalars['String']['input']>;
};

export type CostStatistics = {
  __typename: 'CostStatistics';
  average: Scalars['Int']['output'];
  city: Maybe<City>;
  count: Scalars['Int']['output'];
  facet: Maybe<BooleanFacet>;
  facetDirectoryKeyword: Maybe<DirectoryKeyword>;
  frequencyDistribution: Array<CostStatisticsFrequency>;
  maximum: Scalars['Int']['output'];
  minimum: Scalars['Int']['output'];
  percentiles: Array<CostStatisticsPercentile>;
};

export type CostStatisticsFrequency = {
  __typename: 'CostStatisticsFrequency';
  amount: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
};

export type CostStatisticsPercentile = {
  __typename: 'CostStatisticsPercentile';
  amount: Scalars['Int']['output'];
  percentile: Scalars['Int']['output'];
};

export type Country = Node & {
  __typename: 'Country';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  state: Maybe<State>;
};


export type CountryStateArgs = {
  slug: Scalars['String']['input'];
};

/** Autogenerated input type of CreateNannyListing */
export type CreateNannyListingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nannyListing: NannyListingCreateInput;
};

/** Autogenerated return type of CreateNannyListing */
export type CreateNannyListingPayload = MutationWithErrorsResult & {
  __typename: 'CreateNannyListingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  nannyListing: Maybe<NannyListing>;
};

/** Autogenerated input type of CreateOrUpdateFamilyListing */
export type CreateOrUpdateFamilyListingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  gptExperimentVariant?: InputMaybe<Scalars['String']['input']>;
  listing?: InputMaybe<FamilyListingInput>;
};

/** Autogenerated return type of CreateOrUpdateFamilyListing */
export type CreateOrUpdateFamilyListingPayload = MutationWithErrorsResult & {
  __typename: 'CreateOrUpdateFamilyListingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  listing: Maybe<FamilyListing>;
};

/** Autogenerated input type of CreateOrUpdateFamilyPairListing */
export type CreateOrUpdateFamilyPairListingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  listing?: InputMaybe<FamilyPairListingInput>;
};

/** Autogenerated return type of CreateOrUpdateFamilyPairListing */
export type CreateOrUpdateFamilyPairListingPayload = MutationWithErrorsResult & {
  __typename: 'CreateOrUpdateFamilyPairListingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  listing: Maybe<FamilyPairListing>;
};

/** Autogenerated input type of CreateOrUpdateNannyListing */
export type CreateOrUpdateNannyListingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  listing?: InputMaybe<NannyListingUpdateInput>;
};

/** Autogenerated return type of CreateOrUpdateNannyListing */
export type CreateOrUpdateNannyListingPayload = MutationWithErrorsResult & {
  __typename: 'CreateOrUpdateNannyListingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  listing: Maybe<NannyListing>;
};

/** Autogenerated input type of CreateOrUpdatePayrollClientRegistration */
export type CreateOrUpdatePayrollClientRegistrationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentUserToken: Scalars['String']['input'];
  payrollClientRegistration: PayrollClientRegistrationInput;
};

/** Autogenerated return type of CreateOrUpdatePayrollClientRegistration */
export type CreateOrUpdatePayrollClientRegistrationPayload = MutationWithErrorsResult & {
  __typename: 'CreateOrUpdatePayrollClientRegistrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  payrollClientRegistration: Maybe<PayrollClientRegistration>;
};

/** Autogenerated input type of CreateOrUpdatePayrollEmployeeRegistration */
export type CreateOrUpdatePayrollEmployeeRegistrationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentUserToken: Scalars['String']['input'];
  payrollEmployeeRegistration: PayrollEmployeeRegistrationInput;
};

/** Autogenerated return type of CreateOrUpdatePayrollEmployeeRegistration */
export type CreateOrUpdatePayrollEmployeeRegistrationPayload = MutationWithErrorsResult & {
  __typename: 'CreateOrUpdatePayrollEmployeeRegistrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  payrollEmployeeRegistration: Maybe<PayrollEmployeeRegistration>;
};

/** Autogenerated input type of CreateOrUpdateShareListing */
export type CreateOrUpdateShareListingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  listing?: InputMaybe<ShareListingInput>;
};

/** Autogenerated return type of CreateOrUpdateShareListing */
export type CreateOrUpdateShareListingPayload = MutationWithErrorsResult & {
  __typename: 'CreateOrUpdateShareListingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  listing: Maybe<ShareListing>;
};

/** Autogenerated input type of CreateOrUpdateShareNanny */
export type CreateOrUpdateShareNannyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<ShareNannyInput>;
};

/** Autogenerated return type of CreateOrUpdateShareNanny */
export type CreateOrUpdateShareNannyPayload = MutationWithErrorsResult & {
  __typename: 'CreateOrUpdateShareNannyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  profile: Maybe<ShareNanny>;
};

/** Autogenerated input type of CreateOrUpdateUserDocument */
export type CreateOrUpdateUserDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentType: UserDocumentType;
  originalFilename: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

/** Autogenerated return type of CreateOrUpdateUserDocument */
export type CreateOrUpdateUserDocumentPayload = MutationWithErrorsResult & {
  __typename: 'CreateOrUpdateUserDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  document: Maybe<UserDocument>;
  errors: Maybe<Errors>;
  profile: Maybe<Profile>;
};

/** Autogenerated input type of CreateReference */
export type CreateReferenceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reference: ReferenceInput;
  referenceToken: Scalars['String']['input'];
};

/** Autogenerated return type of CreateReference */
export type CreateReferencePayload = MutationWithErrorsResult & {
  __typename: 'CreateReferencePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  reference: Maybe<Reference>;
};

/** Autogenerated input type of CreateUserReportMutation */
export type CreateUserReportMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reason: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateUserReportMutation */
export type CreateUserReportMutationPayload = MutationWithErrorsResult & {
  __typename: 'CreateUserReportMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  userReport: Maybe<UserReport>;
};

/** JSON for custom hiring assistance requirements */
export type CustomRequirement = {
  isAccepted: Scalars['Boolean']['input'];
  slug: Scalars['String']['input'];
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type Daycare = Node & {
  __typename: 'Daycare';
  address: Scalars['String']['output'];
  city: City;
  daycareDirectoryKeyword: DaycareDirectoryKeyword;
  email: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image: DaycareImage;
  name: Scalars['String']['output'];
  nearbyCities: CityConnection;
  nearbyNannyListings: ListingConnectionWithCount;
  nearbyNannyShareListings: ListingConnectionWithCount;
  phoneNumber: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  relatedDirectoryKeywords: DirectoryKeywordConnection;
  similarDaycares: DaycareConnectionWithCount;
  slug: Scalars['String']['output'];
  state: State;
};


export type DaycareNearbyCitiesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type DaycareNearbyNannyListingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type DaycareNearbyNannyShareListingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type DaycareRelatedDirectoryKeywordsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type DaycareSimilarDaycaresArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Daycare. */
export type DaycareConnectionWithCount = {
  __typename: 'DaycareConnectionWithCount';
  count: Scalars['Int']['output'];
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DaycareEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type DaycareDirectoryKeyword = Node & {
  __typename: 'DaycareDirectoryKeyword';
  cities: CityConnection;
  daycares: DaycareConnectionWithCount;
  gradientType: ListingType;
  id: Scalars['ID']['output'];
  metaDescription: Scalars['String']['output'];
  name: Scalars['String']['output'];
  nearbyCities: CityConnection;
  slug: Scalars['String']['output'];
  states: StateConnection;
  title: Scalars['String']['output'];
  webClientPath: Scalars['String']['output'];
};


export type DaycareDirectoryKeywordCitiesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  stateSlug: InputMaybe<Scalars['String']['input']>;
};


export type DaycareDirectoryKeywordDaycaresArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  citySlug: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  page: InputMaybe<Scalars['Int']['input']>;
  stateSlug: InputMaybe<Scalars['String']['input']>;
};


export type DaycareDirectoryKeywordMetaDescriptionArgs = {
  citySlug: InputMaybe<Scalars['String']['input']>;
  stateSlug: InputMaybe<Scalars['String']['input']>;
};


export type DaycareDirectoryKeywordNameArgs = {
  letterCase: InputMaybe<LetterCase>;
  pluralize?: InputMaybe<Scalars['Boolean']['input']>;
};


export type DaycareDirectoryKeywordNearbyCitiesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  citySlug: Scalars['String']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  stateSlug: Scalars['String']['input'];
};


export type DaycareDirectoryKeywordStatesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type DaycareDirectoryKeywordTitleArgs = {
  citySlug: InputMaybe<Scalars['String']['input']>;
  stateSlug: InputMaybe<Scalars['String']['input']>;
};

/** An edge in a connection. */
export type DaycareEdge = {
  __typename: 'DaycareEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Daycare>;
};

export type DaycareImage = Node & {
  __typename: 'DaycareImage';
  alt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};


export type DaycareImageUrlArgs = {
  transformations: InputMaybe<ImageTransformation>;
};

/** Autogenerated input type of DeclineBackgroundCheck */
export type DeclineBackgroundCheckInput = {
  backgroundCheckId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeclineBackgroundCheck */
export type DeclineBackgroundCheckPayload = MutationWithErrorsResult & {
  __typename: 'DeclineBackgroundCheckPayload';
  backgroundCheck: Maybe<BackgroundCheck>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

/** Autogenerated input type of DeclineInterview */
export type DeclineInterviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  interviewId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeclineInterview */
export type DeclineInterviewPayload = MutationWithErrorsResult & {
  __typename: 'DeclineInterviewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  interview: Maybe<Interview>;
};

/** Autogenerated input type of DeclineOffer */
export type DeclineOfferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  declineReason?: InputMaybe<Scalars['String']['input']>;
  offerId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeclineOffer */
export type DeclineOfferPayload = MutationWithErrorsResult & {
  __typename: 'DeclineOfferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  offer: Maybe<Offer>;
};

/** Autogenerated input type of DeclineReferenceContactRequest */
export type DeclineReferenceContactRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  referenceContactRequestId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeclineReferenceContactRequest */
export type DeclineReferenceContactRequestPayload = MutationWithErrorsResult & {
  __typename: 'DeclineReferenceContactRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  referenceContactRequest: Maybe<ReferenceContactRequest>;
};

/** Autogenerated input type of DeclineToJoinFamily */
export type DeclineToJoinFamilyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  familyListingToken: Scalars['String']['input'];
};

/** Autogenerated return type of DeclineToJoinFamily */
export type DeclineToJoinFamilyPayload = MutationWithErrorsResult & {
  __typename: 'DeclineToJoinFamilyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

/** Autogenerated input type of DeleteAccountMutation */
export type DeleteAccountMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeleteAccountMutation */
export type DeleteAccountMutationPayload = MutationWithErrorsResult & {
  __typename: 'DeleteAccountMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

/** Autogenerated input type of DeleteUserDocument */
export type DeleteUserDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentType: UserDocumentType;
};

/** Autogenerated return type of DeleteUserDocument */
export type DeleteUserDocumentPayload = MutationWithErrorsResult & {
  __typename: 'DeleteUserDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  document: Maybe<UserDocument>;
  errors: Maybe<Errors>;
  profile: Maybe<Profile>;
};

export type DirectoryKeyword = Node & {
  __typename: 'DirectoryKeyword';
  audienceName: Scalars['String']['output'];
  audienceProfileType: ProfileType;
  cities: Maybe<CityConnection>;
  costDirectory: Maybe<CostDirectory>;
  description: Scalars['String']['output'];
  facetDirectoryKeywords: Maybe<Array<DirectoryKeyword>>;
  gradientType: ListingType;
  id: Scalars['ID']['output'];
  listings: Maybe<ListingConnectionWithCount>;
  metaDescription: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nearbyCities: CityConnection;
  neighborhoods: Maybe<NeighborhoodConnection>;
  parentDirectoryKeyword: Maybe<DirectoryKeyword>;
  queryFacet: Maybe<BooleanFacet>;
  references: Array<Reference>;
  relatedJourney: Journey;
  slug: Scalars['String']['output'];
  states: Maybe<StateConnection>;
  title: Maybe<Scalars['String']['output']>;
  webClientPath: Scalars['String']['output'];
};


export type DirectoryKeywordAudienceNameArgs = {
  letterCase: InputMaybe<LetterCase>;
  pluralize?: InputMaybe<Scalars['Boolean']['input']>;
};


export type DirectoryKeywordCitiesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  stateSlug: InputMaybe<Scalars['String']['input']>;
};


export type DirectoryKeywordDescriptionArgs = {
  citySlug: InputMaybe<Scalars['String']['input']>;
  stateSlug: InputMaybe<Scalars['String']['input']>;
};


export type DirectoryKeywordListingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  citySlug: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  neighborhoodSlug: InputMaybe<Scalars['String']['input']>;
  secondary: InputMaybe<Scalars['Boolean']['input']>;
  stateSlug: InputMaybe<Scalars['String']['input']>;
};


export type DirectoryKeywordMetaDescriptionArgs = {
  citySlug: InputMaybe<Scalars['String']['input']>;
  stateSlug: InputMaybe<Scalars['String']['input']>;
};


export type DirectoryKeywordNameArgs = {
  letterCase: InputMaybe<LetterCase>;
  pluralize?: InputMaybe<Scalars['Boolean']['input']>;
};


export type DirectoryKeywordNearbyCitiesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  citySlug: Scalars['String']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  stateSlug: Scalars['String']['input'];
};


export type DirectoryKeywordNeighborhoodsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  citySlug: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type DirectoryKeywordReferencesArgs = {
  citySlug: InputMaybe<Scalars['String']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  stateSlug: InputMaybe<Scalars['String']['input']>;
};


export type DirectoryKeywordStatesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type DirectoryKeywordTitleArgs = {
  citySlug: InputMaybe<Scalars['String']['input']>;
  stateSlug: InputMaybe<Scalars['String']['input']>;
};

/** The connection type for DirectoryKeyword. */
export type DirectoryKeywordConnection = {
  __typename: 'DirectoryKeywordConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DirectoryKeywordEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<DirectoryKeyword>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DirectoryKeywordEdge = {
  __typename: 'DirectoryKeywordEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<DirectoryKeyword>;
};

/** Autogenerated input type of DisableUser */
export type DisableUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DisableUser */
export type DisableUserPayload = MutationWithErrorsResult & {
  __typename: 'DisableUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  user: Maybe<User>;
};

/** Autogenerated input type of EmailLoginUser */
export type EmailLoginUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** Autogenerated return type of EmailLoginUser */
export type EmailLoginUserPayload = MutationWithErrorsResult & {
  __typename: 'EmailLoginUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  user: Maybe<User>;
  viewer: Maybe<Viewer>;
};

/** Autogenerated input type of EmailSignupUser */
export type EmailSignupUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** Autogenerated return type of EmailSignupUser */
export type EmailSignupUserPayload = MutationWithErrorsResult & {
  __typename: 'EmailSignupUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  user: Maybe<User>;
};

/** Autogenerated input type of EnableUser */
export type EnableUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EnableUser */
export type EnableUserPayload = MutationWithErrorsResult & {
  __typename: 'EnableUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  user: Maybe<User>;
};

/** Errors associated with specific fields, as well as generic errors */
export type Errors = {
  __typename: 'Errors';
  /** Errors that do not apply to a specific field */
  allFields: Maybe<Scalars['String']['output']>;
  /** Errors that apply to specific fields */
  fields: Maybe<Array<Maybe<FieldError>>>;
};

/** An age group that a nanny has worked with */
export type ExperienceChildInput = {
  ageGroup?: InputMaybe<AgeGroup>;
};

export type ExperienceChildren = Node & {
  __typename: 'ExperienceChildren';
  ageGroup: AgeGroup;
  id: Scalars['ID']['output'];
};

export type Family = Node & Profile & {
  __typename: 'Family';
  address: Maybe<Address>;
  avatar: Avatar;
  canRefer: Scalars['Boolean']['output'];
  children: Maybe<ChildConnection>;
  displayName: Maybe<Scalars['String']['output']>;
  documentOwnerCredentials: Maybe<UserDocumentCredentials>;
  documents: Maybe<Array<UserDocument>>;
  employer: Maybe<PayrollEmployer>;
  familyListing: Maybe<FamilyListing>;
  familyPairListing: Maybe<FamilyPairListing>;
  firstName: Maybe<Scalars['String']['output']>;
  fullName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use `user.is_complete` instead */
  isComplete: Scalars['Boolean']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  listing: Maybe<Listing>;
  payroll: Maybe<PayrollProfile>;
  referenceToken: Maybe<Scalars['String']['output']>;
  references: Maybe<Array<Reference>>;
  type: ProfileType;
  user: Maybe<User>;
};


export type FamilyCanReferArgs = {
  referenceToken: Scalars['String']['input'];
};


export type FamilyChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type FamilyDocumentOwnerCredentialsArgs = {
  expireInSeconds?: InputMaybe<Scalars['Int']['input']>;
};

export type FamilyInput = {
  address?: InputMaybe<AddressInput>;
  avatar?: InputMaybe<AvatarInput>;
  children?: InputMaybe<Array<InputMaybe<ChildInput>>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type FamilyListing = Listing & Node & {
  __typename: 'FamilyListing';
  activeInterviews: Array<Interview>;
  canAccess: Scalars['Boolean']['output'];
  canJoin: Scalars['Boolean']['output'];
  canMessage: Scalars['Boolean']['output'];
  candidateInvitations: Array<Candidate>;
  cpr: Maybe<Scalars['Boolean']['output']>;
  createdAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  directoryKeyword: Maybe<DirectoryKeyword>;
  displayName: Maybe<Scalars['String']['output']>;
  driversLicense: Maybe<Scalars['Boolean']['output']>;
  family: Maybe<Family>;
  firstAid: Maybe<Scalars['Boolean']['output']>;
  fluentEnglish: Maybe<Scalars['Boolean']['output']>;
  fluentSpanish: Maybe<Scalars['Boolean']['output']>;
  fullTime: Maybe<Scalars['Boolean']['output']>;
  gptDescription: Maybe<Scalars['String']['output']>;
  hasUnreadNotifications: Scalars['Boolean']['output'];
  hourRanges: Maybe<HourRangeConnection>;
  hourlyRate: Maybe<Scalars['Int']['output']>;
  hoursPerWeek: Maybe<Scalars['Int']['output']>;
  housekeeping: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  incompatibilityError: Maybe<Scalars['String']['output']>;
  interestedInPayroll: Maybe<Scalars['Boolean']['output']>;
  interestedInShare: Maybe<Scalars['Boolean']['output']>;
  isCandidate: Scalars['Boolean']['output'];
  /** @deprecated Use `user.is_complete` instead */
  isComplete: Scalars['Boolean']['output'];
  isEarlyAccessible: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  isGptDescriptionEnabled: Scalars['Boolean']['output'];
  isOwner: Scalars['Boolean']['output'];
  liveIn: Maybe<Scalars['Boolean']['output']>;
  mealPreparation: Maybe<Scalars['Boolean']['output']>;
  messages: Maybe<MessageConnection>;
  /** @deprecated No longer used. */
  messagesWithCurrentUser: Maybe<Array<Message>>;
  nearbyCities: Maybe<CityConnection>;
  newListing: Maybe<Listing>;
  occasionalWork: Maybe<Scalars['Boolean']['output']>;
  partTime: Maybe<Scalars['Boolean']['output']>;
  positionDuration: Maybe<PositionDuration>;
  profile: Profile;
  redactedDescription: Maybe<RedactedText>;
  relatedListings: Array<Listing>;
  relevantKeywords: Maybe<DirectoryKeywordConnection>;
  shareableToken: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  specialNeeds: Maybe<Scalars['Boolean']['output']>;
  startDate: Maybe<Scalars['ISO8601Date']['output']>;
  transportation: Maybe<Scalars['Boolean']['output']>;
  type: ListingType;
  updatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  userListingInterest: Maybe<UserListingInterest>;
};


export type FamilyListingCanJoinArgs = {
  token: Scalars['String']['input'];
};


export type FamilyListingHourRangesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type FamilyListingMessagesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type FamilyListingMessagesWithCurrentUserArgs = {
  last: InputMaybe<Scalars['Int']['input']>;
};


export type FamilyListingNearbyCitiesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type FamilyListingRelatedListingsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
};


export type FamilyListingRelevantKeywordsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type FamilyListingInput = {
  cpr?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  driversLicense?: InputMaybe<Scalars['Boolean']['input']>;
  firstAid?: InputMaybe<Scalars['Boolean']['input']>;
  fluentEnglish?: InputMaybe<Scalars['Boolean']['input']>;
  fluentSpanish?: InputMaybe<Scalars['Boolean']['input']>;
  fullTime?: InputMaybe<Scalars['Boolean']['input']>;
  hourlyRate?: InputMaybe<Scalars['Int']['input']>;
  hoursPerWeek?: InputMaybe<Scalars['Int']['input']>;
  housekeeping?: InputMaybe<Scalars['Boolean']['input']>;
  interestedInPayroll?: InputMaybe<Scalars['Boolean']['input']>;
  interestedInShare?: InputMaybe<Scalars['Boolean']['input']>;
  liveIn?: InputMaybe<Scalars['Boolean']['input']>;
  mealPreparation?: InputMaybe<Scalars['Boolean']['input']>;
  occasionalWork?: InputMaybe<Scalars['Boolean']['input']>;
  partTime?: InputMaybe<Scalars['Boolean']['input']>;
  positionDuration?: InputMaybe<PositionDuration>;
  schedules?: InputMaybe<Array<ScheduleInput>>;
  specialNeeds?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  transportation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FamilyPairListing = Listing & Node & {
  __typename: 'FamilyPairListing';
  activeInterviews: Array<Interview>;
  canAccess: Scalars['Boolean']['output'];
  canMessage: Scalars['Boolean']['output'];
  candidateInvitations: Array<Candidate>;
  cpr: Maybe<Scalars['Boolean']['output']>;
  createdAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  directoryKeyword: Maybe<DirectoryKeyword>;
  displayName: Maybe<Scalars['String']['output']>;
  driversLicense: Maybe<Scalars['Boolean']['output']>;
  family: Maybe<Family>;
  firstAid: Maybe<Scalars['Boolean']['output']>;
  fluentEnglish: Maybe<Scalars['Boolean']['output']>;
  fluentSpanish: Maybe<Scalars['Boolean']['output']>;
  fullTime: Maybe<Scalars['Boolean']['output']>;
  hasUnreadNotifications: Scalars['Boolean']['output'];
  hourRanges: Maybe<HourRangeConnection>;
  hourlyRate: Maybe<Scalars['Int']['output']>;
  hoursPerWeek: Maybe<Scalars['Int']['output']>;
  housekeeping: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  incompatibilityError: Maybe<Scalars['String']['output']>;
  interestedInPayroll: Maybe<Scalars['Boolean']['output']>;
  isCandidate: Scalars['Boolean']['output'];
  /** @deprecated Use `user.is_complete` instead */
  isComplete: Scalars['Boolean']['output'];
  isEarlyAccessible: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  isOwner: Scalars['Boolean']['output'];
  isSplit: Maybe<Scalars['Boolean']['output']>;
  liveIn: Maybe<Scalars['Boolean']['output']>;
  mealPreparation: Maybe<Scalars['Boolean']['output']>;
  messages: Maybe<MessageConnection>;
  /** @deprecated No longer used. */
  messagesWithCurrentUser: Maybe<Array<Message>>;
  nearbyCities: Maybe<CityConnection>;
  occasionalWork: Maybe<Scalars['Boolean']['output']>;
  otherFamily: Family;
  otherProfile: Profile;
  partTime: Maybe<Scalars['Boolean']['output']>;
  partnerFamily: Family;
  positionDuration: Maybe<PositionDuration>;
  profile: Profile;
  redactedDescription: Maybe<RedactedText>;
  relatedListings: Array<Listing>;
  relevantKeywords: Maybe<DirectoryKeywordConnection>;
  shareableToken: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  specialNeeds: Maybe<Scalars['Boolean']['output']>;
  startDate: Maybe<Scalars['ISO8601Date']['output']>;
  transportation: Maybe<Scalars['Boolean']['output']>;
  type: ListingType;
  updatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  userListingInterest: Maybe<UserListingInterest>;
};


export type FamilyPairListingHourRangesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type FamilyPairListingMessagesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type FamilyPairListingMessagesWithCurrentUserArgs = {
  last: InputMaybe<Scalars['Int']['input']>;
};


export type FamilyPairListingNearbyCitiesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type FamilyPairListingRelatedListingsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
};


export type FamilyPairListingRelevantKeywordsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** Family Pair Listing */
export type FamilyPairListingInput = {
  cpr?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  driversLicense?: InputMaybe<Scalars['Boolean']['input']>;
  firstAid?: InputMaybe<Scalars['Boolean']['input']>;
  fluentEnglish?: InputMaybe<Scalars['Boolean']['input']>;
  fluentSpanish?: InputMaybe<Scalars['Boolean']['input']>;
  fullTime?: InputMaybe<Scalars['Boolean']['input']>;
  hourlyRate?: InputMaybe<Scalars['Int']['input']>;
  hoursPerWeek?: InputMaybe<Scalars['Int']['input']>;
  housekeeping?: InputMaybe<Scalars['Boolean']['input']>;
  interestedInPayroll?: InputMaybe<Scalars['Boolean']['input']>;
  liveIn?: InputMaybe<Scalars['Boolean']['input']>;
  mealPreparation?: InputMaybe<Scalars['Boolean']['input']>;
  occasionalWork?: InputMaybe<Scalars['Boolean']['input']>;
  partTime?: InputMaybe<Scalars['Boolean']['input']>;
  positionDuration?: InputMaybe<PositionDuration>;
  schedules?: InputMaybe<Array<ScheduleInput>>;
  specialNeeds?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  transportation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Feature = Node & {
  __typename: 'Feature';
  id: Scalars['ID']['output'];
  lookupKey: FeatureLookupKey;
  name: Scalars['String']['output'];
};

export type FeatureFlag = {
  __typename: 'FeatureFlag';
  enabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

/** Supported feature lookup keys */
export enum FeatureLookupKey {
  /** Ad free */
  AdFree = 'AdFree',
  /** Basic background checks */
  BasicBackgroundChecks = 'BasicBackgroundChecks',
  /** Candidate tracking */
  CandidateTracking = 'CandidateTracking',
  /** Early access jobs */
  EarlyAccessJobs = 'EarlyAccessJobs',
  /** Enhanced background checks */
  EnhancedBackgroundChecks = 'EnhancedBackgroundChecks',
  /** Featured listing */
  FeaturedListing = 'FeaturedListing',
  /** Higher search ranking */
  HigherSearchRanking = 'HigherSearchRanking',
  /** Hiring assistance */
  HiringAssistance = 'HiringAssistance',
  /** Interview scheduling */
  InterviewScheduling = 'InterviewScheduling',
  /** Nanny contract generator */
  NannyContractGenerator = 'NannyContractGenerator',
  /** Payroll */
  Payroll = 'Payroll',
  /** Publish listing */
  PublishListing = 'PublishListing',
  /** Unlimited messaging */
  UnlimitedMessaging = 'UnlimitedMessaging'
}

export type FieldError = {
  __typename: 'FieldError';
  /** Errors for field identified by name */
  error: Scalars['String']['output'];
  /** Field name */
  name: Scalars['String']['output'];
};

/** Gender */
export enum Gender {
  /** Female */
  Female = 'FEMALE',
  /** Male */
  Male = 'MALE',
  /** Other */
  Other = 'OTHER'
}

export type GeoCoordinates = {
  __typename: 'GeoCoordinates';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

/** Autogenerated input type of GivePlusFeedback */
export type GivePlusFeedbackInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['ID']['input'];
  rating: SubscriptionFeedbackRating;
};

/** Autogenerated return type of GivePlusFeedback */
export type GivePlusFeedbackPayload = MutationWithErrorsResult & {
  __typename: 'GivePlusFeedbackPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  subscription: Maybe<UserSubscription>;
  subscriptionFeedback: Maybe<SubscriptionFeedback>;
};

/** Autogenerated input type of GoogleLoginUser */
export type GoogleLoginUserInput = {
  accessToken: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of GoogleLoginUser */
export type GoogleLoginUserPayload = MutationWithErrorsResult & {
  __typename: 'GoogleLoginUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  user: Maybe<User>;
  viewer: Maybe<Viewer>;
};

/** Autogenerated input type of GoogleSignupUser */
export type GoogleSignupUserInput = {
  accessToken: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  profileType: ProfileType;
};

/** Autogenerated return type of GoogleSignupUser */
export type GoogleSignupUserPayload = MutationWithErrorsResult & {
  __typename: 'GoogleSignupUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  viewer: Maybe<Viewer>;
};

/** Autogenerated input type of HideReference */
export type HideReferenceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  referenceId: Scalars['ID']['input'];
};

/** Autogenerated return type of HideReference */
export type HideReferencePayload = MutationWithErrorsResult & {
  __typename: 'HideReferencePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  reference: Maybe<Reference>;
};

/** The connection type for Candidate. */
export type HiringAssistanceCandidateConnection = {
  __typename: 'HiringAssistanceCandidateConnection';
  count: Scalars['Int']['output'];
  countNew: Scalars['Int']['output'];
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CandidateEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export enum HiringAssistantCandidatesFilterEnum {
  New = 'new',
  Pending = 'pending',
  Unread = 'unread'
}

/** Stage of a candidate in the hiring process */
export enum HiringStage {
  BackgroundCheck = 'BACKGROUND_CHECK',
  Interview = 'INTERVIEW',
  ShortList = 'SHORT_LIST'
}

export enum Holiday {
  Christmas = 'CHRISTMAS',
  Independence = 'INDEPENDENCE',
  Indigenous = 'INDIGENOUS',
  Juneteenth = 'JUNETEENTH',
  Labor = 'LABOR',
  MartinLutherKing = 'MARTIN_LUTHER_KING',
  Memorial = 'MEMORIAL',
  NewYears = 'NEW_YEARS',
  Presidents = 'PRESIDENTS',
  Thanksgiving = 'THANKSGIVING',
  Veterans = 'VETERANS'
}

export type HourRange = Node & Schedule & {
  __typename: 'HourRange';
  days: Array<DayOfWeek>;
  endTime: Scalars['Int']['output'];
  friday: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  monday: Scalars['Boolean']['output'];
  saturday: Scalars['Boolean']['output'];
  startTime: Scalars['Int']['output'];
  sunday: Scalars['Boolean']['output'];
  thursday: Scalars['Boolean']['output'];
  tuesday: Scalars['Boolean']['output'];
  wednesday: Scalars['Boolean']['output'];
};

/** The connection type for HourRange. */
export type HourRangeConnection = {
  __typename: 'HourRangeConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<HourRangeEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<HourRange>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type HourRangeEdge = {
  __typename: 'HourRangeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<HourRange>;
};

export type ImageFileTypeConversion = {
  format?: InputMaybe<Scalars['String']['input']>;
};

export type ImageQuality = {
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type ImageResize = {
  fit?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type ImageRotate = {
  deg?: InputMaybe<Scalars['String']['input']>;
  exif?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ImageTransformation = {
  filetypeConversion?: InputMaybe<ImageFileTypeConversion>;
  quality?: InputMaybe<ImageQuality>;
  resize?: InputMaybe<ImageResize>;
  rotate?: InputMaybe<ImageRotate>;
};

export type Interest = Node & {
  __typename: 'Interest';
  id: Scalars['ID']['output'];
  isAccepted: Scalars['Boolean']['output'];
  isQuality: Scalars['Boolean']['output'];
};

export type Interview = Node & {
  __typename: 'Interview';
  canRespond: Scalars['Boolean']['output'];
  canView: Scalars['Boolean']['output'];
  contactMethod: Maybe<Scalars['String']['output']>;
  counterpart: Maybe<Listing>;
  createdAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  familyListing: Listing;
  id: Scalars['ID']['output'];
  interviewAppointments: Array<InterviewAppointment>;
  interviewLength: Scalars['String']['output'];
  interviewType: InterviewType;
  nannyListing: Listing;
  status: InterviewStatus;
  updatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type InterviewAppointment = Node & {
  __typename: 'InterviewAppointment';
  id: Scalars['ID']['output'];
  interview: Interview;
  interviewDatetime: Scalars['String']['output'];
  isExpired: Scalars['Boolean']['output'];
  status: InterviewAppoitmentStatus;
};

export type InterviewAppointmentInput = {
  interviewDatetime: Scalars['Int']['input'];
};

/** Status of interview appointment. If interivew is confirmed, it must have exactly 1 confirmed appointment */
export enum InterviewAppoitmentStatus {
  /** confirmed */
  Confirmed = 'confirmed',
  /** proposed */
  Proposed = 'proposed'
}

export enum InterviewStatus {
  /** cancelled */
  Cancelled = 'cancelled',
  /** confirmed */
  Confirmed = 'confirmed',
  /** declined */
  Declined = 'declined',
  /** expired */
  Expired = 'expired',
  /** requested */
  Requested = 'requested'
}

/** Interview Type (video, in-person, phone) */
export enum InterviewType {
  /** in_person */
  InPerson = 'in_person',
  /** phone */
  Phone = 'phone',
  /** video */
  Video = 'video'
}

/** Autogenerated input type of InviteToJoinFamily */
export type InviteToJoinFamilyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of InviteToJoinFamily */
export type InviteToJoinFamilyPayload = MutationWithErrorsResult & {
  __typename: 'InviteToJoinFamilyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

/** Autogenerated input type of InviteToJoinPayrollEmployeeRegistration */
export type InviteToJoinPayrollEmployeeRegistrationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentUserToken: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

/** Autogenerated return type of InviteToJoinPayrollEmployeeRegistration */
export type InviteToJoinPayrollEmployeeRegistrationPayload = MutationWithErrorsResult & {
  __typename: 'InviteToJoinPayrollEmployeeRegistrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  payrollRegistration: Maybe<PayrollRegistration>;
};

/** JSON where keys are requirements and values are booleans indicating whether they are accepted or not. */
export type JobRequirements = {
  children?: InputMaybe<Scalars['Boolean']['input']>;
  cpr?: InputMaybe<Scalars['Boolean']['input']>;
  customRequirements?: InputMaybe<Array<CustomRequirement>>;
  driversLicense?: InputMaybe<Scalars['Boolean']['input']>;
  firstAid?: InputMaybe<Scalars['Boolean']['input']>;
  fluentEnglish?: InputMaybe<Scalars['Boolean']['input']>;
  fluentSpanish?: InputMaybe<Scalars['Boolean']['input']>;
  hoursPerWeek?: InputMaybe<Scalars['Boolean']['input']>;
  housekeeping?: InputMaybe<Scalars['Boolean']['input']>;
  interestedInPayroll?: InputMaybe<Scalars['Boolean']['input']>;
  jobLocation: Scalars['Boolean']['input'];
  legallyEligible?: InputMaybe<Scalars['Boolean']['input']>;
  liveIn?: InputMaybe<Scalars['Boolean']['input']>;
  mealPreparation?: InputMaybe<Scalars['Boolean']['input']>;
  otherRequirements?: InputMaybe<Scalars['Boolean']['input']>;
  overMinimumAge?: InputMaybe<Scalars['Boolean']['input']>;
  positionDuration?: InputMaybe<Scalars['Boolean']['input']>;
  schedule?: InputMaybe<Scalars['Boolean']['input']>;
  specialNeeds?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Boolean']['input']>;
  transportation?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of JoinFamily */
export type JoinFamilyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  familyListingToken: Scalars['String']['input'];
};

/** Autogenerated return type of JoinFamily */
export type JoinFamilyPayload = MutationWithErrorsResult & {
  __typename: 'JoinFamilyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  familyPairListing: Maybe<FamilyPairListing>;
  viewer: Maybe<Viewer>;
};

/** Autogenerated input type of JoinPayrollClientRegistration */
export type JoinPayrollClientRegistrationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentUserToken: Scalars['String']['input'];
  payrollClientUserToken: Scalars['String']['input'];
};

/** Autogenerated return type of JoinPayrollClientRegistration */
export type JoinPayrollClientRegistrationPayload = MutationWithErrorsResult & {
  __typename: 'JoinPayrollClientRegistrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  payrollLinkedRegistration: Maybe<PayrollLinkedRegistration>;
};

/** Autogenerated input type of JoinPayrollEmployeeRegistration */
export type JoinPayrollEmployeeRegistrationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentUserToken: Scalars['String']['input'];
  payrollEmployeeUserToken: Scalars['String']['input'];
};

/** Autogenerated return type of JoinPayrollEmployeeRegistration */
export type JoinPayrollEmployeeRegistrationPayload = MutationWithErrorsResult & {
  __typename: 'JoinPayrollEmployeeRegistrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  payrollLinkedRegistration: Maybe<PayrollLinkedRegistration>;
};

export type Journey = Node & {
  __typename: 'Journey';
  audienceType: ProfileType;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageAlt: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  steps: Array<JourneyStep>;
  trendingArticles: Array<Article>;
  webClientPath: Scalars['String']['output'];
};


export type JourneyImageUrlArgs = {
  transformations: InputMaybe<ImageTransformation>;
};


export type JourneyNameArgs = {
  letterCase: InputMaybe<LetterCase>;
};


export type JourneyTrendingArticlesArgs = {
  limit: Scalars['Int']['input'];
};

export type JourneyStep = Node & {
  __typename: 'JourneyStep';
  articles: ArticleConnection;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageAlt: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  journey: Journey;
  name: Scalars['String']['output'];
  nextJourneyStep: Maybe<JourneyStep>;
  previousJourneyStep: Maybe<JourneyStep>;
  slug: Scalars['String']['output'];
  trendingArticles: Array<Article>;
  webClientPath: Scalars['String']['output'];
};


export type JourneyStepArticlesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type JourneyStepImageUrlArgs = {
  transformations: InputMaybe<ImageTransformation>;
};


export type JourneyStepTrendingArticlesArgs = {
  limit: Scalars['Int']['input'];
};

export enum LetterCase {
  /** A unicase style with no capital letters */
  Lower = 'LOWER',
  /** A mixed-case style in which the first word of all words capitalised */
  Title = 'TITLE',
  /** A unicase style with capital letters only */
  Upper = 'UPPER',
  /** A mixed-case style in which the first word of the sentence is capitalised */
  UpperFirst = 'UPPER_FIRST'
}

export type Listing = {
  activeInterviews: Array<Interview>;
  canAccess: Scalars['Boolean']['output'];
  canMessage: Scalars['Boolean']['output'];
  candidateInvitations: Array<Candidate>;
  createdAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  directoryKeyword: Maybe<DirectoryKeyword>;
  displayName: Maybe<Scalars['String']['output']>;
  fullTime: Maybe<Scalars['Boolean']['output']>;
  hasUnreadNotifications: Scalars['Boolean']['output'];
  hourRanges: Maybe<HourRangeConnection>;
  hourlyRate: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  incompatibilityError: Maybe<Scalars['String']['output']>;
  interestedInPayroll: Maybe<Scalars['Boolean']['output']>;
  isCandidate: Scalars['Boolean']['output'];
  /** @deprecated Use `user.is_complete` instead */
  isComplete: Scalars['Boolean']['output'];
  isEarlyAccessible: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  isOwner: Scalars['Boolean']['output'];
  liveIn: Maybe<Scalars['Boolean']['output']>;
  messages: Maybe<MessageConnection>;
  /** @deprecated No longer used. */
  messagesWithCurrentUser: Maybe<Array<Message>>;
  nearbyCities: Maybe<CityConnection>;
  occasionalWork: Maybe<Scalars['Boolean']['output']>;
  partTime: Maybe<Scalars['Boolean']['output']>;
  positionDuration: Maybe<PositionDuration>;
  profile: Profile;
  redactedDescription: Maybe<RedactedText>;
  relatedListings: Array<Listing>;
  relevantKeywords: Maybe<DirectoryKeywordConnection>;
  shareableToken: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  startDate: Maybe<Scalars['ISO8601Date']['output']>;
  type: ListingType;
  updatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  userListingInterest: Maybe<UserListingInterest>;
};


export type ListingHourRangesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type ListingMessagesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type ListingMessagesWithCurrentUserArgs = {
  last: InputMaybe<Scalars['Int']['input']>;
};


export type ListingNearbyCitiesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type ListingRelatedListingsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
};


export type ListingRelevantKeywordsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Listing. */
export type ListingConnection = {
  __typename: 'ListingConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ListingEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Listing>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** The connection type for Listing. */
export type ListingConnectionWithCount = {
  __typename: 'ListingConnectionWithCount';
  count: Scalars['Int']['output'];
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ListingEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ListingEdge = {
  __typename: 'ListingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Listing>;
};

/** Autogenerated input type of ListingMarkNotInterested */
export type ListingMarkNotInterestedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  listingId: Scalars['ID']['input'];
};

/** Autogenerated return type of ListingMarkNotInterested */
export type ListingMarkNotInterestedPayload = MutationWithErrorsResult & {
  __typename: 'ListingMarkNotInterestedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  listing: Listing;
};

export type ListingRole = {
  __typename: 'ListingRole';
  label: Maybe<Scalars['String']['output']>;
  value: ListingSearchType;
};

export type ListingSearchFilters = {
  archivedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  conversationRequestsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  distanceMax?: InputMaybe<Scalars['Int']['input']>;
  earlyAccessOnly?: InputMaybe<Scalars['Boolean']['input']>;
  excludedListingTypes?: InputMaybe<Array<ListingType>>;
  listingRole?: InputMaybe<ListingSearchType>;
  priceRangeMax?: InputMaybe<Scalars['Int']['input']>;
  priceRangeMaxInDollars?: InputMaybe<Scalars['Int']['input']>;
  priceRangeMin?: InputMaybe<Scalars['Int']['input']>;
  priceRangeMinInDollars?: InputMaybe<Scalars['Int']['input']>;
  requiredFacets?: InputMaybe<Array<BooleanFacet>>;
};

/** Listing Type */
export enum ListingSearchType {
  /** Only listing types designated as high value for the user */
  Default = 'DEFAULT',
  /** Only listing types related to nanny share */
  Shares = 'SHARES'
}

/** Listing Type */
export enum ListingType {
  /** FamilyListing */
  FamilyListing = 'FamilyListing',
  /** FamilyPairListing */
  FamilyPairListing = 'FamilyPairListing',
  /** N4hNannyListing */
  N4hNannyListing = 'N4hNannyListing',
  /** NannyListing */
  NannyListing = 'NannyListing',
  /** ShareListing */
  ShareListing = 'ShareListing'
}

/** Autogenerated input type of LogoutUser */
export type LogoutUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of LogoutUser */
export type LogoutUserPayload = MutationWithErrorsResult & {
  __typename: 'LogoutUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

/** Autogenerated input type of MakeOffer */
export type MakeOfferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offer: OfferInput;
  userListingInterestId: Scalars['ID']['input'];
};

/** Autogenerated return type of MakeOffer */
export type MakeOfferPayload = MutationWithErrorsResult & {
  __typename: 'MakeOfferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  offer: Maybe<Offer>;
  userListingInterest: Maybe<UserListingInterest>;
};

/** Autogenerated input type of MarkListingNotificationsRead */
export type MarkListingNotificationsReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  listing: Scalars['ID']['input'];
};

/** Autogenerated return type of MarkListingNotificationsRead */
export type MarkListingNotificationsReadPayload = MutationWithErrorsResult & {
  __typename: 'MarkListingNotificationsReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  listing: Maybe<Listing>;
  user: Maybe<User>;
};

/** Autogenerated input type of MarkMessageRead */
export type MarkMessageReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['ID']['input'];
  shouldMarkPreviousUnread?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of MarkMessageRead */
export type MarkMessageReadPayload = MutationWithErrorsResult & {
  __typename: 'MarkMessageReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  message: Maybe<Message>;
  messageUserEdge: Maybe<MessageUserEdge>;
};

/** Message from one to many users */
export type Message = Node & {
  __typename: 'Message';
  contentType: MessageContentType;
  createdAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  link: Maybe<Scalars['String']['output']>;
  recipients: Maybe<MessageUserConnection>;
  sender: Maybe<User>;
  text: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  users: Maybe<MessageUserConnection>;
};


/** Message from one to many users */
export type MessageRecipientsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/** Message from one to many users */
export type MessageUsersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Message. */
export type MessageConnection = {
  __typename: 'MessageConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<MessageEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Message>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Message Content Type (user text, system message, etc.) */
export enum MessageContentType {
  /** BACKGROUND_CHECK_ACCEPTED */
  BackgroundCheckAccepted = 'BACKGROUND_CHECK_ACCEPTED',
  /** BACKGROUND_CHECK_CLEARED */
  BackgroundCheckCleared = 'BACKGROUND_CHECK_CLEARED',
  /** BACKGROUND_CHECK_DECLINED */
  BackgroundCheckDeclined = 'BACKGROUND_CHECK_DECLINED',
  /** BACKGROUND_CHECK_EXPIRED */
  BackgroundCheckExpired = 'BACKGROUND_CHECK_EXPIRED',
  /** BACKGROUND_CHECK_FAILED */
  BackgroundCheckFailed = 'BACKGROUND_CHECK_FAILED',
  /** BACKGROUND_CHECK_PENDING */
  BackgroundCheckPending = 'BACKGROUND_CHECK_PENDING',
  /** BACKGROUND_CHECK_REMINDER */
  BackgroundCheckReminder = 'BACKGROUND_CHECK_REMINDER',
  /** BACKGROUND_CHECK_REQUESTED */
  BackgroundCheckRequested = 'BACKGROUND_CHECK_REQUESTED',
  /** BACKGROUND_CHECK_STATUS_UPDATED */
  BackgroundCheckStatusUpdated = 'BACKGROUND_CHECK_STATUS_UPDATED',
  /** BACKGROUND_CHECK_SUSPENDED */
  BackgroundCheckSuspended = 'BACKGROUND_CHECK_SUSPENDED',
  /** CONTACT_INFO_WARNING */
  ContactInfoWarning = 'CONTACT_INFO_WARNING',
  /** FAMILY_PAIR_LISTING_SPLIT */
  FamilyPairListingSplit = 'FAMILY_PAIR_LISTING_SPLIT',
  /** HIDE_PROFILE_PROMPT */
  HideProfilePrompt = 'HIDE_PROFILE_PROMPT',
  /** INTERVIEW_ACCEPTED */
  InterviewAccepted = 'INTERVIEW_ACCEPTED',
  /** INTERVIEW_CREATED */
  InterviewCreated = 'INTERVIEW_CREATED',
  /** INTERVIEW_REQUESTED */
  InterviewRequested = 'INTERVIEW_REQUESTED',
  /** INTERVIEW_STATUS_UPDATE */
  InterviewStatusUpdate = 'INTERVIEW_STATUS_UPDATE',
  /** LISTING_DESCRIPTION_UPDATED */
  ListingDescriptionUpdated = 'LISTING_DESCRIPTION_UPDATED',
  /** OFFER_ACCEPTED */
  OfferAccepted = 'OFFER_ACCEPTED',
  /** OFFER_AMENDED */
  OfferAmended = 'OFFER_AMENDED',
  /** OFFER_CREATED */
  OfferCreated = 'OFFER_CREATED',
  /** OFFER_DECLINED */
  OfferDeclined = 'OFFER_DECLINED',
  /** PLUS_AUTOMATION_ASSISTANT_MESSAGE */
  PlusAutomationAssistantMessage = 'PLUS_AUTOMATION_ASSISTANT_MESSAGE',
  /** PLUS_AUTOMATION_STATUS_UPDATE */
  PlusAutomationStatusUpdate = 'PLUS_AUTOMATION_STATUS_UPDATE',
  /** PLUS_FEEDBACK_ADMIN */
  PlusFeedbackAdmin = 'PLUS_FEEDBACK_ADMIN',
  /** PLUS_FEEDBACK_REQUEST */
  PlusFeedbackRequest = 'PLUS_FEEDBACK_REQUEST',
  /** PLUS_HIRING_REQUIREMENTS_RECEIVED */
  PlusHiringRequirementsReceived = 'PLUS_HIRING_REQUIREMENTS_RECEIVED',
  /** PLUS_HIRING_REQUIREMENTS_REQUESTED */
  PlusHiringRequirementsRequested = 'PLUS_HIRING_REQUIREMENTS_REQUESTED',
  /** REFERENCE_CONTACT_ACCEPTED */
  ReferenceContactAccepted = 'REFERENCE_CONTACT_ACCEPTED',
  /** REFERENCE_CONTACT_DECLINED */
  ReferenceContactDeclined = 'REFERENCE_CONTACT_DECLINED',
  /** REFERENCE_CONTACT_REQUESTED */
  ReferenceContactRequested = 'REFERENCE_CONTACT_REQUESTED',
  /** SHARE_INVITE_ACCEPTED */
  ShareInviteAccepted = 'SHARE_INVITE_ACCEPTED',
  /** SHARE_INVITE_AMENDED */
  ShareInviteAmended = 'SHARE_INVITE_AMENDED',
  /** SHARE_INVITE_CREATED */
  ShareInviteCreated = 'SHARE_INVITE_CREATED',
  /** SHARE_INVITE_DECLINED */
  ShareInviteDeclined = 'SHARE_INVITE_DECLINED',
  /** USER_INTRODUCTION */
  UserIntroduction = 'USER_INTRODUCTION',
  /** USER_MESSAGE */
  UserMessage = 'USER_MESSAGE'
}

/** An edge in a connection. */
export type MessageEdge = {
  __typename: 'MessageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Message>;
};

/** Autogenerated return type of MessageSentSubscription */
export type MessageSentSubscriptionPayload = {
  __typename: 'MessageSentSubscriptionPayload';
  listing: Listing;
  message: Maybe<Message>;
};

/** The connection type for User. */
export type MessageUserConnection = {
  __typename: 'MessageUserConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<MessageUserEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MessageUserEdge = {
  __typename: 'MessageUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isSender: Scalars['Boolean']['output'];
  /** The item at the end of the edge. */
  node: User;
  readAt: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type Mutation = {
  __typename: 'Mutation';
  /** Accept a requested background check by providing personal information */
  acceptBackgroundCheck: Maybe<AcceptBackgroundCheckPayload>;
  acceptCandidateInvitation: Maybe<CandidateAcceptInvitationMutationPayload>;
  /** Accept an interview. Can be performed by nanny users only */
  acceptInterview: Maybe<AcceptInterviewPayload>;
  /** Accept an offer */
  acceptOffer: Maybe<AcceptOfferPayload>;
  /** Accept a request for contact details for a reference */
  acceptReferenceContactRequest: Maybe<AcceptReferenceContactRequestPayload>;
  /** Archive a ULI */
  archive: Maybe<UserListingInterestArchivePayload>;
  /** Assign hiring stage of a candidate for tracking */
  assignCandidateHiringStage: Maybe<AssignCandidateHiringStageMutationPayload>;
  /** Attach a new default payment method */
  attachDefaultPaymentMethod: Maybe<AttachDefaultPaymentMethodPayload>;
  /** Build a new nanny contract */
  buildNannyContract: Maybe<BuildNannyContractPayload>;
  cancelInterview: Maybe<CancelInterviewPayload>;
  /** Cancel the current user's subscription */
  cancelSubscription: Maybe<CancelSubscriptionPayload>;
  /** Updates password if user is logged-in or has valid reset password token */
  changePassword: Maybe<ChangePasswordPayload>;
  completePayrollClientRegistration: Maybe<CompletePayrollClientRegistrationPayload>;
  completePayrollEmployeeRegistration: Maybe<CompletePayrollEmployeeRegistrationPayload>;
  /** Confirm Purchase after 3DS verification for a subscription */
  confirmSubscriptionPurchase: Maybe<ConfirmSubscriptionPurchasePayload>;
  /** DEPRECATED: Use CreateOrUpdateNannyListing instead */
  createNannyListing: Maybe<CreateNannyListingPayload>;
  /** Create a family listing for the signed in user, or update if it exists */
  createOrUpdateFamilyListing: Maybe<CreateOrUpdateFamilyListingPayload>;
  /** Create a family listing for the signed in user, or update if it exists */
  createOrUpdateFamilyPairListing: Maybe<CreateOrUpdateFamilyPairListingPayload>;
  /** Create a nanny listing for the signed in user, or update if it exists */
  createOrUpdateNannyListing: Maybe<CreateOrUpdateNannyListingPayload>;
  createOrUpdatePayrollClientRegistration: Maybe<CreateOrUpdatePayrollClientRegistrationPayload>;
  createOrUpdatePayrollEmployeeRegistration: Maybe<CreateOrUpdatePayrollEmployeeRegistrationPayload>;
  /** Create a share listing for the signed in user, or update if it exists */
  createOrUpdateShareListing: Maybe<CreateOrUpdateShareListingPayload>;
  /** Create a share nanny for the signed in user, or update if it exists */
  createOrUpdateShareNanny: Maybe<CreateOrUpdateShareNannyPayload>;
  /** Creates or updates a document and associates it with user's profile */
  createOrUpdateUserDocument: Maybe<CreateOrUpdateUserDocumentPayload>;
  /** Create a reference for a profile */
  createReference: Maybe<CreateReferencePayload>;
  /** Report a user for inappropriate behaviour */
  createUserReport: Maybe<CreateUserReportMutationPayload>;
  /** Decline a requested background check */
  declineBackgroundCheck: Maybe<DeclineBackgroundCheckPayload>;
  declineInterview: Maybe<DeclineInterviewPayload>;
  /** Decline an offer */
  declineOffer: Maybe<DeclineOfferPayload>;
  /** Decline a request for contact details for a reference */
  declineReferenceContactRequest: Maybe<DeclineReferenceContactRequestPayload>;
  /** Decline to join another family in forming a family pair */
  declineToJoinFamily: Maybe<DeclineToJoinFamilyPayload>;
  /** Delete user and all associated data */
  deleteAccount: Maybe<DeleteAccountMutationPayload>;
  deleteUserDocument: Maybe<DeleteUserDocumentPayload>;
  deliverCandidate: Maybe<CandidateDeliverMutationPayload>;
  /** Disable a User account */
  disableUser: Maybe<DisableUserPayload>;
  /** Download a document for a payroll employee */
  downloadPayrollEmployeeDocument: Maybe<PayrollEmployeeDownloadDocumentPayload>;
  /** Download a document for a payroll employer */
  downloadPayrollEmployerDocument: Maybe<PayrollEmployerDownloadDocumentPayload>;
  /** Authenticate a user with an email/password */
  emailLoginUser: Maybe<EmailLoginUserPayload>;
  /** Create a user with an email/password */
  emailSignupUser: Maybe<EmailSignupUserPayload>;
  /** Enable a User account */
  enableUser: Maybe<EnableUserPayload>;
  /** Provide feedback on currently active PLUS service in response to a request via message */
  givePlusFeedback: Maybe<GivePlusFeedbackPayload>;
  /** Authenticate a user by providing an access token from Google */
  googleLoginUser: Maybe<GoogleLoginUserPayload>;
  /** Authenticate and create a user with a profile, by providing a user access token from Google */
  googleSignupUser: Maybe<GoogleSignupUserPayload>;
  /** Marks a reference as hidden */
  hideReference: Maybe<HideReferencePayload>;
  inviteCandidate: Maybe<CandidateInviteMutationPayload>;
  /** Triggers the sending of an invitation email for an employee to join a payroll employer */
  invitePayrollEmployee: Maybe<PayrollEmployerInviteEmployeePayload>;
  /** Triggers the sending of an invitation email for an employer to join a payroll employee */
  invitePayrollEmployer: Maybe<PayrollEmployeeInviteEmployerPayload>;
  /** Invite another family via email to form a family pair */
  inviteToJoinFamily: Maybe<InviteToJoinFamilyPayload>;
  inviteToJoinPayrollEmployeeRegistration: Maybe<InviteToJoinPayrollEmployeeRegistrationPayload>;
  /** Join another family, given a token, to form a family pair listing */
  joinFamily: Maybe<JoinFamilyPayload>;
  joinPayrollClientRegistration: Maybe<JoinPayrollClientRegistrationPayload>;
  joinPayrollEmployeeRegistration: Maybe<JoinPayrollEmployeeRegistrationPayload>;
  /** Log out a user by invalidating their signed in token and removing cookie */
  logoutUser: Maybe<LogoutUserPayload>;
  /** Make a new offer */
  makeOffer: Maybe<MakeOfferPayload>;
  markInvitationNotInterested: Maybe<CandidateMarkNotInterestedMutationPayload>;
  markListingNotificationsRead: Maybe<MarkListingNotificationsReadPayload>;
  markMessageRead: Maybe<MarkMessageReadPayload>;
  markNotInterested: Maybe<ListingMarkNotInterestedPayload>;
  markUserListingInterestOfferRead: Maybe<UserListingInterestOfferMarkReadPayload>;
  markUserListingInterestRead: Maybe<UserListingInterestMarkReadPayload>;
  optInGptDescription: Maybe<OptInGptDescriptionMutationPayload>;
  performCheck: Maybe<PerformCheckPayload>;
  /** Send hiring requirements to the assistant assigned for the subscription */
  plusSendHiringRequirements: Maybe<PlusSendHiringRequirementsPayload>;
  /** Purchases a subscription for a product */
  purchaseProductSubscription: Maybe<PurchaseProductSubscriptionMutationPayload>;
  /** Purchase a PLUS subscription */
  purchaseSubscription: Maybe<PurchaseSubscriptionPayload>;
  rejectCandidateInvitation: Maybe<CandidateRejectInvitationMutationPayload>;
  /** Remove hiring stage of a candidate */
  removeCandidateHiringStage: Maybe<RemoveCandidateHiringStageMutationPayload>;
  /**
   * DEPRECATED: Request a background check from a user on the platform - Use
   * standalone_purchase or subscription_request mutation instead
   */
  requestBackgroundCheck: Maybe<RequestBackgroundCheckPayload>;
  /**
   * DEPRECATED: Request a background check from a user outside of the platform -
   * Use standalone_purchase or subscription_request mutation instead
   */
  requestExternalBackgroundCheck: Maybe<RequestExternalBackgroundCheckPayload>;
  /** Request an interview; takes interview_type and contact_method as arguments */
  requestInterview: Maybe<RequestInterviewPayload>;
  /** Request contact details for a reference */
  requestReferenceContact: Maybe<RequestReferenceContactPayload>;
  /** Request a background check for oneself */
  requestSelfBackgroundCheck: Maybe<RequestSelfBackgroundCheckPayload>;
  /** Reset a users password if user exists */
  resetPassword: Maybe<ResetPasswordPayload>;
  /** Reset a user password via provided phone number (if user exists) */
  resetPasswordByPhoneNumber: Maybe<ResetPasswordByPhoneNumberPayload>;
  /** Restore a ULI */
  restore: Maybe<UserListingInterestRestorePayload>;
  /** Creates payment intent for self background check */
  selfBackgroundCheckCreatePaymentIntent: Maybe<SelfBackgroundCheckCreatePaymentIntentPayload>;
  /** Send a new verification code to the currently logged in user */
  sendEmailVerificationCode: Maybe<SendEmailVerificationCodePayload>;
  sendMessage: Maybe<SendMessagePayload>;
  /** Send a phone number verification code to the currently logged in user */
  sendPhoneNumberVerificationCode: Maybe<SendPhoneNumberVerificationCodePayload>;
  /** Handles the onboarding process for a payroll employee user */
  signupEmployeeOnboarding: Maybe<SignupEmployeeOnboardingUserPayload>;
  /** Handles the onboarding process for a payroll employer user */
  signupEmployerOnboarding: Maybe<SignupEmployerOnboardingUserPayload>;
  /** Complete sign up for a family, consisting of: a user, profile and listing */
  signupFamily: Maybe<UserSignupFamilyPayload>;
  /** Handles the onboarding process for a family user */
  signupFamilyOnboarding: Maybe<SignupFamilyOnboardingUserPayload>;
  /** Sign up for a nanny, consisting of: a user, profile and optionally a listing */
  signupNanny: Maybe<UserSignupNannyPayload>;
  /** Handles the onboarding process for a nanny user */
  signupNannyOnboarding: Maybe<SignupNannyOnboardingUserPayload>;
  /** Complete sign up for a nanny share, consisting of: a user, profiles and listing */
  signupShareNanny: Maybe<UserSignupShareNannyPayload>;
  /** Split family pair listing into their own, previous listings */
  splitFamilyPairListing: Maybe<SplitFamilyPairListingPayload>;
  /** Confirms payment intent for standalone background check purchase for 3DS */
  standaloneConfirmPurchase: Maybe<StandaloneConfirmPurchasePayload>;
  /** Purchases standalone background check, returns 3DS fields if required */
  standalonePurchase: Maybe<StandalonePurchasePayload>;
  subscribeToJourneyNewsletter: Maybe<SubscribeToJourneyNewsletterPayload>;
  /** Request a background check using the users subscription */
  subscriptionRequest: Maybe<SubscriptionRequestBackgroundCheckPayload>;
  /** Update a family profile */
  updateFamily: Maybe<UpdateFamilyPayload>;
  /** Update a family listing */
  updateFamilyListing: Maybe<UpdateFamilyListingPayload>;
  /** Update a family pair listing */
  updateFamilyPairListing: Maybe<UpdateFamilyPairListingPayload>;
  /** Update a nanny profile */
  updateNanny: Maybe<UpdateNannyPayload>;
  /** DEPRECATED: Use CreateOrUpdateNannyListing instead */
  updateNannyListing: Maybe<UpdateNannyListingPayload>;
  /** Subscribe or unsubscribe the user from any number of notifications */
  updateNotificationSubscriptions: Maybe<UpdateNotificationSubscriptionsMutationPayload>;
  /** Update or create the primary avatar of a user profile */
  updatePrimaryAvatar: Maybe<AvatarUpdatePrimaryPayload>;
  /** Update a share listing */
  updateShareListing: Maybe<UpdateShareListingPayload>;
  /** Update a share nanny profile */
  updateShareNanny: Maybe<UpdateShareNannyPayload>;
  /** Update a time zone for the currently signed in user */
  updateTimeZone: Maybe<UpdateTimeZonePayload>;
  /** Update a User */
  updateUser: Maybe<UpdateUserPayload>;
  /** Verify the email of signed in user and optionally update phone number */
  verifyEmail: Maybe<VerifyEmailPayload>;
  /** Verify a phone number of the signed in user */
  verifyPhoneNumber: Maybe<VerifyPhoneNumberPayload>;
  /** Verify a promo on a product */
  verifyProductPromo: Maybe<VerifyProductPromoMutationPayload>;
  /** Verify a promo */
  verifyPromo: Maybe<VerifyPromoMutationPayload>;
};


export type MutationAcceptBackgroundCheckArgs = {
  input: AcceptBackgroundCheckInput;
};


export type MutationAcceptCandidateInvitationArgs = {
  input: CandidateAcceptInvitationMutationInput;
};


export type MutationAcceptInterviewArgs = {
  input: AcceptInterviewInput;
};


export type MutationAcceptOfferArgs = {
  input: AcceptOfferInput;
};


export type MutationAcceptReferenceContactRequestArgs = {
  input: AcceptReferenceContactRequestInput;
};


export type MutationArchiveArgs = {
  input: UserListingInterestArchiveInput;
};


export type MutationAssignCandidateHiringStageArgs = {
  input: AssignCandidateHiringStageMutationInput;
};


export type MutationAttachDefaultPaymentMethodArgs = {
  input: AttachDefaultPaymentMethodInput;
};


export type MutationBuildNannyContractArgs = {
  input: BuildNannyContractInput;
};


export type MutationCancelInterviewArgs = {
  input: CancelInterviewInput;
};


export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationCompletePayrollClientRegistrationArgs = {
  input: CompletePayrollClientRegistrationInput;
};


export type MutationCompletePayrollEmployeeRegistrationArgs = {
  input: CompletePayrollEmployeeRegistrationInput;
};


export type MutationConfirmSubscriptionPurchaseArgs = {
  input: ConfirmSubscriptionPurchaseInput;
};


export type MutationCreateNannyListingArgs = {
  input: CreateNannyListingInput;
};


export type MutationCreateOrUpdateFamilyListingArgs = {
  input: CreateOrUpdateFamilyListingInput;
};


export type MutationCreateOrUpdateFamilyPairListingArgs = {
  input: CreateOrUpdateFamilyPairListingInput;
};


export type MutationCreateOrUpdateNannyListingArgs = {
  input: CreateOrUpdateNannyListingInput;
};


export type MutationCreateOrUpdatePayrollClientRegistrationArgs = {
  input: CreateOrUpdatePayrollClientRegistrationInput;
};


export type MutationCreateOrUpdatePayrollEmployeeRegistrationArgs = {
  input: CreateOrUpdatePayrollEmployeeRegistrationInput;
};


export type MutationCreateOrUpdateShareListingArgs = {
  input: CreateOrUpdateShareListingInput;
};


export type MutationCreateOrUpdateShareNannyArgs = {
  input: CreateOrUpdateShareNannyInput;
};


export type MutationCreateOrUpdateUserDocumentArgs = {
  input: CreateOrUpdateUserDocumentInput;
};


export type MutationCreateReferenceArgs = {
  input: CreateReferenceInput;
};


export type MutationCreateUserReportArgs = {
  input: CreateUserReportMutationInput;
};


export type MutationDeclineBackgroundCheckArgs = {
  input: DeclineBackgroundCheckInput;
};


export type MutationDeclineInterviewArgs = {
  input: DeclineInterviewInput;
};


export type MutationDeclineOfferArgs = {
  input: DeclineOfferInput;
};


export type MutationDeclineReferenceContactRequestArgs = {
  input: DeclineReferenceContactRequestInput;
};


export type MutationDeclineToJoinFamilyArgs = {
  input: DeclineToJoinFamilyInput;
};


export type MutationDeleteAccountArgs = {
  input: DeleteAccountMutationInput;
};


export type MutationDeleteUserDocumentArgs = {
  input: DeleteUserDocumentInput;
};


export type MutationDeliverCandidateArgs = {
  input: CandidateDeliverMutationInput;
};


export type MutationDisableUserArgs = {
  input: DisableUserInput;
};


export type MutationDownloadPayrollEmployeeDocumentArgs = {
  input: PayrollEmployeeDownloadDocumentInput;
};


export type MutationDownloadPayrollEmployerDocumentArgs = {
  input: PayrollEmployerDownloadDocumentInput;
};


export type MutationEmailLoginUserArgs = {
  input: EmailLoginUserInput;
};


export type MutationEmailSignupUserArgs = {
  input: EmailSignupUserInput;
};


export type MutationEnableUserArgs = {
  input: EnableUserInput;
};


export type MutationGivePlusFeedbackArgs = {
  input: GivePlusFeedbackInput;
};


export type MutationGoogleLoginUserArgs = {
  input: GoogleLoginUserInput;
};


export type MutationGoogleSignupUserArgs = {
  input: GoogleSignupUserInput;
};


export type MutationHideReferenceArgs = {
  input: HideReferenceInput;
};


export type MutationInviteCandidateArgs = {
  input: CandidateInviteMutationInput;
};


export type MutationInvitePayrollEmployeeArgs = {
  input: PayrollEmployerInviteEmployeeInput;
};


export type MutationInvitePayrollEmployerArgs = {
  input: PayrollEmployeeInviteEmployerInput;
};


export type MutationInviteToJoinFamilyArgs = {
  input: InviteToJoinFamilyInput;
};


export type MutationInviteToJoinPayrollEmployeeRegistrationArgs = {
  input: InviteToJoinPayrollEmployeeRegistrationInput;
};


export type MutationJoinFamilyArgs = {
  input: JoinFamilyInput;
};


export type MutationJoinPayrollClientRegistrationArgs = {
  input: JoinPayrollClientRegistrationInput;
};


export type MutationJoinPayrollEmployeeRegistrationArgs = {
  input: JoinPayrollEmployeeRegistrationInput;
};


export type MutationLogoutUserArgs = {
  input: LogoutUserInput;
};


export type MutationMakeOfferArgs = {
  input: MakeOfferInput;
};


export type MutationMarkInvitationNotInterestedArgs = {
  input: CandidateMarkNotInterestedMutationInput;
};


export type MutationMarkListingNotificationsReadArgs = {
  input: MarkListingNotificationsReadInput;
};


export type MutationMarkMessageReadArgs = {
  input: MarkMessageReadInput;
};


export type MutationMarkNotInterestedArgs = {
  input: ListingMarkNotInterestedInput;
};


export type MutationMarkUserListingInterestOfferReadArgs = {
  input: UserListingInterestOfferMarkReadInput;
};


export type MutationMarkUserListingInterestReadArgs = {
  input: UserListingInterestMarkReadInput;
};


export type MutationOptInGptDescriptionArgs = {
  input: OptInGptDescriptionMutationInput;
};


export type MutationPerformCheckArgs = {
  input: PerformCheckInput;
};


export type MutationPlusSendHiringRequirementsArgs = {
  input: PlusSendHiringRequirementsInput;
};


export type MutationPurchaseProductSubscriptionArgs = {
  input: PurchaseProductSubscriptionMutationInput;
};


export type MutationPurchaseSubscriptionArgs = {
  input: PurchaseSubscriptionInput;
};


export type MutationRejectCandidateInvitationArgs = {
  input: CandidateRejectInvitationMutationInput;
};


export type MutationRemoveCandidateHiringStageArgs = {
  input: RemoveCandidateHiringStageMutationInput;
};


export type MutationRequestBackgroundCheckArgs = {
  input: RequestBackgroundCheckInput;
};


export type MutationRequestExternalBackgroundCheckArgs = {
  input: RequestExternalBackgroundCheckInput;
};


export type MutationRequestInterviewArgs = {
  input: RequestInterviewInput;
};


export type MutationRequestReferenceContactArgs = {
  input: RequestReferenceContactInput;
};


export type MutationRequestSelfBackgroundCheckArgs = {
  input: RequestSelfBackgroundCheckInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationResetPasswordByPhoneNumberArgs = {
  input: ResetPasswordByPhoneNumberInput;
};


export type MutationRestoreArgs = {
  input: UserListingInterestRestoreInput;
};


export type MutationSelfBackgroundCheckCreatePaymentIntentArgs = {
  input: SelfBackgroundCheckCreatePaymentIntentInput;
};


export type MutationSendEmailVerificationCodeArgs = {
  input: SendEmailVerificationCodeInput;
};


export type MutationSendMessageArgs = {
  input: SendMessageInput;
};


export type MutationSendPhoneNumberVerificationCodeArgs = {
  input: SendPhoneNumberVerificationCodeInput;
};


export type MutationSignupEmployeeOnboardingArgs = {
  input: SignupEmployeeOnboardingUserInput;
};


export type MutationSignupEmployerOnboardingArgs = {
  input: SignupEmployerOnboardingUserInput;
};


export type MutationSignupFamilyArgs = {
  input: UserSignupFamilyInput;
};


export type MutationSignupFamilyOnboardingArgs = {
  input: SignupFamilyOnboardingUserInput;
};


export type MutationSignupNannyArgs = {
  input: UserSignupNannyInput;
};


export type MutationSignupNannyOnboardingArgs = {
  input: SignupNannyOnboardingUserInput;
};


export type MutationSignupShareNannyArgs = {
  input: UserSignupShareNannyInput;
};


export type MutationSplitFamilyPairListingArgs = {
  input: SplitFamilyPairListingInput;
};


export type MutationStandaloneConfirmPurchaseArgs = {
  input: StandaloneConfirmPurchaseInput;
};


export type MutationStandalonePurchaseArgs = {
  input: StandalonePurchaseInput;
};


export type MutationSubscribeToJourneyNewsletterArgs = {
  input: SubscribeToJourneyNewsletterInput;
};


export type MutationSubscriptionRequestArgs = {
  input: SubscriptionRequestBackgroundCheckInput;
};


export type MutationUpdateFamilyArgs = {
  input: UpdateFamilyInput;
};


export type MutationUpdateFamilyListingArgs = {
  input: UpdateFamilyListingInput;
};


export type MutationUpdateFamilyPairListingArgs = {
  input: UpdateFamilyPairListingInput;
};


export type MutationUpdateNannyArgs = {
  input: UpdateNannyInput;
};


export type MutationUpdateNannyListingArgs = {
  input: UpdateNannyListingInput;
};


export type MutationUpdateNotificationSubscriptionsArgs = {
  input: UpdateNotificationSubscriptionsMutationInput;
};


export type MutationUpdatePrimaryAvatarArgs = {
  input: AvatarUpdatePrimaryInput;
};


export type MutationUpdateShareListingArgs = {
  input: UpdateShareListingInput;
};


export type MutationUpdateShareNannyArgs = {
  input: UpdateShareNannyInput;
};


export type MutationUpdateTimeZoneArgs = {
  input: UpdateTimeZoneInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};


export type MutationVerifyPhoneNumberArgs = {
  input: VerifyPhoneNumberInput;
};


export type MutationVerifyProductPromoArgs = {
  input: VerifyProductPromoMutationInput;
};


export type MutationVerifyPromoArgs = {
  input: VerifyPromoMutationInput;
};

export type MutationWithErrorsResult = {
  errors: Maybe<Errors>;
};

export type N4hNannyListing = Listing & Node & {
  __typename: 'N4hNannyListing';
  activeInterviews: Array<Interview>;
  activitiesDescription: Maybe<Scalars['String']['output']>;
  canAccess: Scalars['Boolean']['output'];
  canMessage: Scalars['Boolean']['output'];
  candidateInvitations: Array<Candidate>;
  cpr: Maybe<Scalars['Boolean']['output']>;
  createdAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  directoryKeyword: Maybe<DirectoryKeyword>;
  displayName: Maybe<Scalars['String']['output']>;
  driversLicense: Maybe<Scalars['Boolean']['output']>;
  experience: Maybe<Scalars['Float']['output']>;
  experienceChildren: Maybe<Array<ExperienceChildren>>;
  experienceDescription: Maybe<Scalars['String']['output']>;
  firstAid: Maybe<Scalars['Boolean']['output']>;
  fluentEnglish: Maybe<Scalars['Boolean']['output']>;
  fluentSpanish: Maybe<Scalars['Boolean']['output']>;
  fullTime: Maybe<Scalars['Boolean']['output']>;
  gradeschoolerCare: Maybe<Scalars['Boolean']['output']>;
  hasUnreadNotifications: Scalars['Boolean']['output'];
  hourRanges: Maybe<HourRangeConnection>;
  hourlyRate: Maybe<Scalars['Int']['output']>;
  housekeeping: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  importanceDescription: Maybe<Scalars['String']['output']>;
  incompatibilityError: Maybe<Scalars['String']['output']>;
  infantCare: Maybe<Scalars['Boolean']['output']>;
  interestedInPayroll: Maybe<Scalars['Boolean']['output']>;
  isCandidate: Scalars['Boolean']['output'];
  /** @deprecated Use `user.is_complete` instead */
  isComplete: Scalars['Boolean']['output'];
  isEarlyAccessible: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  isOwner: Scalars['Boolean']['output'];
  liveIn: Maybe<Scalars['Boolean']['output']>;
  mealPreparation: Maybe<Scalars['Boolean']['output']>;
  messages: Maybe<MessageConnection>;
  /** @deprecated No longer used. */
  messagesWithCurrentUser: Maybe<Array<Message>>;
  nanny: Maybe<Nanny>;
  nannyMomentDescription: Maybe<Scalars['String']['output']>;
  nearbyCities: Maybe<CityConnection>;
  occasionalWork: Maybe<Scalars['Boolean']['output']>;
  partTime: Maybe<Scalars['Boolean']['output']>;
  pastChildCareInfo: Maybe<Scalars['String']['output']>;
  positionDuration: Maybe<PositionDuration>;
  preschoolerCare: Maybe<Scalars['Boolean']['output']>;
  profile: Profile;
  redactedActivitiesDescription: Maybe<RedactedText>;
  redactedDescription: Maybe<RedactedText>;
  redactedExperienceDescription: Maybe<RedactedText>;
  redactedImportanceDescription: Maybe<RedactedText>;
  redactedNannyMomentDescription: Maybe<RedactedText>;
  redactedSpecialNeedsExperienceDescription: Maybe<RedactedText>;
  relatedListings: Array<Listing>;
  relevantKeywords: Maybe<DirectoryKeywordConnection>;
  shareableToken: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  specialNeeds: Maybe<Scalars['Boolean']['output']>;
  specialNeedsExperienceDescription: Maybe<Scalars['String']['output']>;
  startDate: Maybe<Scalars['ISO8601Date']['output']>;
  toddlerCare: Maybe<Scalars['Boolean']['output']>;
  transportation: Maybe<Scalars['Boolean']['output']>;
  type: ListingType;
  updatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  userListingInterest: Maybe<UserListingInterest>;
  whyNannyInfo: Maybe<Scalars['String']['output']>;
};


export type N4hNannyListingHourRangesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type N4hNannyListingMessagesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type N4hNannyListingMessagesWithCurrentUserArgs = {
  last: InputMaybe<Scalars['Int']['input']>;
};


export type N4hNannyListingNearbyCitiesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type N4hNannyListingRelatedListingsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
};


export type N4hNannyListingRelevantKeywordsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type Nanny = Node & Profile & {
  __typename: 'Nanny';
  address: Maybe<Address>;
  avatar: Avatar;
  canRefer: Scalars['Boolean']['output'];
  displayName: Maybe<Scalars['String']['output']>;
  documentOwnerCredentials: Maybe<UserDocumentCredentials>;
  documents: Maybe<Array<UserDocument>>;
  employee: Maybe<PayrollEmployee>;
  firstName: Maybe<Scalars['String']['output']>;
  fullName: Maybe<Scalars['String']['output']>;
  gender: Maybe<Gender>;
  id: Scalars['ID']['output'];
  /** @deprecated Use `user.is_complete` instead */
  isComplete: Scalars['Boolean']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  listing: Maybe<Listing>;
  payroll: Maybe<PayrollProfile>;
  referenceToken: Maybe<Scalars['String']['output']>;
  references: Maybe<Array<Reference>>;
  truncatedDisplayName: Maybe<Scalars['String']['output']>;
  type: ProfileType;
  user: Maybe<User>;
};


export type NannyCanReferArgs = {
  referenceToken: Scalars['String']['input'];
};


export type NannyDocumentOwnerCredentialsArgs = {
  expireInSeconds?: InputMaybe<Scalars['Int']['input']>;
};

export type NannyContract = Node & {
  __typename: 'NannyContract';
  address: ContractAddress;
  allowance: Maybe<ContractAllowance>;
  children: Array<ContractChild>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customClauses: Array<ContractCustomClause>;
  emergencyContact: Maybe<ContractEmergencyContact>;
  holidays: Maybe<ContractHolidays>;
  id: Scalars['ID']['output'];
  nanny: ContractNanny;
  parents: Array<ContractParent>;
  payment: ContractPayment;
  responsibilities: Maybe<Scalars['String']['output']>;
  schedules: Array<ContractSchedule>;
  sickLeave: Maybe<ContractSickLeave>;
  startDate: Scalars['ISO8601Date']['output'];
  termination: Maybe<ContractTermination>;
  user: User;
  vacation: Maybe<ContractVacation>;
};

export type NannyContractInput = {
  address: ContractAddressInput;
  allowance?: InputMaybe<ContractAllowanceInput>;
  children: Array<ContractChildInput>;
  customClauses?: InputMaybe<Array<ContractCustomClauseInput>>;
  emergencyContact?: InputMaybe<ContractEmergencyContactInput>;
  holidays?: InputMaybe<ContractHolidaysInput>;
  nanny: ContractPartyInput;
  parents: Array<ContractPartyInput>;
  payment: ContractPaymentInput;
  responsibilities?: InputMaybe<Scalars['String']['input']>;
  schedules?: InputMaybe<Array<ScheduleInput>>;
  sickLeave?: InputMaybe<ContractSickLeaveInput>;
  startDate: Scalars['ISO8601Date']['input'];
  termination?: InputMaybe<ContractTerminationInput>;
  vacation?: InputMaybe<ContractVacationInput>;
};

export type NannyInput = {
  address?: InputMaybe<AddressInput>;
  avatar?: InputMaybe<AvatarInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type NannyListing = Listing & Node & {
  __typename: 'NannyListing';
  activeInterviews: Array<Interview>;
  activitiesDescription: Maybe<Scalars['String']['output']>;
  canAccess: Scalars['Boolean']['output'];
  canMessage: Scalars['Boolean']['output'];
  candidateInvitations: Array<Candidate>;
  cpr: Maybe<Scalars['Boolean']['output']>;
  createdAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  directoryKeyword: Maybe<DirectoryKeyword>;
  displayName: Maybe<Scalars['String']['output']>;
  driversLicense: Maybe<Scalars['Boolean']['output']>;
  experience: Maybe<Scalars['Float']['output']>;
  experienceChildren: Maybe<Array<ExperienceChildren>>;
  experienceDescription: Maybe<Scalars['String']['output']>;
  firstAid: Maybe<Scalars['Boolean']['output']>;
  fluentEnglish: Maybe<Scalars['Boolean']['output']>;
  fluentSpanish: Maybe<Scalars['Boolean']['output']>;
  fullTime: Maybe<Scalars['Boolean']['output']>;
  gradeschoolerCare: Maybe<Scalars['Boolean']['output']>;
  hasUnreadNotifications: Scalars['Boolean']['output'];
  hourRanges: Maybe<HourRangeConnection>;
  hourlyRate: Maybe<Scalars['Int']['output']>;
  housekeeping: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  importanceDescription: Maybe<Scalars['String']['output']>;
  incompatibilityError: Maybe<Scalars['String']['output']>;
  infantCare: Maybe<Scalars['Boolean']['output']>;
  interestedInPayroll: Maybe<Scalars['Boolean']['output']>;
  isCandidate: Scalars['Boolean']['output'];
  /** @deprecated Use `user.is_complete` instead */
  isComplete: Scalars['Boolean']['output'];
  isEarlyAccessible: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  isOwner: Scalars['Boolean']['output'];
  liveIn: Maybe<Scalars['Boolean']['output']>;
  mealPreparation: Maybe<Scalars['Boolean']['output']>;
  messages: Maybe<MessageConnection>;
  /** @deprecated No longer used. */
  messagesWithCurrentUser: Maybe<Array<Message>>;
  nanny: Maybe<Nanny>;
  nannyMomentDescription: Maybe<Scalars['String']['output']>;
  nearbyCities: Maybe<CityConnection>;
  occasionalWork: Maybe<Scalars['Boolean']['output']>;
  partTime: Maybe<Scalars['Boolean']['output']>;
  positionDuration: Maybe<PositionDuration>;
  preschoolerCare: Maybe<Scalars['Boolean']['output']>;
  profile: Profile;
  redactedActivitiesDescription: Maybe<RedactedText>;
  redactedDescription: Maybe<RedactedText>;
  redactedExperienceDescription: Maybe<RedactedText>;
  redactedImportanceDescription: Maybe<RedactedText>;
  redactedNannyMomentDescription: Maybe<RedactedText>;
  redactedSpecialNeedsExperienceDescription: Maybe<RedactedText>;
  relatedListings: Array<Listing>;
  relevantKeywords: Maybe<DirectoryKeywordConnection>;
  shareableToken: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  specialNeeds: Maybe<Scalars['Boolean']['output']>;
  specialNeedsExperienceDescription: Maybe<Scalars['String']['output']>;
  startDate: Maybe<Scalars['ISO8601Date']['output']>;
  toddlerCare: Maybe<Scalars['Boolean']['output']>;
  transportation: Maybe<Scalars['Boolean']['output']>;
  type: ListingType;
  updatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  userListingInterest: Maybe<UserListingInterest>;
};


export type NannyListingHourRangesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type NannyListingMessagesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type NannyListingMessagesWithCurrentUserArgs = {
  last: InputMaybe<Scalars['Int']['input']>;
};


export type NannyListingNearbyCitiesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type NannyListingRelatedListingsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
};


export type NannyListingRelevantKeywordsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type NannyListingCreateInput = {
  experience: Scalars['Float']['input'];
  experienceChildren: Array<ExperienceChildInput>;
};

export type NannyListingUpdateInput = {
  activitiesDescription?: InputMaybe<Scalars['String']['input']>;
  cpr?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  driversLicense?: InputMaybe<Scalars['Boolean']['input']>;
  experience?: InputMaybe<Scalars['Float']['input']>;
  experienceChildren?: InputMaybe<Array<ExperienceChildInput>>;
  experienceDescription?: InputMaybe<Scalars['String']['input']>;
  firstAid?: InputMaybe<Scalars['Boolean']['input']>;
  fluentEnglish?: InputMaybe<Scalars['Boolean']['input']>;
  fluentSpanish?: InputMaybe<Scalars['Boolean']['input']>;
  fullTime?: InputMaybe<Scalars['Boolean']['input']>;
  gradeschoolerCare?: InputMaybe<Scalars['Boolean']['input']>;
  hourlyRate?: InputMaybe<Scalars['Int']['input']>;
  housekeeping?: InputMaybe<Scalars['Boolean']['input']>;
  importanceDescription?: InputMaybe<Scalars['String']['input']>;
  infantCare?: InputMaybe<Scalars['Boolean']['input']>;
  interestedInPayroll?: InputMaybe<Scalars['Boolean']['input']>;
  liveIn?: InputMaybe<Scalars['Boolean']['input']>;
  mealPreparation?: InputMaybe<Scalars['Boolean']['input']>;
  nannyMomentDescription?: InputMaybe<Scalars['String']['input']>;
  occasionalWork?: InputMaybe<Scalars['Boolean']['input']>;
  partTime?: InputMaybe<Scalars['Boolean']['input']>;
  positionDuration?: InputMaybe<PositionDuration>;
  preschoolerCare?: InputMaybe<Scalars['Boolean']['input']>;
  schedules?: InputMaybe<Array<ScheduleInput>>;
  specialNeeds?: InputMaybe<Scalars['Boolean']['input']>;
  specialNeedsExperienceDescription?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  toddlerCare?: InputMaybe<Scalars['Boolean']['input']>;
  transportation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Neighborhood = Node & {
  __typename: 'Neighborhood';
  city: City;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

/** The connection type for Neighborhood. */
export type NeighborhoodConnection = {
  __typename: 'NeighborhoodConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<NeighborhoodEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Neighborhood>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NeighborhoodEdge = {
  __typename: 'NeighborhoodEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Neighborhood>;
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID']['output'];
};

export type NotificationSubscriptionInput = {
  medium: SubscriptionMedium;
  subscribe?: InputMaybe<Scalars['Boolean']['input']>;
  type: Scalars['String']['input'];
};

export type Offer = Node & {
  __typename: 'Offer';
  acceptMessage: Maybe<Scalars['String']['output']>;
  canAccept: Scalars['Boolean']['output'];
  canDecline: Scalars['Boolean']['output'];
  city: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601Date']['output'];
  createdBy: User;
  declineReason: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  employmentStartDate: Maybe<Scalars['ISO8601Date']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  hourlyRate: Scalars['Int']['output'];
  hoursPerWeek: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isAccepted: Scalars['Boolean']['output'];
  isDeclined: Scalars['Boolean']['output'];
  isOwner: Scalars['Boolean']['output'];
  isRecipient: Scalars['Boolean']['output'];
  isShareInvite: Scalars['Boolean']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  offeredTo: User;
  other: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  postalCode: Maybe<Scalars['String']['output']>;
  state: Maybe<Scalars['String']['output']>;
  street: Maybe<Scalars['String']['output']>;
  userListingInterestOffer: Maybe<UserListingInterestOffer>;
};

export type OfferInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employmentStartDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hourlyRate: Scalars['Int']['input'];
  hoursPerWeek: Scalars['Int']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  other?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type OnboardingEmployeeInput = {
  address?: InputMaybe<OnboardingPayrollAddressInput>;
  bank?: InputMaybe<OnboardingPayrollBankInput>;
  birthdate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
};

export type OnboardingEmployerInput = {
  address?: InputMaybe<OnboardingPayrollAddressInput>;
  bank?: InputMaybe<OnboardingPayrollBankInput>;
  birthdate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  businessContactEmail?: InputMaybe<Scalars['String']['input']>;
  businessContactFirstName?: InputMaybe<Scalars['String']['input']>;
  businessContactLastName?: InputMaybe<Scalars['String']['input']>;
  businessContactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  businessFederalTaxId?: InputMaybe<Scalars['String']['input']>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  businessStateTaxId?: InputMaybe<Scalars['String']['input']>;
  businessStateUnemploymentTaxId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hourlyRate?: InputMaybe<Scalars['Int']['input']>;
  hoursPerWeek?: InputMaybe<Scalars['Float']['input']>;
  isNannyShare?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  nannyShareHourSubmissionType?: InputMaybe<PayrollNannyShareHourSubmission>;
  nannyShareOtherFamilyEmail?: InputMaybe<Scalars['String']['input']>;
  nannyShareOtherFamilyLastName?: InputMaybe<Scalars['String']['input']>;
  payCycle?: InputMaybe<PayrollPayCycle>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  secondaryHourlyRate?: InputMaybe<Scalars['Int']['input']>;
  secondaryHoursPerWeek?: InputMaybe<Scalars['Float']['input']>;
  spouseFirstName?: InputMaybe<Scalars['String']['input']>;
  spouseLastName?: InputMaybe<Scalars['String']['input']>;
  spouseMiddleName?: InputMaybe<Scalars['String']['input']>;
  spouseSsn?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

export type OnboardingFamilyInput = {
  address?: InputMaybe<AddressInput>;
  children?: InputMaybe<Array<InputMaybe<ChildInput>>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type OnboardingFamilyListingInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fullTime?: InputMaybe<Scalars['Boolean']['input']>;
  hourlyRate?: InputMaybe<Scalars['Int']['input']>;
  hoursPerWeek?: InputMaybe<Scalars['Int']['input']>;
  interestedInShare?: InputMaybe<Scalars['Boolean']['input']>;
  occasionalWork?: InputMaybe<Scalars['Boolean']['input']>;
  partTime?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OnboardingNannyInput = {
  address?: InputMaybe<AddressInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type OnboardingNannyListingInput = {
  cpr?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  driversLicense?: InputMaybe<Scalars['Boolean']['input']>;
  experience?: InputMaybe<Scalars['Float']['input']>;
  experienceChildren?: InputMaybe<Array<ExperienceChildInput>>;
  firstAid?: InputMaybe<Scalars['Boolean']['input']>;
  fluentEnglish?: InputMaybe<Scalars['Boolean']['input']>;
  fluentSpanish?: InputMaybe<Scalars['Boolean']['input']>;
  fullTime?: InputMaybe<Scalars['Boolean']['input']>;
  gradeschoolerCare?: InputMaybe<Scalars['Boolean']['input']>;
  hourlyRate?: InputMaybe<Scalars['Int']['input']>;
  housekeeping?: InputMaybe<Scalars['Boolean']['input']>;
  infantCare?: InputMaybe<Scalars['Boolean']['input']>;
  liveIn?: InputMaybe<Scalars['Boolean']['input']>;
  mealPreparation?: InputMaybe<Scalars['Boolean']['input']>;
  occasionalWork?: InputMaybe<Scalars['Boolean']['input']>;
  partTime?: InputMaybe<Scalars['Boolean']['input']>;
  preschoolerCare?: InputMaybe<Scalars['Boolean']['input']>;
  specialNeeds?: InputMaybe<Scalars['Boolean']['input']>;
  toddlerCare?: InputMaybe<Scalars['Boolean']['input']>;
  transportation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OnboardingPayrollAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  countrySlug?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  stateSlug?: InputMaybe<Scalars['String']['input']>;
  street1?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
};

export type OnboardingPayrollBankInput = {
  accountHolderName?: InputMaybe<Scalars['String']['input']>;
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  accountType?: InputMaybe<PayrollBankAccountType>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  routingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type OnboardingPayrollProfileInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type OnboardingPayrollUserInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type OnboardingUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  googleAccessToken?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  smsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of OptInGptDescriptionMutation */
export type OptInGptDescriptionMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  gptDescriptionEnableToken: Scalars['String']['input'];
};

/** Autogenerated return type of OptInGptDescriptionMutation */
export type OptInGptDescriptionMutationPayload = MutationWithErrorsResult & {
  __typename: 'OptInGptDescriptionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  listing: Maybe<FamilyListing>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Paid tier cohort */
export enum PaidTierCohort {
  Control = 'CONTROL',
  IntroduceEssential = 'INTRODUCE_ESSENTIAL',
  PaidRequired = 'PAID_REQUIRED'
}

export type PayrollAddress = {
  __typename: 'PayrollAddress';
  city: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  postalCode: Maybe<Scalars['String']['output']>;
  stateName: Maybe<Scalars['String']['output']>;
  stateSlug: Maybe<Scalars['String']['output']>;
  street1: Maybe<Scalars['String']['output']>;
  street2: Maybe<Scalars['String']['output']>;
};

export type PayrollAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  countrySlug?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  stateName?: InputMaybe<Scalars['String']['input']>;
  stateSlug?: InputMaybe<Scalars['String']['input']>;
  street1?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
};

export type PayrollBank = {
  __typename: 'PayrollBank';
  accountHolderName: Maybe<Scalars['String']['output']>;
  accountNumber: Maybe<Scalars['String']['output']>;
  accountType: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  routingNumber: Maybe<Scalars['String']['output']>;
};

/** Account types for payroll bank accounts */
export enum PayrollBankAccountType {
  /** checking */
  Checking = 'CHECKING',
  /** savings */
  Savings = 'SAVINGS'
}

export type PayrollBankInput = {
  accountHolderName?: InputMaybe<Scalars['String']['input']>;
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  accountType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  routingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type PayrollClientRegistration = PayrollRegistration & {
  __typename: 'PayrollClientRegistration';
  address: Maybe<PayrollAddress>;
  bank: Maybe<PayrollBank>;
  birthdate: Maybe<Scalars['ISO8601Date']['output']>;
  businessContactEmail: Maybe<Scalars['String']['output']>;
  businessContactFirstName: Maybe<Scalars['String']['output']>;
  businessContactLastName: Maybe<Scalars['String']['output']>;
  businessContactPhoneNumber: Maybe<Scalars['String']['output']>;
  businessFederalTaxId: Maybe<Scalars['String']['output']>;
  businessName: Maybe<Scalars['String']['output']>;
  businessStateTaxId: Maybe<Scalars['String']['output']>;
  businessStateUnemploymentTaxId: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  hourlyRate: Maybe<Scalars['Int']['output']>;
  hoursPerWeek: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['ID']['output'];
  invitedEmployeeEmail: Maybe<Scalars['String']['output']>;
  invitedEmployeeName: Maybe<Scalars['String']['output']>;
  isComplete: Maybe<Scalars['Boolean']['output']>;
  isNannyShare: Maybe<Scalars['Boolean']['output']>;
  isOtherFamilyOnPlatform: Maybe<Scalars['Boolean']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  linkedRegistration: Maybe<PayrollLinkedRegistration>;
  middleName: Maybe<Scalars['String']['output']>;
  nannyShareHourSubmissionType: Maybe<Scalars['String']['output']>;
  otherFamilyLastName: Maybe<Scalars['String']['output']>;
  payCycle: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  secondaryHourlyRate: Maybe<Scalars['Int']['output']>;
  secondaryHoursPerWeek: Maybe<Scalars['Decimal']['output']>;
  shareableToken: Scalars['String']['output'];
  spouseFirstName: Maybe<Scalars['String']['output']>;
  spouseLastName: Maybe<Scalars['String']['output']>;
  spouseMiddleName: Maybe<Scalars['String']['output']>;
  spouseSsn: Maybe<Scalars['String']['output']>;
  ssn: Maybe<Scalars['String']['output']>;
  startDate: Maybe<Scalars['ISO8601Date']['output']>;
  user: Maybe<User>;
};

export type PayrollClientRegistrationInput = {
  address?: InputMaybe<PayrollAddressInput>;
  bank?: InputMaybe<PayrollBankInput>;
  birthdate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  businessContactEmail?: InputMaybe<Scalars['String']['input']>;
  businessContactFirstName?: InputMaybe<Scalars['String']['input']>;
  businessContactLastName?: InputMaybe<Scalars['String']['input']>;
  businessContactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  businessFederalTaxId?: InputMaybe<Scalars['String']['input']>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  businessStateTaxId?: InputMaybe<Scalars['String']['input']>;
  businessStateUnemploymentTaxId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hourlyRate?: InputMaybe<Scalars['Int']['input']>;
  hoursPerWeek?: InputMaybe<Scalars['Decimal']['input']>;
  invitedEmployeeEmail?: InputMaybe<Scalars['String']['input']>;
  invitedEmployeeName?: InputMaybe<Scalars['String']['input']>;
  isNannyShare?: InputMaybe<Scalars['Boolean']['input']>;
  isOtherFamilyOnPlatform?: InputMaybe<Scalars['Boolean']['input']>;
  joinWithUserToken?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  nannyShareHourSubmissionType?: InputMaybe<Scalars['String']['input']>;
  otherFamilyLastName?: InputMaybe<Scalars['String']['input']>;
  payCycle?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  secondaryHourlyRate?: InputMaybe<Scalars['Int']['input']>;
  secondaryHoursPerWeek?: InputMaybe<Scalars['Decimal']['input']>;
  spouseFirstName?: InputMaybe<Scalars['String']['input']>;
  spouseLastName?: InputMaybe<Scalars['String']['input']>;
  spouseMiddleName?: InputMaybe<Scalars['String']['input']>;
  spouseSsn?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

export type PayrollDocument = {
  __typename: 'PayrollDocument';
  date: Scalars['ISO8601Date']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  type: PayrollDocumentType;
};

/** The document types Polaris supports */
export enum PayrollDocumentType {
  /** paystub */
  PayStub = 'PAY_STUB',
  /** reports */
  Reports = 'REPORTS',
  /** taxdocument */
  TaxDocuments = 'TAX_DOCUMENTS',
  /** taxforms */
  TaxForms = 'TAX_FORMS'
}

export type PayrollEmployee = Node & PayrollProfile & {
  __typename: 'PayrollEmployee';
  address: Maybe<PayrollProfileAddress>;
  anonymousInviteToken: Maybe<Scalars['String']['output']>;
  bank: Maybe<PayrollProfileBank>;
  birthdate: Maybe<Scalars['ISO8601Date']['output']>;
  documents: Maybe<Array<PayrollDocument>>;
  employers: Maybe<Array<PayrollEmployer>>;
  externalEmployeeId: Maybe<Scalars['ID']['output']>;
  firstName: Scalars['String']['output'];
  hasEmployer: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  invitedEmployerEmail: Maybe<Scalars['String']['output']>;
  isComplete: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  middleName: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  ssn: Maybe<Scalars['String']['output']>;
};


export type PayrollEmployeeDocumentsArgs = {
  eraAccountId: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of PayrollEmployeeDownloadDocument */
export type PayrollEmployeeDownloadDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
  documentType: PayrollDocumentType;
  externalEmployerId: Scalars['ID']['input'];
  payrollEmployeeId: Scalars['ID']['input'];
};

/** Autogenerated return type of PayrollEmployeeDownloadDocument */
export type PayrollEmployeeDownloadDocumentPayload = MutationWithErrorsResult & {
  __typename: 'PayrollEmployeeDownloadDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  document: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

/** Autogenerated input type of PayrollEmployeeInviteEmployer */
export type PayrollEmployeeInviteEmployerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  payrollEmployeeId: Scalars['ID']['input'];
};

/** Autogenerated return type of PayrollEmployeeInviteEmployer */
export type PayrollEmployeeInviteEmployerPayload = MutationWithErrorsResult & {
  __typename: 'PayrollEmployeeInviteEmployerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  viewer: Maybe<Viewer>;
};

export type PayrollEmployeeRegistration = PayrollRegistration & {
  __typename: 'PayrollEmployeeRegistration';
  address: Maybe<PayrollAddress>;
  bank: Maybe<PayrollBank>;
  birthdate: Maybe<Scalars['ISO8601Date']['output']>;
  email: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isComplete: Maybe<Scalars['Boolean']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  linkedRegistration: Maybe<PayrollLinkedRegistration>;
  maritalStatus: Maybe<Scalars['String']['output']>;
  middleName: Maybe<Scalars['String']['output']>;
  movedWithinCalendarYear: Maybe<Scalars['Boolean']['output']>;
  onboardingIntent: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  shareableToken: Scalars['String']['output'];
  ssn: Maybe<Scalars['String']['output']>;
  user: Maybe<User>;
  withholdTaxes: Maybe<Scalars['Boolean']['output']>;
};

export type PayrollEmployeeRegistrationInput = {
  address?: InputMaybe<PayrollAddressInput>;
  bank?: InputMaybe<PayrollBankInput>;
  birthdate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  joinWithUserToken?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  movedWithinCalendarYear?: InputMaybe<Scalars['Boolean']['input']>;
  onboardingIntent?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  withholdTaxes?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PayrollEmployer = Node & PayrollProfile & {
  __typename: 'PayrollEmployer';
  address: Maybe<PayrollProfileAddress>;
  anonymousInviteToken: Maybe<Scalars['String']['output']>;
  bank: Maybe<PayrollProfileBank>;
  birthdate: Maybe<Scalars['ISO8601Date']['output']>;
  businessContactEmail: Maybe<Scalars['String']['output']>;
  businessContactFirstName: Maybe<Scalars['String']['output']>;
  businessContactLastName: Maybe<Scalars['String']['output']>;
  businessContactPhoneNumber: Maybe<Scalars['String']['output']>;
  businessFederalTaxId: Maybe<Scalars['String']['output']>;
  businessName: Maybe<Scalars['String']['output']>;
  businessStateTaxId: Maybe<Scalars['String']['output']>;
  businessStateUnemploymentTaxId: Maybe<Scalars['String']['output']>;
  documents: Maybe<Array<PayrollDocument>>;
  employees: Maybe<Array<PayrollEmployee>>;
  externalEmployerId: Maybe<Scalars['ID']['output']>;
  firstName: Scalars['String']['output'];
  hasEmployee: Scalars['Boolean']['output'];
  hourlyRate: Maybe<Scalars['Int']['output']>;
  hoursPerWeek: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  invitedEmployeeEmail: Maybe<Scalars['String']['output']>;
  isComplete: Scalars['Boolean']['output'];
  isNannyShare: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  middleName: Maybe<Scalars['String']['output']>;
  nannyShareHourSubmissionType: Maybe<PayrollNannyShareHourSubmission>;
  nannyShareOtherFamilyEmail: Maybe<Scalars['String']['output']>;
  nannyShareOtherFamilyLastName: Maybe<Scalars['String']['output']>;
  payCycle: Maybe<PayrollPayCycle>;
  phoneNumber: Scalars['String']['output'];
  secondaryHourlyRate: Maybe<Scalars['Int']['output']>;
  secondaryHoursPerWeek: Maybe<Scalars['Float']['output']>;
  spouseFirstName: Maybe<Scalars['String']['output']>;
  spouseLastName: Maybe<Scalars['String']['output']>;
  spouseMiddleName: Maybe<Scalars['String']['output']>;
  spouseSsn: Maybe<Scalars['String']['output']>;
  ssn: Maybe<Scalars['String']['output']>;
  startDate: Maybe<Scalars['ISO8601Date']['output']>;
};


export type PayrollEmployerDocumentsArgs = {
  endDate: InputMaybe<Scalars['ISO8601Date']['input']>;
  startDate: InputMaybe<Scalars['ISO8601Date']['input']>;
};

/** Autogenerated input type of PayrollEmployerDownloadDocument */
export type PayrollEmployerDownloadDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
  payrollEmployerId: Scalars['ID']['input'];
};

/** Autogenerated return type of PayrollEmployerDownloadDocument */
export type PayrollEmployerDownloadDocumentPayload = MutationWithErrorsResult & {
  __typename: 'PayrollEmployerDownloadDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  document: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

/** Autogenerated input type of PayrollEmployerInviteEmployee */
export type PayrollEmployerInviteEmployeeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  payrollEmployerId: Scalars['ID']['input'];
};

/** Autogenerated return type of PayrollEmployerInviteEmployee */
export type PayrollEmployerInviteEmployeePayload = MutationWithErrorsResult & {
  __typename: 'PayrollEmployerInviteEmployeePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  viewer: Maybe<Viewer>;
};

export type PayrollLinkedRegistration = {
  __typename: 'PayrollLinkedRegistration';
  id: Scalars['ID']['output'];
  isComplete: Scalars['Boolean']['output'];
};

/** The type of nanny share hour submission */
export enum PayrollNannyShareHourSubmission {
  /** combined */
  Combined = 'COMBINED',
  /** individual */
  Individual = 'INDIVIDUAL'
}

/** The pay cycle for a payroll employer */
export enum PayrollPayCycle {
  /** bi-weekly */
  Biweekly = 'BIWEEKLY',
  /** semi-monthly */
  Semimonthly = 'SEMIMONTHLY',
  /** weekly */
  Weekly = 'WEEKLY'
}

export type PayrollProfile = {
  address: Maybe<PayrollProfileAddress>;
  anonymousInviteToken: Maybe<Scalars['String']['output']>;
  bank: Maybe<PayrollProfileBank>;
  birthdate: Maybe<Scalars['ISO8601Date']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isComplete: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  middleName: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  ssn: Maybe<Scalars['String']['output']>;
};

export type PayrollProfileAddress = Node & {
  __typename: 'PayrollProfileAddress';
  city: Scalars['String']['output'];
  countrySlug: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  stateSlug: Scalars['String']['output'];
  street1: Scalars['String']['output'];
  street2: Maybe<Scalars['String']['output']>;
};

export type PayrollProfileBank = Node & {
  __typename: 'PayrollProfileBank';
  accountHolderName: Scalars['String']['output'];
  accountNumber: Scalars['String']['output'];
  accountType: PayrollBankAccountType;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  routingNumber: Scalars['String']['output'];
};

export type PayrollRegistration = {
  address: Maybe<PayrollAddress>;
  bank: Maybe<PayrollBank>;
  birthdate: Maybe<Scalars['ISO8601Date']['output']>;
  email: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isComplete: Maybe<Scalars['Boolean']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  linkedRegistration: Maybe<PayrollLinkedRegistration>;
  middleName: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  shareableToken: Scalars['String']['output'];
  ssn: Maybe<Scalars['String']['output']>;
  user: Maybe<User>;
};

/** Autogenerated input type of PerformCheck */
export type PerformCheckInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
};

/** Autogenerated return type of PerformCheck */
export type PerformCheckPayload = MutationWithErrorsResult & {
  __typename: 'PerformCheckPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  status: Status;
};

/** Autogenerated input type of PlusSendHiringRequirements */
export type PlusSendHiringRequirementsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  otherRequirements?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PlusSendHiringRequirements */
export type PlusSendHiringRequirementsPayload = MutationWithErrorsResult & {
  __typename: 'PlusSendHiringRequirementsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  subscription: Maybe<UserSubscription>;
};

export enum PositionDuration {
  LongTerm = 'LONG_TERM',
  Seasonal = 'SEASONAL',
  ShortTerm = 'SHORT_TERM'
}

export type Product = Node & {
  __typename: 'Product';
  /** @deprecated This is no longer used. */
  backgroundCheckType: BackgroundCheckType;
  category: ProductCategory;
  currency: Scalars['String']['output'];
  features: Array<Feature>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  stripeProductId: Scalars['String']['output'];
  variant: ProductVariant;
};

/** Supported product categeory types */
export enum ProductCategory {
  /** Background check */
  BackgroundCheck = 'BackgroundCheck',
  /** Subscription */
  Subscription = 'Subscription'
}

/** Supported product variant types */
export enum ProductVariant {
  /** Enhanced */
  Enhanced = 'Enhanced',
  /** Essential */
  Essential = 'Essential',
  /** Plus */
  Plus = 'Plus',
  /** Self basic */
  SelfBasic = 'SelfBasic'
}

export type Profile = {
  avatar: Avatar;
  canRefer: Scalars['Boolean']['output'];
  displayName: Maybe<Scalars['String']['output']>;
  documentOwnerCredentials: Maybe<UserDocumentCredentials>;
  documents: Maybe<Array<UserDocument>>;
  firstName: Maybe<Scalars['String']['output']>;
  fullName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use `user.is_complete` instead */
  isComplete: Scalars['Boolean']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  listing: Maybe<Listing>;
  payroll: Maybe<PayrollProfile>;
  referenceToken: Maybe<Scalars['String']['output']>;
  references: Maybe<Array<Reference>>;
  type: ProfileType;
  user: Maybe<User>;
};


export type ProfileCanReferArgs = {
  referenceToken: Scalars['String']['input'];
};


export type ProfileDocumentOwnerCredentialsArgs = {
  expireInSeconds?: InputMaybe<Scalars['Int']['input']>;
};

/** Profile Type */
export enum ProfileType {
  /** Family */
  Family = 'Family',
  /** Nanny */
  Nanny = 'Nanny',
  /** ShareNanny */
  ShareNanny = 'ShareNanny'
}

export type Promo = {
  __typename: 'Promo';
  duration: Scalars['String']['output'];
  id: Scalars['String']['output'];
  percentOff: Scalars['Int']['output'];
  promoCode: Scalars['String']['output'];
};

/** Autogenerated input type of PurchaseProductSubscriptionMutation */
export type PurchaseProductSubscriptionMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
  promoCode?: InputMaybe<Scalars['String']['input']>;
  stripePaymentMethodId: Scalars['String']['input'];
};

/** Autogenerated return type of PurchaseProductSubscriptionMutation */
export type PurchaseProductSubscriptionMutationPayload = MutationWithErrorsResult & {
  __typename: 'PurchaseProductSubscriptionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  product: Maybe<Product>;
  requiresConfirmation: Maybe<Scalars['Boolean']['output']>;
  subscription: Maybe<StripeSubscription>;
  user: Maybe<User>;
};

/** Autogenerated input type of PurchaseSubscription */
export type PurchaseSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  stripePaymentMethodId: Scalars['String']['input'];
  stripePriceId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PurchaseSubscription */
export type PurchaseSubscriptionPayload = MutationWithErrorsResult & {
  __typename: 'PurchaseSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  requiresConfirmation: Maybe<Scalars['Boolean']['output']>;
  subscription: Maybe<StripeSubscription>;
  user: Maybe<User>;
};

export type Query = {
  __typename: 'Query';
  _entities: Array<Maybe<_Entity>>;
  _service: _Service;
  allJourneys: Array<Journey>;
  article: Maybe<Article>;
  articleBySlug: Maybe<Article>;
  backgroundCheck: Maybe<BackgroundCheck>;
  backgroundCheckByToken: Maybe<BackgroundCheck>;
  candidate: Maybe<Candidate>;
  candidateInvitation: Maybe<CandidateInvitation>;
  country: Maybe<Country>;
  daycareBySlug: Maybe<Daycare>;
  daycareDirectoryKeyword: Maybe<DaycareDirectoryKeyword>;
  directoryKeyword: Maybe<DirectoryKeyword>;
  directoryKeywords: Maybe<DirectoryKeywordConnection>;
  featureFlags: Array<FeatureFlag>;
  interview: Maybe<Interview>;
  journeyBySlug: Maybe<Journey>;
  journeyStepBySlug: Maybe<JourneyStep>;
  listing: Maybe<Listing>;
  listingById: Maybe<Listing>;
  listings: Maybe<ListingConnection>;
  nannyContractById: Maybe<NannyContract>;
  /** Fetches an object given its ID. */
  node: Maybe<Node>;
  offer: Maybe<Offer>;
  /** @deprecated Use category specific product fields instead. */
  products: Array<Product>;
  reference: Maybe<Reference>;
  referenceContactRequest: Maybe<ReferenceContactRequest>;
  referenceProfile: Maybe<Profile>;
  shareableListing: Maybe<Listing>;
  state: Maybe<State>;
  status: Status;
  subscriptionProducts: Array<Product>;
  user: Maybe<User>;
  userListingInterest: Maybe<UserListingInterest>;
  viewer: Viewer;
};


export type Query_EntitiesArgs = {
  representations: Array<Scalars['_Any']['input']>;
};


export type QueryArticleArgs = {
  slug: Scalars['String']['input'];
  topic: Scalars['String']['input'];
};


export type QueryArticleBySlugArgs = {
  journeyStepSlug: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};


export type QueryBackgroundCheckArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBackgroundCheckByTokenArgs = {
  token: Scalars['String']['input'];
};


export type QueryCandidateArgs = {
  token: Scalars['String']['input'];
};


export type QueryCandidateInvitationArgs = {
  token: Scalars['String']['input'];
};


export type QueryCountryArgs = {
  slug: Scalars['String']['input'];
};


export type QueryDaycareBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryDaycareDirectoryKeywordArgs = {
  slug: Scalars['String']['input'];
};


export type QueryDirectoryKeywordArgs = {
  slug: Scalars['String']['input'];
};


export type QueryDirectoryKeywordsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  audienceType: InputMaybe<ProfileType>;
  before: InputMaybe<Scalars['String']['input']>;
  citySlug: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  onlyCostStatisticsCapable?: InputMaybe<Scalars['Boolean']['input']>;
  parentDirectoryKeywordSlug?: InputMaybe<Scalars['String']['input']>;
  stateSlug: InputMaybe<Scalars['String']['input']>;
};


export type QueryInterviewArgs = {
  id: Scalars['ID']['input'];
};


export type QueryJourneyBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryJourneyStepBySlugArgs = {
  journeySlug: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};


export type QueryListingArgs = {
  includeInaccessible: InputMaybe<Scalars['Boolean']['input']>;
  slug: Scalars['String']['input'];
  type: ListingType;
};


export type QueryListingByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryListingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  sort: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type QueryNannyContractByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOfferArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductsArgs = {
  category: InputMaybe<Scalars['String']['input']>;
  supportedProfiles: InputMaybe<Array<ProfileType>>;
};


export type QueryReferenceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReferenceContactRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReferenceProfileArgs = {
  referenceToken: Scalars['String']['input'];
};


export type QueryShareableListingArgs = {
  listingToken: Scalars['String']['input'];
};


export type QueryStateArgs = {
  slug: Scalars['String']['input'];
};


export type QuerySubscriptionProductsArgs = {
  supportedProfiles: InputMaybe<Array<ProfileType>>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserListingInterestArgs = {
  id: Scalars['ID']['input'];
};

export type RedactedText = {
  __typename: 'RedactedText';
  emailOffsets: Maybe<Array<Maybe<RedactedTextOffset>>>;
  phoneOffsets: Maybe<Array<Maybe<RedactedTextOffset>>>;
  text: Maybe<Scalars['String']['output']>;
};

/** Redacted Text Offset */
export type RedactedTextOffset = {
  __typename: 'RedactedTextOffset';
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

/** A reference made for a profile */
export type Reference = Node & {
  __typename: 'Reference';
  canHide: Scalars['Boolean']['output'];
  canRequestContactInformation: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  endDate: Scalars['ISO8601Date']['output'];
  firstName: Scalars['String']['output'];
  hasPhoneNumber: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  phoneNumber: Maybe<Scalars['String']['output']>;
  profile: Profile;
  startDate: Scalars['ISO8601Date']['output'];
  userReferenceContactRequest: Maybe<ReferenceContactRequest>;
};

export type ReferenceContactRequest = Node & {
  __typename: 'ReferenceContactRequest';
  canAccept: Maybe<Scalars['Boolean']['output']>;
  canDecline: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isAccepted: Scalars['Boolean']['output'];
  isDeclined: Scalars['Boolean']['output'];
  reference: Reference;
  user: User;
};

/** Describes details of a reference made for a profile */
export type ReferenceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  familyId?: InputMaybe<Scalars['ID']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  nannyId?: InputMaybe<Scalars['ID']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  shareNannyId?: InputMaybe<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

/** Autogenerated input type of RemoveCandidateHiringStageMutation */
export type RemoveCandidateHiringStageMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userListingInterestId: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveCandidateHiringStageMutation */
export type RemoveCandidateHiringStageMutationPayload = MutationWithErrorsResult & {
  __typename: 'RemoveCandidateHiringStageMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  userListingInterest: Maybe<UserListingInterest>;
  viewer: Maybe<Viewer>;
};

/** Autogenerated input type of RequestBackgroundCheck */
export type RequestBackgroundCheckInput = {
  backgroundCheckType: Scalars['String']['input'];
  candidateId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  onSubscription: Scalars['Boolean']['input'];
};

/** Autogenerated return type of RequestBackgroundCheck */
export type RequestBackgroundCheckPayload = MutationWithErrorsResult & {
  __typename: 'RequestBackgroundCheckPayload';
  backgroundCheck: Maybe<BackgroundCheck>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

/** Autogenerated input type of RequestExternalBackgroundCheck */
export type RequestExternalBackgroundCheckInput = {
  backgroundCheckType: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  onSubscription: Scalars['Boolean']['input'];
};

/** Autogenerated return type of RequestExternalBackgroundCheck */
export type RequestExternalBackgroundCheckPayload = MutationWithErrorsResult & {
  __typename: 'RequestExternalBackgroundCheckPayload';
  backgroundCheck: Maybe<BackgroundCheck>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

/** Autogenerated input type of RequestInterview */
export type RequestInterviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactMethod: Scalars['String']['input'];
  interviewAppointments: Array<InterviewAppointmentInput>;
  interviewLength: Scalars['Int']['input'];
  interviewType: InterviewType;
  nannyListingSlug: Scalars['String']['input'];
};

/** Autogenerated return type of RequestInterview */
export type RequestInterviewPayload = MutationWithErrorsResult & {
  __typename: 'RequestInterviewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  interview: Maybe<Interview>;
};

/** Autogenerated input type of RequestReferenceContact */
export type RequestReferenceContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  referenceId: Scalars['ID']['input'];
};

/** Autogenerated return type of RequestReferenceContact */
export type RequestReferenceContactPayload = MutationWithErrorsResult & {
  __typename: 'RequestReferenceContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  referenceContactRequest: Maybe<ReferenceContactRequest>;
};

/** Autogenerated input type of RequestSelfBackgroundCheck */
export type RequestSelfBackgroundCheckInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  paymentIntentId: Scalars['String']['input'];
};

/** Autogenerated return type of RequestSelfBackgroundCheck */
export type RequestSelfBackgroundCheckPayload = MutationWithErrorsResult & {
  __typename: 'RequestSelfBackgroundCheckPayload';
  backgroundCheck: Maybe<BackgroundCheck>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

/** Autogenerated input type of ResetPasswordByPhoneNumber */
export type ResetPasswordByPhoneNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
};

/** Autogenerated return type of ResetPasswordByPhoneNumber */
export type ResetPasswordByPhoneNumberPayload = MutationWithErrorsResult & {
  __typename: 'ResetPasswordByPhoneNumberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

/** Autogenerated input type of ResetPassword */
export type ResetPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of ResetPassword */
export type ResetPasswordPayload = MutationWithErrorsResult & {
  __typename: 'ResetPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

export type Schedule = {
  days: Array<DayOfWeek>;
  endTime: Scalars['Int']['output'];
  startTime: Scalars['Int']['output'];
};

export type ScheduleInput = {
  days: Array<DayOfWeek>;
  endTime: Scalars['Int']['input'];
  startTime: Scalars['Int']['input'];
};

/** Autogenerated input type of SelfBackgroundCheckCreatePaymentIntent */
export type SelfBackgroundCheckCreatePaymentIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  paymentMethod: Scalars['String']['input'];
  productId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of SelfBackgroundCheckCreatePaymentIntent */
export type SelfBackgroundCheckCreatePaymentIntentPayload = MutationWithErrorsResult & {
  __typename: 'SelfBackgroundCheckCreatePaymentIntentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  clientSecret: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  paymentIntentId: Maybe<Scalars['String']['output']>;
  paymentIntentStatus: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SendEmailVerificationCode */
export type SendEmailVerificationCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SendEmailVerificationCode */
export type SendEmailVerificationCodePayload = MutationWithErrorsResult & {
  __typename: 'SendEmailVerificationCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

/** Autogenerated input type of SendMessage */
export type SendMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  listingId?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
};

/** Autogenerated return type of SendMessage */
export type SendMessagePayload = MutationWithErrorsResult & {
  __typename: 'SendMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  listing: Maybe<Listing>;
  message: Maybe<Message>;
  userListingInterest: Maybe<UserListingInterest>;
};

/** Autogenerated input type of SendPhoneNumberVerificationCode */
export type SendPhoneNumberVerificationCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
};

/** Autogenerated return type of SendPhoneNumberVerificationCode */
export type SendPhoneNumberVerificationCodePayload = MutationWithErrorsResult & {
  __typename: 'SendPhoneNumberVerificationCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  user: Maybe<User>;
};

export type ShareListing = Listing & Node & {
  __typename: 'ShareListing';
  activeInterviews: Array<Interview>;
  canAccess: Scalars['Boolean']['output'];
  canMessage: Scalars['Boolean']['output'];
  candidateInvitations: Array<Candidate>;
  createdAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  directoryKeyword: Maybe<DirectoryKeyword>;
  displayName: Maybe<Scalars['String']['output']>;
  family: Maybe<Family>;
  fullTime: Maybe<Scalars['Boolean']['output']>;
  hasUnreadNotifications: Scalars['Boolean']['output'];
  hourRanges: Maybe<HourRangeConnection>;
  hourlyRate: Maybe<Scalars['Int']['output']>;
  hourlyShareRate: Maybe<Scalars['Int']['output']>;
  hoursPerWeek: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  incompatibilityError: Maybe<Scalars['String']['output']>;
  interestedInPayroll: Maybe<Scalars['Boolean']['output']>;
  isCandidate: Scalars['Boolean']['output'];
  /** @deprecated Use `user.is_complete` instead */
  isComplete: Scalars['Boolean']['output'];
  isEarlyAccessible: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  isOwner: Scalars['Boolean']['output'];
  liveIn: Maybe<Scalars['Boolean']['output']>;
  messages: Maybe<MessageConnection>;
  /** @deprecated No longer used. */
  messagesWithCurrentUser: Maybe<Array<Message>>;
  nanny: Maybe<Nanny>;
  nearbyCities: Maybe<CityConnection>;
  occasionalWork: Maybe<Scalars['Boolean']['output']>;
  otherProfile: Profile;
  partTime: Maybe<Scalars['Boolean']['output']>;
  positionDuration: Maybe<PositionDuration>;
  profile: Profile;
  redactedDescription: Maybe<RedactedText>;
  relatedListings: Array<Listing>;
  relevantKeywords: Maybe<DirectoryKeywordConnection>;
  shareNanny: Maybe<ShareNanny>;
  shareableToken: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  startDate: Maybe<Scalars['ISO8601Date']['output']>;
  type: ListingType;
  updatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  userListingInterest: Maybe<UserListingInterest>;
};


export type ShareListingHourRangesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type ShareListingMessagesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type ShareListingMessagesWithCurrentUserArgs = {
  last: InputMaybe<Scalars['Int']['input']>;
};


export type ShareListingNearbyCitiesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type ShareListingRelatedListingsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
};


export type ShareListingRelevantKeywordsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type ShareListingInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fullTime?: InputMaybe<Scalars['Boolean']['input']>;
  hourlyRate?: InputMaybe<Scalars['Int']['input']>;
  hourlyShareRate?: InputMaybe<Scalars['Int']['input']>;
  hoursPerWeek?: InputMaybe<Scalars['Int']['input']>;
  interestedInPayroll?: InputMaybe<Scalars['Boolean']['input']>;
  liveIn?: InputMaybe<Scalars['Boolean']['input']>;
  occasionalWork?: InputMaybe<Scalars['Boolean']['input']>;
  partTime?: InputMaybe<Scalars['Boolean']['input']>;
  positionDuration?: InputMaybe<PositionDuration>;
  schedules?: InputMaybe<Array<ScheduleInput>>;
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

export type ShareNanny = Node & Profile & {
  __typename: 'ShareNanny';
  avatar: Avatar;
  canRefer: Scalars['Boolean']['output'];
  dateHired: Maybe<Scalars['ISO8601Date']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  documentOwnerCredentials: Maybe<UserDocumentCredentials>;
  documents: Maybe<Array<UserDocument>>;
  firstName: Maybe<Scalars['String']['output']>;
  fullName: Maybe<Scalars['String']['output']>;
  gender: Maybe<Gender>;
  id: Scalars['ID']['output'];
  /** @deprecated Use `user.is_complete` instead */
  isComplete: Scalars['Boolean']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  listing: Maybe<Listing>;
  payroll: Maybe<PayrollProfile>;
  referenceToken: Maybe<Scalars['String']['output']>;
  references: Maybe<Array<Reference>>;
  truncatedDisplayName: Maybe<Scalars['String']['output']>;
  type: ProfileType;
  user: Maybe<User>;
};


export type ShareNannyCanReferArgs = {
  referenceToken: Scalars['String']['input'];
};


export type ShareNannyDocumentOwnerCredentialsArgs = {
  expireInSeconds?: InputMaybe<Scalars['Int']['input']>;
};

export type ShareNannyInput = {
  dateHired?: InputMaybe<Scalars['ISO8601Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of SignupEmployeeOnboardingUser */
export type SignupEmployeeOnboardingUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employee?: InputMaybe<OnboardingEmployeeInput>;
  inviteEmail?: InputMaybe<Scalars['String']['input']>;
  inviteToken?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<OnboardingPayrollProfileInput>;
  user?: InputMaybe<OnboardingPayrollUserInput>;
};

/** Autogenerated return type of SignupEmployeeOnboardingUser */
export type SignupEmployeeOnboardingUserPayload = MutationWithErrorsResult & {
  __typename: 'SignupEmployeeOnboardingUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  viewer: Maybe<Viewer>;
};

/** Autogenerated input type of SignupEmployerOnboardingUser */
export type SignupEmployerOnboardingUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  employer?: InputMaybe<OnboardingEmployerInput>;
  inviteEmail?: InputMaybe<Scalars['String']['input']>;
  inviteToken?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<OnboardingPayrollProfileInput>;
  user?: InputMaybe<OnboardingPayrollUserInput>;
};

/** Autogenerated return type of SignupEmployerOnboardingUser */
export type SignupEmployerOnboardingUserPayload = MutationWithErrorsResult & {
  __typename: 'SignupEmployerOnboardingUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  viewer: Maybe<Viewer>;
};

/** Autogenerated input type of SignupFamilyOnboardingUser */
export type SignupFamilyOnboardingUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  gptExperimentVariant?: InputMaybe<Scalars['String']['input']>;
  listing?: InputMaybe<OnboardingFamilyListingInput>;
  profile?: InputMaybe<OnboardingFamilyInput>;
  user?: InputMaybe<OnboardingUserInput>;
};

/** Autogenerated return type of SignupFamilyOnboardingUser */
export type SignupFamilyOnboardingUserPayload = MutationWithErrorsResult & {
  __typename: 'SignupFamilyOnboardingUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  viewer: Maybe<Viewer>;
};

/** Autogenerated input type of SignupNannyOnboardingUser */
export type SignupNannyOnboardingUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  listing?: InputMaybe<OnboardingNannyListingInput>;
  profile?: InputMaybe<OnboardingNannyInput>;
  user?: InputMaybe<OnboardingUserInput>;
};

/** Autogenerated return type of SignupNannyOnboardingUser */
export type SignupNannyOnboardingUserPayload = MutationWithErrorsResult & {
  __typename: 'SignupNannyOnboardingUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  viewer: Maybe<Viewer>;
};

export type Sort = {
  direction?: InputMaybe<SortDirection>;
  key: Scalars['String']['input'];
};

/** The direction of a sort */
export enum SortDirection {
  /** Ascending */
  Asc = 'ASC',
  /** Descending */
  Desc = 'DESC'
}

/** Autogenerated input type of SplitFamilyPairListing */
export type SplitFamilyPairListingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  familyPairListingId: Scalars['ID']['input'];
};

/** Autogenerated return type of SplitFamilyPairListing */
export type SplitFamilyPairListingPayload = MutationWithErrorsResult & {
  __typename: 'SplitFamilyPairListingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  familyPairListing: Maybe<FamilyPairListing>;
};

/** Autogenerated input type of StandaloneConfirmPurchase */
export type StandaloneConfirmPurchaseInput = {
  backgroundCheckType: BackgroundCheckType;
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalCandidate?: InputMaybe<BackgroundCheckExternalCandidateInput>;
  paymentIntentId: Scalars['String']['input'];
  productId: Scalars['ID']['input'];
};

/** Autogenerated return type of StandaloneConfirmPurchase */
export type StandaloneConfirmPurchasePayload = MutationWithErrorsResult & {
  __typename: 'StandaloneConfirmPurchasePayload';
  backgroundCheck: Maybe<BackgroundCheck>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

/** Autogenerated input type of StandalonePurchase */
export type StandalonePurchaseInput = {
  backgroundCheckType: BackgroundCheckType;
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalCandidate?: InputMaybe<BackgroundCheckExternalCandidateInput>;
  paymentMethodId: Scalars['String']['input'];
  productId: Scalars['ID']['input'];
};

/** Autogenerated return type of StandalonePurchase */
export type StandalonePurchasePayload = MutationWithErrorsResult & {
  __typename: 'StandalonePurchasePayload';
  backgroundCheck: Maybe<BackgroundCheck>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  clientSecret: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  paymentIntentId: Maybe<Scalars['String']['output']>;
  paymentIntentStatus: Maybe<Scalars['String']['output']>;
};

export type State = Node & {
  __typename: 'State';
  averageCost: Maybe<Scalars['Int']['output']>;
  averageSalary: Maybe<Scalars['Int']['output']>;
  cities: Maybe<CityConnection>;
  city: Maybe<City>;
  country: Country;
  id: Scalars['ID']['output'];
  minimumWage: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};


export type StateCitiesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type StateCityArgs = {
  slug: Scalars['String']['input'];
};

/** The connection type for State. */
export type StateConnection = {
  __typename: 'StateConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<StateEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<State>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type StateEdge = {
  __typename: 'StateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<State>;
};

export type Status = {
  __typename: 'Status';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
};

export type StripeInvoice = {
  __typename: 'StripeInvoice';
  id: Scalars['String']['output'];
  paymentIntent: Maybe<StripePaymentIntent>;
};

export type StripePaymentIntent = {
  __typename: 'StripePaymentIntent';
  clientSecret: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  status: Maybe<Scalars['String']['output']>;
};

export type StripeSubscription = {
  __typename: 'StripeSubscription';
  id: Scalars['String']['output'];
  latestInvoice: Maybe<StripeInvoice>;
};

/** Autogenerated input type of SubscribeToJourneyNewsletter */
export type SubscribeToJourneyNewsletterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  journeyId: Scalars['ID']['input'];
};

/** Autogenerated return type of SubscribeToJourneyNewsletter */
export type SubscribeToJourneyNewsletterPayload = MutationWithErrorsResult & {
  __typename: 'SubscribeToJourneyNewsletterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

export type Subscription = {
  __typename: 'Subscription';
  messageSent: MessageSentSubscriptionPayload;
};


export type SubscriptionMessageSentArgs = {
  listing: Scalars['ID']['input'];
  sender: Scalars['ID']['input'];
};

export type SubscriptionFeedback = Node & {
  __typename: 'SubscriptionFeedback';
  id: Scalars['ID']['output'];
  message: Message;
  rating: SubscriptionFeedbackRating;
  subscription: UserSubscription;
};

/** How the user rates their PLUS subscription service */
export enum SubscriptionFeedbackRating {
  /** Negative */
  Negative = 'NEGATIVE',
  /** Neutral */
  Neutral = 'NEUTRAL',
  /** Positive */
  Positive = 'POSITIVE'
}

export type SubscriptionHiringRequirement = Node & {
  __typename: 'SubscriptionHiringRequirement';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

/** Notification subscription medium */
export enum SubscriptionMedium {
  /** Customer IO */
  Cio = 'cio'
}

/** Autogenerated input type of SubscriptionRequestBackgroundCheck */
export type SubscriptionRequestBackgroundCheckInput = {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalCandidate?: InputMaybe<BackgroundCheckExternalCandidateInput>;
};

/** Autogenerated return type of SubscriptionRequestBackgroundCheck */
export type SubscriptionRequestBackgroundCheckPayload = MutationWithErrorsResult & {
  __typename: 'SubscriptionRequestBackgroundCheckPayload';
  backgroundCheck: Maybe<BackgroundCheck>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
};

export type UnsubscribeField = {
  __typename: 'UnsubscribeField';
  key: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  unsubscribed: Maybe<Scalars['Boolean']['output']>;
};

export type Unsubscribed = {
  __typename: 'Unsubscribed';
  cio: Maybe<Array<Maybe<UnsubscribeField>>>;
};

/** Autogenerated input type of UpdateFamily */
export type UpdateFamilyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  family?: InputMaybe<FamilyInput>;
  familyId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of UpdateFamilyListing */
export type UpdateFamilyListingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  familyListing?: InputMaybe<FamilyListingInput>;
  familyListingId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateFamilyListing */
export type UpdateFamilyListingPayload = MutationWithErrorsResult & {
  __typename: 'UpdateFamilyListingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  familyListing: Maybe<FamilyListing>;
};

/** Autogenerated input type of UpdateFamilyPairListing */
export type UpdateFamilyPairListingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  familyPairListing?: InputMaybe<FamilyPairListingInput>;
  familyPairListingId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateFamilyPairListing */
export type UpdateFamilyPairListingPayload = MutationWithErrorsResult & {
  __typename: 'UpdateFamilyPairListingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  familyPairListing: Maybe<FamilyPairListing>;
};

/** Autogenerated return type of UpdateFamily */
export type UpdateFamilyPayload = MutationWithErrorsResult & {
  __typename: 'UpdateFamilyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  family: Maybe<Family>;
};

/** Autogenerated input type of UpdateNanny */
export type UpdateNannyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nanny?: InputMaybe<NannyInput>;
  nannyId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of UpdateNannyListing */
export type UpdateNannyListingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nannyListing: NannyListingUpdateInput;
  nannyListingId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateNannyListing */
export type UpdateNannyListingPayload = MutationWithErrorsResult & {
  __typename: 'UpdateNannyListingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  nannyListing: Maybe<NannyListing>;
};

/** Autogenerated return type of UpdateNanny */
export type UpdateNannyPayload = MutationWithErrorsResult & {
  __typename: 'UpdateNannyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  nanny: Maybe<Nanny>;
};

/** Autogenerated input type of UpdateNotificationSubscriptionsMutation */
export type UpdateNotificationSubscriptionsMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subscriptions: Array<NotificationSubscriptionInput>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateNotificationSubscriptionsMutation */
export type UpdateNotificationSubscriptionsMutationPayload = MutationWithErrorsResult & {
  __typename: 'UpdateNotificationSubscriptionsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  user: Maybe<User>;
};

/** Autogenerated input type of UpdateShareListing */
export type UpdateShareListingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shareListing?: InputMaybe<ShareListingInput>;
  shareListingId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateShareListing */
export type UpdateShareListingPayload = MutationWithErrorsResult & {
  __typename: 'UpdateShareListingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  shareListing: Maybe<ShareListing>;
};

/** Autogenerated input type of UpdateShareNanny */
export type UpdateShareNannyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shareNanny?: InputMaybe<ShareNannyInput>;
  shareNannyId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdateShareNanny */
export type UpdateShareNannyPayload = MutationWithErrorsResult & {
  __typename: 'UpdateShareNannyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  shareNanny: Maybe<ShareNanny>;
};

/** Autogenerated input type of UpdateTimeZone */
export type UpdateTimeZoneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  timeZone: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateTimeZone */
export type UpdateTimeZonePayload = MutationWithErrorsResult & {
  __typename: 'UpdateTimeZonePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  user: Maybe<User>;
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  smsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  stripeToken?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateUser */
export type UpdateUserPayload = MutationWithErrorsResult & {
  __typename: 'UpdateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  user: Maybe<User>;
};

export type User = Node & {
  __typename: 'User';
  activeInterviewWithViewer: Maybe<Interview>;
  activeSubscription: Maybe<UserSubscription>;
  averageResponseTimeInSeconds: Maybe<Scalars['Int']['output']>;
  averageResponseTimeText: Maybe<Scalars['String']['output']>;
  backgroundCheckProducts: Array<Product>;
  c7rToken: Maybe<Scalars['String']['output']>;
  canReadSensitive: Scalars['Boolean']['output'];
  canReport: Scalars['Boolean']['output'];
  canRequestBackgroundCheck: Scalars['Boolean']['output'];
  canRequestInterview: Scalars['Boolean']['output'];
  canRequestReference: Scalars['Boolean']['output'];
  email: Maybe<Scalars['String']['output']>;
  features: Array<Feature>;
  hasPhoneNumberVerificationCode: Scalars['Boolean']['output'];
  hasUnreadConversationNotifications: Scalars['Boolean']['output'];
  hasUnreadPlusNotifications: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isAdmin: Maybe<Scalars['Boolean']['output']>;
  isComplete: Scalars['Boolean']['output'];
  isDisabled: Maybe<Scalars['Boolean']['output']>;
  isEmailVerified: Maybe<Scalars['Boolean']['output']>;
  isFacebookVerified: Maybe<Scalars['Boolean']['output']>;
  isFullSession: Scalars['Boolean']['output'];
  isGoogleVerified: Maybe<Scalars['Boolean']['output']>;
  isOnline: Maybe<Scalars['Boolean']['output']>;
  isPayrollRegistrationReady: Scalars['Boolean']['output'];
  isPhoneNumberVerified: Maybe<Scalars['Boolean']['output']>;
  isPlusAssistant: Scalars['Boolean']['output'];
  isReported: Scalars['Boolean']['output'];
  isScammer: Maybe<Scalars['Boolean']['output']>;
  isStripeCustomer: Scalars['Boolean']['output'];
  lastActiveAgoText: Maybe<Scalars['String']['output']>;
  lastActiveAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  latestNannyContract: Maybe<NannyContract>;
  otherProfile: Maybe<Profile>;
  paidTierCohort: Maybe<PaidTierCohort>;
  payrollClientRegistration: Maybe<PayrollClientRegistration>;
  payrollEmployeeRegistration: Maybe<PayrollEmployeeRegistration>;
  payrollRegistration: Maybe<PayrollRegistration>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  pk: Scalars['Int']['output'];
  /** @deprecated Use category specific product fields instead. */
  products: Array<Product>;
  profile: Maybe<Profile>;
  referenceContactRequests: Maybe<Array<ReferenceContactRequest>>;
  requestedBackgroundCheck: Maybe<BackgroundCheck>;
  requestedBackgroundChecks: Array<BackgroundCheck>;
  selfBackgroundCheck: Maybe<BackgroundCheck>;
  smsEnabled: Scalars['Boolean']['output'];
  /** @deprecated Use key on front end */
  stripePublishableKey: Maybe<Scalars['String']['output']>;
  stripeSourceLast4: Maybe<Scalars['String']['output']>;
  subscriptionProducts: Array<Product>;
  timeZone: Maybe<Scalars['String']['output']>;
  unsubscribed: Maybe<Unsubscribed>;
};


export type UserProductsArgs = {
  category: InputMaybe<Scalars['String']['input']>;
};

/** A file upload associated with a user's proile */
export type UserDocument = Node & {
  __typename: 'UserDocument';
  documentType: UserDocumentType;
  id: Scalars['ID']['output'];
  originalFilename: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** The policy and signature for permissions to FileStack assets */
export type UserDocumentCredentials = {
  __typename: 'UserDocumentCredentials';
  policy: Scalars['String']['output'];
  signature: Scalars['String']['output'];
};

/** Document type ie. Bank, FEIN, etc. */
export enum UserDocumentType {
  /** ADDRESS */
  Address = 'ADDRESS',
  /** BANK */
  Bank = 'BANK',
  /** FEIN */
  Fein = 'FEIN',
  /** PHONE */
  Phone = 'PHONE'
}

export type UserInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  smsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Interest of a user in a listing */
export type UserListingInterest = Node & {
  __typename: 'UserListingInterest';
  acceptedAt: Maybe<Scalars['ISO8601Date']['output']>;
  acceptedBy: Maybe<User>;
  appliedAt: Maybe<Scalars['ISO8601Date']['output']>;
  appliedBy: Maybe<User>;
  canArchive: Scalars['Boolean']['output'];
  canDecline: Scalars['Boolean']['output'];
  canMakeOffer: Scalars['Boolean']['output'];
  canRestore: Scalars['Boolean']['output'];
  currentOffer: Maybe<Offer>;
  declinedAt: Maybe<Scalars['ISO8601Date']['output']>;
  declinedBy: Maybe<User>;
  hiringStage: Maybe<HiringStage>;
  id: Scalars['ID']['output'];
  interest: Interest;
  isArchived: Scalars['Boolean']['output'];
  isOwnerOfLastMessage: Scalars['Boolean']['output'];
  listing: Listing;
  readAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  user: Maybe<User>;
};

/** Autogenerated input type of UserListingInterestArchive */
export type UserListingInterestArchiveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userListingInterestId: Scalars['ID']['input'];
};

/** Autogenerated return type of UserListingInterestArchive */
export type UserListingInterestArchivePayload = MutationWithErrorsResult & {
  __typename: 'UserListingInterestArchivePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  userListingInterest: Maybe<UserListingInterest>;
};

/** An edge in a connection. */
export type UserListingInterestEdge = {
  __typename: 'UserListingInterestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<UserListingInterest>;
};

/** Autogenerated input type of UserListingInterestMarkRead */
export type UserListingInterestMarkReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userListingInterest: Scalars['ID']['input'];
};

/** Autogenerated return type of UserListingInterestMarkRead */
export type UserListingInterestMarkReadPayload = MutationWithErrorsResult & {
  __typename: 'UserListingInterestMarkReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  userListingInterest: Maybe<UserListingInterest>;
};

/** Users view of an offer */
export type UserListingInterestOffer = Node & {
  __typename: 'UserListingInterestOffer';
  id: Scalars['ID']['output'];
  offer: Offer;
  readAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  userListingInterest: UserListingInterest;
};

/** Autogenerated input type of UserListingInterestOfferMarkRead */
export type UserListingInterestOfferMarkReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userListingInterestOffer: Scalars['ID']['input'];
};

/** Autogenerated return type of UserListingInterestOfferMarkRead */
export type UserListingInterestOfferMarkReadPayload = MutationWithErrorsResult & {
  __typename: 'UserListingInterestOfferMarkReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  userListingInterestOffer: Maybe<UserListingInterestOffer>;
};

/** Autogenerated input type of UserListingInterestRestore */
export type UserListingInterestRestoreInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userListingInterestId: Scalars['ID']['input'];
};

/** Autogenerated return type of UserListingInterestRestore */
export type UserListingInterestRestorePayload = MutationWithErrorsResult & {
  __typename: 'UserListingInterestRestorePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  userListingInterest: Maybe<UserListingInterest>;
};

export type UserReport = Node & {
  __typename: 'UserReport';
  id: Scalars['ID']['output'];
  reason: Scalars['String']['output'];
  reportedBy: User;
  user: User;
};

/** Autogenerated input type of UserSignupFamily */
export type UserSignupFamilyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  family: FamilyInput;
  listing?: InputMaybe<FamilyListingInput>;
  user?: InputMaybe<UserInput>;
};

/** Autogenerated return type of UserSignupFamily */
export type UserSignupFamilyPayload = MutationWithErrorsResult & {
  __typename: 'UserSignupFamilyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  user: Maybe<User>;
};

/** Autogenerated input type of UserSignupNanny */
export type UserSignupNannyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  listing?: InputMaybe<NannyListingUpdateInput>;
  nanny: NannyInput;
  user?: InputMaybe<UserInput>;
};

/** Autogenerated return type of UserSignupNanny */
export type UserSignupNannyPayload = MutationWithErrorsResult & {
  __typename: 'UserSignupNannyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  user: Maybe<User>;
};

/** Autogenerated input type of UserSignupShareNanny */
export type UserSignupShareNannyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  family: FamilyInput;
  listing: ShareListingInput;
  nanny: ShareNannyInput;
  user?: InputMaybe<UserInput>;
};

/** Autogenerated return type of UserSignupShareNanny */
export type UserSignupShareNannyPayload = MutationWithErrorsResult & {
  __typename: 'UserSignupShareNannyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  user: Maybe<User>;
};

export type UserSubscription = Node & {
  __typename: 'UserSubscription';
  assistant: Maybe<User>;
  availableBackgroundChecks: Maybe<Scalars['Int']['output']>;
  cancelReason: Maybe<Scalars['String']['output']>;
  currentPeriodEndAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  customHiringRequirements: Array<SubscriptionHiringRequirement>;
  deliveredCandidates: HiringAssistanceCandidateConnection;
  expiresAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  hasRequirementsSent: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  isCancelled: Maybe<Scalars['Boolean']['output']>;
  isExpired: Maybe<Scalars['Boolean']['output']>;
  maximumBackgroundChecks: Maybe<Scalars['Int']['output']>;
  numberOfCandidates: Scalars['Int']['output'];
  otherRequirements: Maybe<Scalars['String']['output']>;
  product: Product;
  subscriptionFeedback: Array<SubscriptionFeedback>;
  user: User;
};


export type UserSubscriptionDeliveredCandidatesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<HiringAssistantCandidatesFilterEnum>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type UserSubscriptionNumberOfCandidatesArgs = {
  status: InputMaybe<Array<CandidateStatus>>;
};

/** Autogenerated input type of VerifyEmail */
export type VerifyEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  verificationCode: Scalars['String']['input'];
};

/** Autogenerated return type of VerifyEmail */
export type VerifyEmailPayload = MutationWithErrorsResult & {
  __typename: 'VerifyEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  user: Maybe<User>;
};

/** Autogenerated input type of VerifyPhoneNumber */
export type VerifyPhoneNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};

/** Autogenerated return type of VerifyPhoneNumber */
export type VerifyPhoneNumberPayload = MutationWithErrorsResult & {
  __typename: 'VerifyPhoneNumberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  user: Maybe<User>;
};

/** Autogenerated input type of VerifyProductPromoMutation */
export type VerifyProductPromoMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
  promoCode: Scalars['String']['input'];
};

/** Autogenerated return type of VerifyProductPromoMutation */
export type VerifyProductPromoMutationPayload = MutationWithErrorsResult & {
  __typename: 'VerifyProductPromoMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  product: Maybe<Product>;
  promo: Maybe<Promo>;
};

/** Autogenerated input type of VerifyPromoMutation */
export type VerifyPromoMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  priceId: Scalars['String']['input'];
  promoCode: Scalars['String']['input'];
};

/** Autogenerated return type of VerifyPromoMutation */
export type VerifyPromoMutationPayload = MutationWithErrorsResult & {
  __typename: 'VerifyPromoMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  errors: Maybe<Errors>;
  promo: Maybe<Promo>;
};

/** Data related to the querying user */
export type Viewer = {
  __typename: 'Viewer';
  conversations: ConversationConnection;
  listingRoleFilters: Maybe<Array<ListingRole>>;
  numberOfCandidates: Scalars['Int']['output'];
  numberOfRecommendedListings: Scalars['Int']['output'];
  recommended: Maybe<ListingConnectionWithCount>;
  /** The currently authenticated user */
  user: Maybe<User>;
};


/** Data related to the querying user */
export type ViewerConversationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CandidateSearchFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


/** Data related to the querying user */
export type ViewerNumberOfCandidatesArgs = {
  filters?: InputMaybe<CandidateSearchFilters>;
};


/** Data related to the querying user */
export type ViewerNumberOfRecommendedListingsArgs = {
  filters?: InputMaybe<ListingSearchFilters>;
};


/** Data related to the querying user */
export type ViewerRecommendedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ListingSearchFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type _Entity = User;

/** The sdl representing the federated service capabilities. Includes federation directives, removes federation types, and includes rest of full schema after schema directives have been applied */
export type _Service = {
  __typename: '_Service';
  sdl: Maybe<Scalars['String']['output']>;
};
