import { useRouter } from 'next/router'

import ButtonLink from '@ui/button/ButtonLink'
import Stack from '@ui/layout/stack'
import { buildOnboardingPath } from 'routes/onboarding'
import { dashboardPath, loginPath } from 'routes/static'

const NavButtons = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const router = useRouter()
  const showLoginButton = router.asPath !== loginPath
  return (
    <Stack direction='horizontal' gap='4' valign='center' align='right'>
      {isLoggedIn ? (
        <ButtonLink
          minWidth={{ _: '85px', desktop: '132px' }}
          variant='transparent'
          href={dashboardPath}
        >
          Dashboard
        </ButtonLink>
      ) : (
        <>
          {showLoginButton && (
            <ButtonLink
              minWidth={{ _: '85px', desktop: '132px' }}
              variant='flat'
              href={loginPath}
              eventClickTarget='external-top-nav-join'
            >
              Login
            </ButtonLink>
          )}

          <ButtonLink
            minWidth={{ _: '85px', desktop: '132px' }}
            variant='transparent'
            href={buildOnboardingPath({ back: router.asPath })}
            eventClickTarget='external-top-nav-login'
          >
            Join
          </ButtonLink>
        </>
      )}
    </Stack>
  )
}

export default NavButtons
