import { ContentBlock } from '@ui/layout/ContentBlock'
import PrimaryFooterPublic from '@ui/navigation/PrimaryFooterPublic'
import { PrimaryFooterPublicFragment } from '@ui/navigation/PrimaryFooterPublic/fragments.generated'
import PrimaryNavigationPublic from '@ui/navigation/PrimaryNavigationPublic'
import { GradientType } from '@ui/theme/gradients'

import PageLayoutContainer from './PageLayoutContainer'

type PublicPageLayoutSharedProps = {
  children: React.ReactNode
  gradient?: GradientType
  stickyBottom?: React.ReactNode
}

type PublicPageLayoutWithoutFooterProps = PublicPageLayoutSharedProps & {
  includes: { footer: false; navigation?: boolean }
}

type PublicPageLayoutWithFooterProps = PublicPageLayoutSharedProps & {
  includes: { footer: true; navigation?: boolean }
} & PrimaryFooterPublicFragment

export type PublicPageLayoutProps =
  | PublicPageLayoutWithoutFooterProps
  | PublicPageLayoutWithFooterProps

const defaultIncludes = { navigation: true, footer: true }

const PublicPageLayout = ({
  children,
  gradient,
  includes = defaultIncludes,
  stickyBottom,
  ...rest
}: PublicPageLayoutProps) => {
  const { navigation, footer } = { ...defaultIncludes, ...includes }

  return (
    <>
      <PageLayoutContainer gradient={gradient}>
        {navigation && <PrimaryNavigationPublic />}

        <ContentBlock
          maxWidth={{ _: '100%', desktop: 'page.content.maxWidth' }}
          px='3'
          pt='4'
          pb='6'
        >
          {children}
        </ContentBlock>

        {footer && <PrimaryFooterPublic {...(rest as PrimaryFooterPublicFragment)} />}
      </PageLayoutContainer>
      {stickyBottom && stickyBottom}
    </>
  )
}

export default PublicPageLayout
