import css from '@styled-system/css'
import styled from 'styled-components'

import ButtonLink from '@ui/button/ButtonLink'
import Icon from '@ui/icons/Icon'
import questionMarkMessageSvg from '@ui/icons/svgs/question-mark-message-white.svg'
import Stack from '@ui/layout/stack'
import { Body } from '@ui/typography/Body'
import { helpCenterPath } from 'routes/static'

const HelpCenterButton = () => {
  return (
    <StyledButton variant='flat' target='_blank' href={helpCenterPath}>
      <Stack gap='4' direction='horizontal'>
        <Icon src={questionMarkMessageSvg} />
        <StyledBody fontWeight='bold' color='white.4'>
          Help Center
        </StyledBody>
      </Stack>
    </StyledButton>
  )
}

const StyledButton = styled(ButtonLink)`
  color: #fff;

  background-color: transparent;
  border-color: #fff;
  border-style: solid;
  border-width: 1px;
  && {
    text-decoration: none;
  }
`

const StyledBody = styled(Body)`
  ${css({
    fontSize: 0,
  })}
`

export default HelpCenterButton
