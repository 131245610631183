import { ReactElement } from 'react'
import styled from 'styled-components'
import {
  flexBasis,
  flexGrow,
  flexShrink,
  alignSelf,
  FlexBasisProps,
  FlexGrowProps,
  FlexShrinkProps,
  AlignSelfProps,
} from 'styled-system'

export type StackItemProps = FlexBasisProps &
  FlexGrowProps &
  FlexShrinkProps &
  AlignSelfProps & {
    className?: string
    children: ReactElement
  }

export const StackItem = styled.div<StackItemProps>`
  ${flexBasis}
  ${flexGrow}
  ${flexShrink}
  ${alignSelf}
`
