import { PeekabooExperiment } from '@careguide/peekaboo-client'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { startCase, kebabCase } from 'lodash'

import { IMPACT_PRODUCT_CATEGORY } from './constants'

const analytics = AnalyticsBrowser.load({
  writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY as string,
})

const BUTTON_CLICK_EVENT_NAME = 'Button Clicked'
const CREATED_EVENT_ACTION = 'Created'
const ERROR_EVENT_ACTION = 'Error'
const VIEWED_EVENT_ACTION = 'Viewed'
const SECTION_EVENT_OBJECT = 'Section'
const IMPACT_SUBSCRIPTION_PURCHASE_EVENT_ACTION = 'order_complete'

type ImpactPurchaseTracking = {
  order_id: string
  total: number
  subtotal: number
  discount?: number
  coupon?: string
  currency: string
  products: Array<{
    name: string
    price: number
    quantity: number
    category: string
    sku: string
  }>
}

const eventDefaults = () => {
  return {
    release: process.env.NEXT_PUBLIC_REVISION,
    viewportHeight: window.screen.height,
    viewportWidth: window.screen.width,
  }
}

const eventName = (object: string, action: string) => `${startCase(object)} ${startCase(action)}`

export class Analytics {
  /**
   * Include query strings if present in the page url
   * When tracking a Next url you can pass router asPath since it includes query strings
   * ie. /nanny-jobs?test=123
   * @param relativePath - relative url for page to track
   */
  static page(relativePath: string) {
    const absoluteUrl = `https://${window.location.hostname}${relativePath}`
    const url = new URL(absoluteUrl)

    analytics.page({
      ...eventDefaults(),
      url: absoluteUrl,
      path: url.pathname,
      search: url.search,
    })
  }

  static identify(user: { id: string; email: string | null | undefined }) {
    analytics.identify(user.id, user)
  }

  static trackButtonClick(clickTarget: string, metadata: any = {}) {
    const finalProperties: any = {
      clickTarget: kebabCase(clickTarget),
      ...eventDefaults(),
      ...metadata,
    }

    if (finalProperties.metadata) {
      finalProperties.metadata = JSON.stringify(finalProperties.metadata)
    }

    analytics.track(BUTTON_CLICK_EVENT_NAME, finalProperties)
  }

  static trackObjectCreate(object: string, metadata: any = {}) {
    const finalEventName = eventName(object, CREATED_EVENT_ACTION)
    const finalProperties = {
      ...eventDefaults(),
      ...metadata,
    }

    if (finalProperties.metadata) {
      finalProperties.metadata = JSON.stringify(finalProperties.metadata)
    }

    analytics.track(finalEventName, finalProperties)
  }

  static trackErrorView(
    errorType: string,
    clickTarget: string,
    errorMessage: string,
    metadata: any = {}
  ) {
    const finalEventName = eventName(ERROR_EVENT_ACTION, VIEWED_EVENT_ACTION)

    const finalProperties = {
      errorType: kebabCase(errorType),
      clickTarget: kebabCase(clickTarget),
      errorMessage,
      ...eventDefaults(),
      ...metadata,
    }

    if (finalProperties.metadata) {
      finalProperties.metadata = JSON.stringify(finalProperties.metadata)
    }

    analytics.track(finalEventName, finalProperties)
  }

  static trackExperiments(experiments: PeekabooExperiment[]) {
    experiments.forEach((experiment) => {
      const experimentKey = experiment.key
      const variation = experiment.variation.key

      analytics.group(experimentKey, { variation, name: `Experiment ${experimentKey}` })
    })
  }

  static trackSectionView(section: string, metadata: any = {}) {
    const finalEventName = eventName(SECTION_EVENT_OBJECT, VIEWED_EVENT_ACTION)
    const finalProperties: any = {
      section: kebabCase(section),
      ...eventDefaults(),
      ...metadata,
    }

    if (finalProperties.metadata) {
      finalProperties.metadata = JSON.stringify(finalProperties.metadata)
    }

    analytics.track(finalEventName, finalProperties)
  }

  static trackSubscriptionPurchase(
    subscriptionId: string,
    priceInDollars: number,
    stripeProductId: string,
    name: string,
    discount?: number,
    promoCode?: string
  ) {
    const subscriptionMetadata: ImpactPurchaseTracking = {
      order_id: subscriptionId,
      total: priceInDollars - (discount || 0),
      subtotal: priceInDollars,
      discount,
      coupon: promoCode,
      currency: 'USD',
      products: [
        {
          name,
          sku: stripeProductId,
          price: priceInDollars,
          quantity: 1,
          category: IMPACT_PRODUCT_CATEGORY,
        },
      ],
    }
    analytics.track(IMPACT_SUBSCRIPTION_PURCHASE_EVENT_ACTION, subscriptionMetadata)
  }
}
