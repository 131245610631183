import css from '@styled-system/css'
import Image from 'next/image'
import styled from 'styled-components'

type IconProps = {
  src: string
  width: number
  height: number
  onClick?: () => void
  className?: string
}
const Icon = ({ src, width, height, onClick, className }: IconProps) => {
  const imageProps = {
    src,
    width,
    height,
    // Empty alt intentional since describing icons would be noise for screen readers
    alt: '',
    onClick,
    className,
  }

  return <StyledImg priority aria-hidden {...imageProps} />
}

const StyledImg = styled(Image)`
  flex-shrink: 0;
  object-fit: contain;

  ${(props) => props.onClick && css({ cursor: 'pointer' })}
`

Icon.defaultProps = {
  width: 24,
  height: 24,
}

export default Icon
