export const zIndex = {
  tooltip: 200,
  stickyCtas: 300,
  belowNavigation: 499,
  navigation: 500,
  modal: 3000,
  datePicker: 4000,
  snackbar: 5000,
  overlay: 6000,
  congratulationsPage: {
    confettiContainer: 2,
    cta: 1,
    confettiIcon: 10,
  },
  listingModal: {
    carousel: 5,
    messageInputBlock: 10,
    contextMenu: 5,
  },
  goToTop: 200,
}
