import { BooleanFacet } from '@graph/types/global.generated'
import { CANADA_LOCALE, Locale, USA_LOCALE } from 'locale/constants'

export enum OnboardingType {
  User = 'user', // a normal family or nanny user
  Payroll = 'payroll',
}

export enum OnboardingUserTypes {
  Nanny = 'nanny',
  Family = 'family',
  NannyShare = 'nanny-share',
  NannyShareJob = 'family-pair',
  NannyShareJobJoin = 'family-pair-join',
}

export enum PayrollUserTypes {
  NoPayrollUserType = 'payroll',
  NannyPayroll = 'nanny-payroll',
  FamilyPayroll = 'family-payroll',
}
export enum CreateReferenceUserTypes {
  NannyReference = 'nanny-reference',
}

export const ALL_USER_TYPES = {
  ...OnboardingUserTypes,
  ...PayrollUserTypes,
  ...CreateReferenceUserTypes,
}

export enum UserDocumentTypes {
  Bank = 'BANK',
  Fein = 'FEIN',
  Phone = 'PHONE',
  Address = 'ADDRESS',
}

export enum OnboardingCacheKey {
  Family = 'onboarding-family',
  Nanny = 'onboarding-nanny',
  FamilyListing = 'onboarding-family-listing',
  NannyListing = 'onboarding-nanny-listing',
  User = 'onboarding-user',
  ProfileType = 'onboarding-profile-type',
  FurthestCompletedStep = 'onboarding-furthest-completed-step',
  NannyPayroll = 'onboarding-nanny-payroll',
  FamilyPayroll = 'onboarding-family-payroll',
  SubscriptionProduct = 'onboarding-subscription-product',
}

export const CACHE_TO_CLEAR_ON_USER_CREATION = [
  OnboardingCacheKey.Family,
  OnboardingCacheKey.Nanny,
  OnboardingCacheKey.FamilyListing,
  OnboardingCacheKey.NannyListing,
  OnboardingCacheKey.User,
  OnboardingCacheKey.ProfileType,
]

export enum OnboardingStep {
  // family onboarding
  Budget = 'budget',
  EmploymentType = 'employment-type',
  Kids = 'kids',
  Description = 'description',
  User = 'user',
  Phone = 'phone',
  SmsNotification = 'sms-notification',
  Address = 'address',
  NannyShare = 'nanny-share',
  SignUp = 'sign-up',
  Plus = 'plus',
  Plans = 'plans',
  Payment = 'payment',
  Congratulations = 'congratulations',
  // nanny onboarding steps
  FullOrPartTime = 'full-or-part-time',
  FirstAid = 'first-aid',
  CPR = 'cpr',
  Transportation = 'transportation',
  Housekeeping = 'housekeeping',
  MealPrep = 'meal-prep',
  SpecialNeeds = 'special-needs',
  LiveIn = 'live-in',
  Languages = 'languages',
  AgeGroups = 'age-groups',
  Experience = 'experience',
  Location = 'location',
  Rate = 'rate',
  Region = 'region',
  Introduce = 'introduce',
  // payroll nanny onboarding
  ActivelyLooking = 'actively-looking',
  HowItWorks = 'how-it-works',
  Details = 'details',
  DateOfBirth = 'dob',
  Banking = 'banking',
  Tax = 'tax',
  Invite = 'invite',
  WithholdingForms = 'withholding-forms',
  // payroll family onboarding
  WhatsIncluded = 'whats-included',
  TaxAccounts = 'tax-accounts',
  BusinessSetup = 'business-setup',
  FEIN = 'fein',
  NannySharePayroll = 'nanny-share-payroll',
  HourSubmission = 'hour-submission',
  PayCycle = 'pay-cycle',
  PhoneBill = 'phone-bill',
  AddressRegistration = 'address-registration',
  NannyContact = 'nanny-contact',
  Forms = 'forms',
}

export const ONBOARDING_STEP_ORDER: Record<Locale, Record<string, OnboardingStep[]>> = {
  [USA_LOCALE]: {
    'family-control': [
      OnboardingStep.Region,
      OnboardingStep.Kids,
      OnboardingStep.Address,
      OnboardingStep.NannyShare,
      OnboardingStep.SignUp,
      OnboardingStep.User,
      OnboardingStep.Phone,
      OnboardingStep.SmsNotification,
      OnboardingStep.Budget,
      OnboardingStep.EmploymentType,
      OnboardingStep.Description,
      OnboardingStep.Plus,
      OnboardingStep.Plans,
      OnboardingStep.Payment,
      OnboardingStep.Congratulations,
    ],
    'nanny-control': [
      OnboardingStep.Region,
      OnboardingStep.FullOrPartTime,
      OnboardingStep.FirstAid,
      OnboardingStep.CPR,
      OnboardingStep.Transportation,
      OnboardingStep.Housekeeping,
      OnboardingStep.MealPrep,
      OnboardingStep.SpecialNeeds,
      OnboardingStep.LiveIn,
      OnboardingStep.Languages,
      OnboardingStep.AgeGroups,
      OnboardingStep.Experience,
      OnboardingStep.Address,
      OnboardingStep.SignUp,
      OnboardingStep.User,
      OnboardingStep.Phone,
      OnboardingStep.SmsNotification,
      OnboardingStep.Rate,
      OnboardingStep.Introduce,
      OnboardingStep.Plus,
      OnboardingStep.Payment,
      OnboardingStep.Congratulations,
    ],
    'family-payroll': [
      OnboardingStep.WhatsIncluded,
      OnboardingStep.User,
      OnboardingStep.Payment,
      OnboardingStep.TaxAccounts,
      OnboardingStep.BusinessSetup,
      OnboardingStep.FEIN,
      OnboardingStep.DateOfBirth,
      OnboardingStep.Address,
      OnboardingStep.Rate,
      OnboardingStep.NannyShare,
      OnboardingStep.NannySharePayroll,
      OnboardingStep.HourSubmission,
      OnboardingStep.PayCycle,
      OnboardingStep.Banking,
      OnboardingStep.PhoneBill,
      OnboardingStep.AddressRegistration,
      OnboardingStep.Invite,
      OnboardingStep.Forms,
    ],
    'nanny-payroll': [
      OnboardingStep.ActivelyLooking,
      OnboardingStep.HowItWorks,
      OnboardingStep.User,
      OnboardingStep.Details,
      OnboardingStep.DateOfBirth,
      OnboardingStep.Address,
      OnboardingStep.Banking,
      OnboardingStep.Invite,
      OnboardingStep.WithholdingForms,
    ],
  },
  [CANADA_LOCALE]: {
    'family-control': [
      OnboardingStep.Region,
      OnboardingStep.Kids,
      OnboardingStep.Address,
      OnboardingStep.NannyShare,
      OnboardingStep.SignUp,
      OnboardingStep.User,
      OnboardingStep.Phone,
      OnboardingStep.SmsNotification,
      OnboardingStep.Budget,
      OnboardingStep.EmploymentType,
      OnboardingStep.Description,
      OnboardingStep.Congratulations,
    ],
    'nanny-control': [
      OnboardingStep.Region,
      OnboardingStep.FullOrPartTime,
      OnboardingStep.FirstAid,
      OnboardingStep.CPR,
      OnboardingStep.Transportation,
      OnboardingStep.Housekeeping,
      OnboardingStep.MealPrep,
      OnboardingStep.SpecialNeeds,
      OnboardingStep.LiveIn,
      OnboardingStep.Languages,
      OnboardingStep.AgeGroups,
      OnboardingStep.Experience,
      OnboardingStep.Address,
      OnboardingStep.SignUp,
      OnboardingStep.User,
      OnboardingStep.Phone,
      OnboardingStep.SmsNotification,
      OnboardingStep.Rate,
      OnboardingStep.Introduce,
      OnboardingStep.Congratulations,
    ],
  },
}

export enum EmploymentTypeSelection {
  FullTime = BooleanFacet.FullTime,
  PartTime = BooleanFacet.PartTime,
  Occasional = BooleanFacet.OccasionalWork,
}
