import styled from 'styled-components'

import { ContentBlock } from '@ui/layout/ContentBlock'

const PaperContentBlock = styled(ContentBlock).attrs({
  bg: 'white.4',
  boxShadow: 'paper',
})`
  position: relative;

  @media print {
    box-shadow: none;
  }
`

export default PaperContentBlock
