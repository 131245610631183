import { useQuery } from '@apollo/client'
import React, { createContext, useContext, useMemo } from 'react'

import { FeatureFlagsQueryDocument } from './query.generated'

export type FeatureFlag = {
  name: string
  enabled: boolean
}

const FeatureFlagsContext = createContext<FeatureFlag[]>([])

export const FeatureFlagsProvider = ({
  children,
  flags,
}: {
  children: React.ReactNode
  flags?: FeatureFlag[]
}) => {
  const { data } = useQuery(FeatureFlagsQueryDocument, { skip: !!flags })

  const featureFlags = useMemo(() => (data?.featureFlags || flags) ?? [], [data, flags])

  return (
    <FeatureFlagsContext.Provider value={featureFlags}>{children}</FeatureFlagsContext.Provider>
  )
}

export const useFeatureFlag = (featureName: string) => {
  const featureFlags = useContext(FeatureFlagsContext)
  return getFeatureFlag(featureName, featureFlags)
}

export const getFeatureFlag = (featureName: string, featureFlags: FeatureFlag[]) =>
  featureFlags.find((feature) => feature.name === featureName)?.enabled ?? false
