import styled from 'styled-components'

import Stack from '@ui/layout/stack'
import { Body } from '@ui/typography/Body'

import Hr from './Hr'

const OrLine = () => (
  <CenterStack direction='horizontal' gap='4'>
    <Hr />
    <Body fontWeight='bold'>OR</Body>
    <Hr />
  </CenterStack>
)

const CenterStack = styled(Stack)`
  align-items: center;
`

export default OrLine
