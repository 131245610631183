import { ApolloClient, gql, useMutation } from '@apollo/client'
import { useEffect } from 'react'

import { UpdateTimeZoneMutationDocument } from './useTimeZoneWatcher.generated'

export default function useTimeZoneWatcher(
  viewerTimeZone: string | null,
  apolloClient: ApolloClient<any>
) {
  const [updateTimeZone] = useMutation(UpdateTimeZoneMutationDocument, { client: apolloClient })

  const detectTimeZone = () => {
    try {
      return window.Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch {
      return null
    }
  }

  useEffect(() => {
    if (viewerTimeZone) {
      const timeZone = detectTimeZone()
      if (timeZone && viewerTimeZone !== timeZone) {
        updateTimeZone({ variables: { input: { timeZone } } })
      }
    }
  }, [viewerTimeZone, updateTimeZone])
}

gql`
  mutation UpdateTimeZoneMutation($input: UpdateTimeZoneInput!) {
    updateTimeZone(input: $input) {
      user {
        id
        timeZone
      }
    }
  }
`
