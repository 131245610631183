import { useRouter } from 'next/router'
import { useRef, useEffect } from 'react'

import { Analytics } from './Analytics'

type AnalyticsUser = {
  pk?: number
  email?: string | null
}

export default function useAnalyticsWatcher(user: AnalyticsUser) {
  const router = useRouter()
  const lastRoutePath = useRef('')

  useEffect(() => {
    const isValidUser = !!user.pk && !!user.email

    if (isValidUser) {
      Analytics.identify({ id: String(user.pk), email: user.email })
    }
  }, [user])

  useEffect(() => {
    const hasRouteChanged = lastRoutePath.current !== router.asPath

    if (hasRouteChanged) {
      lastRoutePath.current = router.asPath
      Analytics.page(router.asPath)
    }
  }, [router])
}
