import Link from '@ui/common/Link'
import Stack from '@ui/layout/stack'
import { Heading } from '@ui/typography/Heading'

import { PrimaryFooterPublicCityFragment } from './fragments.generated'
import { getCityDirectoryPath, PrimaryPublicFooterKeyword } from './utils'

type LinksPrimaryKeyordProps = {
  keywords: PrimaryPublicFooterKeyword[]
  cities: PrimaryFooterPublicCityFragment[]
}

const LinksPrimaryKeyord = ({ keywords, cities }: LinksPrimaryKeyordProps) => {
  return (
    <>
      {keywords.map((keyword) => (
        <Stack key={keyword.id} as='nav' gap='4'>
          <Heading as='h3' size='xs' color='white.4'>
            <Link variant='secondary' href={keyword.webClientPath} data-heading-link>
              {keyword.name}
            </Link>
          </Heading>

          <Stack gap='3'>
            {cities.map((city) => (
              <Link variant='secondary' key={city.id} href={getCityDirectoryPath(keyword, city)}>
                {city.name} {keyword.name}
              </Link>
            ))}
          </Stack>
        </Stack>
      ))}
    </>
  )
}

export default LinksPrimaryKeyord
