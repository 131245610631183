export const homePath = '/'

// Authenticated Pages
export const dashboardPath = '/dashboard'
export const assistantPath = '/dashboard/assistant'
export const recommendationsPath = '/dashboard/recommendations'
export const conversationsPath = '/dashboard/conversations'
export const plusDashboardPath = '/dashboard/plus'
export const profilePath = '/profile'
export const profileViewPath = '/profile/view'

// PLUS dashboard paths
export const purchasePlusPath = '/dashboard/plus/get-started'
export const backgroundChecksPlusPath = '/dashboard/plus/background-checks'
export const legacyBackgroundChecksPlusPath = '/dashboard/plus/background-checks-info'
export const interviewsPath = '/dashboard/interviews'
export const sendJobRequirementsPath = '/dashboard/plus/hiring-requirements'
export const nannyJobsPath = '/dashboard/nanny/_jobs'

// Dashboard family paths
export const dashboardFamilyPlansPath = '/dashboard/family/plans'
export const dashboardFamilyPlansEssentialPath = '/dashboard/family/essential'
export const dashboardFamilyPlansPlusPath = '/dashboard/family/plus'
export const dashboardFamilyBackgroundChecksPath = '/dashboard/family/background-checks'

// PLUS feature info paths
export const interviewsInfoPath = '/dashboard/plus/interview-top-candidates-info'
export const candidateTrackingInfoPath = '/dashboard/plus/candidate-tracking-info'
export const contractsInfoPath = '/dashboard/plus/contracts-info'
export const hiringAssistanceInfoPath = '/dashboard/plus/hiring-assistance-info'
export const featuredProfileInfoPath = '/dashboard/plus/featured-profile-info'
export const earlyAccessInfoPath = '/dashboard/plus/early-job-access-info'

// Onboarding Pages (use the utility function buildOnboardingPath if possible)
export const onboardingPath = '/signup'
export const familyOnboardingPath = '/signup/family'
export const nannyOnboardingPath = '/signup/nanny'
export const payrollOnboardingPath = '/signup/payroll'
export const payrollFamilyOnboardingPath = '/signup/payroll/family'
export const payrollNannyOnboardingPath = '/signup/payroll/nanny'

// Landing Pages
export const nannyLandingPath = '/nanny'
export const nannyShareLandingPath = '/nanny-share'
export const nannyPayrollLandingPath = '/payroll'
export const nannySharePayrollLandingPath = '/nanny-share-payroll'
export const backgroundCheckLandingPath = '/background-checks'
export const nannyContractLandingPath = '/nanny-contracts'
export const plusLandingPath = '/plus'

// Self-background checks
export const selfBackgroundCheckReviewPath = '/identity-verification/review'
export const selfBackgroundCheckVerifyPath = '/identity-verification/verify'
export const selfBackgroundCheckPurchasePath = '/identity-verification/purchase'

// external background check
export const backgroundCheckExternalPath = '/_background-check/external'
export const legacyBackgroundCheckExternalPath = '/background-check/new-external/'
export const backgroundCheckExternalCandidatePath = `${backgroundCheckExternalPath}/candidate`
export const backgroundCheckExternalProductSelectionPath = `${backgroundCheckExternalPath}/product-selection`
export const backgroundCheckExternalIntentToHirePath = `${backgroundCheckExternalPath}/intent-to-hire`
export const backgroundCheckExternalPaymentPath = `${backgroundCheckExternalPath}/payment`
export const backgroundCheckExternalConfirmPath = `${backgroundCheckExternalPath}/confirm`

// background check
export const backgroundCheckStatusPath = '/background-check/status'

// Directories
export const directoryPath = '/find'

// Guide Pages
export const guideForFamilyPath = '/guide/family'
export const guideForNannyPath = '/guide/nanny'
export const guideForFamilyPayroll =
  '/guide/family/managing-your-nanny/nanny-lane-payroll-service-details'

// Misc.
export const loginPath = '/login'
export const confirmAccount = '/confirm-account'
export const contactUsPath = '/contact-us'
export const privacyPolicyPath = '/privacy-policy'
export const termsOfServicePath = '/terms-of-service'
export const mobileTermsOfServicePath = '/mobile-terms-of-service'
export const cancellationPolicyPath = '/cancellation-policy'
export const compareProductsPath = '/compare/nannylane-vs-sittercity-vs-carecom'
export const refreshPromptPath = '/refresh-prompt'
export const forgotPasswordPath = '/settings/reset-password'

// External
export const facebookPath = 'https://www.facebook.com/shareNannyLane/'
export const twitterPath = 'https://twitter.com/shareNannyLane'
export const communityPath = 'https://community.nannylane.com'
export const helpCenterPath = 'https://nannylanehelp.zendesk.com/hc/en-us'

// Profile
export const viewProfilePath = '/profile/view'
export const editProfilePath = '/profile/edit'

// Settings
export const settingsPath = '/settings'
export const showProfilePath = '/settings/show-profile'
export const hideProfilePath = '/settings/hide-profile'
export const settingsEmailUnsubscribePath = '/settings/unsubscribe'

// Guide
export const guidePath = '/guide'

// Contract
export const contractBuilderPath = '/contract/build'

// Payroll
export const payrollDashboardPath = '/dashboard/payroll'
export const payrollStatusPath = '/payroll/status'
export const payrollDocumentsPath = '/payroll/documents'
