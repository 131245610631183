import css from '@styled-system/css'
import styled, { css as styledCss } from 'styled-components'
import {
  color,
  typography,
  TextAlignProps,
  TextColorProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  lineHeight,
  LineHeightProps,
} from 'styled-system'

const propsToOmit = ['color', 'fontWeight', 'textAlign', 'fontSize', 'lineHeight']

const config = {
  shouldForwardProp: (prop: string) => !propsToOmit.includes(prop),
}

export type BodyProps = JSX.IntrinsicElements['div'] &
  TextAlignProps &
  FontSizeProps &
  LineHeightProps &
  TextColorProps & {
    fontWeight?: string
  }

export const commonBodyStyles = styledCss<BodyProps>`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;

  ${css({
    margin: 0,
    color: 'grey.3',
    fontSize: 1,
    lineHeight: '24px',
  })}

  ${(typography as any).textAlign}
  ${color}
  ${fontWeight}
  ${fontSize}
  ${lineHeight}
`

export const Body = styled.div.withConfig<BodyProps>(config)`
  ${commonBodyStyles}
`

Body.defaultProps = {
  fontWeight: 'regular',
}
